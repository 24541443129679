import { type FC } from "react";
import { Link } from "react-router-dom";

import { type Connection } from "@app_schema";

import { Badge } from "@styled/badge";

export const ConnectionBadgeLink: FC<{
  connection: Pick<Connection, "id" | "name">;
}> = ({ connection }) => (
  <Link to={`/dashboard/connections/${connection.id}`}>
    <Badge>{connection.name}</Badge>
  </Link>
);
