import { type FC } from "react";
import { Link } from "react-router-dom";

import { useBlogListQuery } from "@app_schema";

import { useTitle } from "@application/hooks/use_title";
import { useTrackPageView } from "@application/hooks/use_track_page_view";

import { BlogArticleDate } from "./blog_article_date";
import { BlogArticleTags } from "./blog_article_tags";
import { BlogPage } from "./blog_page";

export const BlogList: FC = () => {
  const { data } = useBlogListQuery();
  const blog = data?.blog;

  useTitle("Workflow", "Blog");
  useTrackPageView({ page: "blog" });

  return (
    <BlogPage>
      <div className="flex flex-col gap-4">
        {blog?.articles?.map((article) => (
          <div key={article.id} className="flex flex-col gap-2">
            <BlogArticleDate article={article} />

            <Link
              to={`/blog/articles/${article.slug}`}
              className="text-3xl font-black text-indigo-600 hover:underline"
            >
              {article.title}
            </Link>

            <p className="text-2xl font-semibold text-slate-600">
              {article.summary}
            </p>

            <BlogArticleTags article={article} />
          </div>
        ))}
      </div>
    </BlogPage>
  );
};
