import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { reset } from "@application/utilities/reset";

import { CSRF_TOKEN } from "./csrf";

const APOLLO_LINK = new ApolloLink((operation, forward) => {
  const token =
    localStorage.getItem("token") ?? sessionStorage.getItem("token");

  if (token) {
    operation.setContext(({ headers = {} }) => ({
      headers: { ...headers, authorization: `Bearer ${token}` },
    }));
  }

  if (CSRF_TOKEN) {
    operation.setContext(({ headers = {} }) => ({
      headers: { ...headers, "X-CSRF-Token": CSRF_TOKEN },
    }));
  }

  return forward(operation);
});

const ERROR_LINK = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      if (error.extensions?.code === "UNAUTHENTICATED") {
        reset();
        break;
      }
    }
  }
});

const HTTP_LINK = new HttpLink({ uri: "/graphql" });

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([APOLLO_LINK, ERROR_LINK, HTTP_LINK]),
});
