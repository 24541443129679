import { type FC } from "react";
import { useParams } from "react-router";

import { useDashboardMailboxesDetailsQuery } from "@app_schema";

import { Clipboard } from "@styled/clipboard";
import { Notification } from "@styled/notification";
import { Page } from "@styled/page";
import { Well } from "@styled/well";

export const DashboardMailboxesDetails: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useDashboardMailboxesDetailsQuery({
    variables: { id: id! },
  });

  const mailbox = data?.mailbox;
  if (!mailbox) return;

  return (
    <Page>
      <Notification color="indigo">
        Please copy / paste the address associated with your mailbox.
      </Notification>

      <Well actions={<Clipboard text={mailbox.address} />}>
        {mailbox.address}
      </Well>
    </Page>
  );
};
