import { type FC, StrictMode, useState } from "react";
import { RouterProvider } from "react-router-dom";

import { ApolloProvider } from "@apollo/client";

import { type Flash } from "@application/types/flash";

import { client } from "@application/config/apollo";

import { useStorage } from "@application/hooks/use_storage";

import { router } from "./router";
import { World } from "@application/contexts/world";

export const App: FC = () => {
  const [flash, setFlash] = useState<Flash | undefined>();
  const [token, setToken] = useStorage(localStorage ?? sessionStorage, "token");

  const login = (token: string): void => {
    setToken(token);
  };

  const logout = (): void => {
    setToken(undefined);
  };

  const notify = (flash?: Flash): void => {
    setFlash(flash);
  };

  return (
    <StrictMode>
      <World.Provider value={{ flash, login, logout, notify, token }}>
        <ApolloProvider client={client}>
          <RouterProvider router={router} />
        </ApolloProvider>
      </World.Provider>
    </StrictMode>
  );
};
