import { type FC, type ReactNode } from "react";
import { FieldArrayWithId } from "react-hook-form";

import {
  type Action__Prompt__MessageInput,
  Action__Prompt__MessageRole,
  type StepInput,
} from "@app_schema";

import { MessagesContext } from "./messages_context";
import { useMessagesFieldArray } from "./use_messages_field_array";

const DEFAULT_MESSAGE: Action__Prompt__MessageInput = {
  template: "",
  role: Action__Prompt__MessageRole.User,
  attachments: [],
};

type Fields = FieldArrayWithId<StepInput, `action.prompt.messages`, "key">[];

export const MessagesProvider: FC<{
  name: `action.prompt.messages`;
  children(fields: Fields): ReactNode;
}> = ({ name, children }) => {
  const { fields, append, remove, update } = useMessagesFieldArray(name);

  const onBuild = (message?: Action__Prompt__MessageInput) => {
    append(message ?? DEFAULT_MESSAGE);
  };

  const onDelete = (index: number) => {
    const { id, _destroy } = fields[index];
    if (id) update(index, { id, _destroy: !_destroy });
    else remove(index);
  };

  return (
    <MessagesContext.Provider
      value={{
        name,
        onBuild,
        onDelete,
      }}
      children={children(fields)}
    />
  );
};
