import { type FC, type ReactNode } from "react";

import { clsx } from "clsx";

import { faChevronLeft } from "@fortawesome/pro-duotone-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/pro-duotone-svg-icons/faChevronRight";
import { faEllipsis } from "@fortawesome/pro-duotone-svg-icons/faEllipsis";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BASE_STYLE = "px-5 py-2 ring-1 ring-inset focus:ring-outline font-medium";
const SELECTED_STYLE = "bg-indigo-500 text-white ring-indigo-500";
const DEFAULT_STYLE = "bg-white text-slate-500 ring-slate-200";

const PaginationEllipsis: FC = () => (
  <div className={clsx(BASE_STYLE, DEFAULT_STYLE)}>
    <FontAwesomeIcon icon={faEllipsis} />
  </div>
);

const PaginationButton: FC<{
  disabled?: boolean;
  selected?: boolean;
  onClick(): void;
  children: ReactNode;
  className?: string;
}> = ({ selected, disabled, onClick, children, className }) => (
  <button
    type="button"
    disabled={disabled}
    onClick={onClick}
    className={clsx(
      BASE_STYLE,
      selected ? SELECTED_STYLE : DEFAULT_STYLE,
      !disabled && !selected && "hover:bg-slate-100",
      className,
    )}
  >
    {children}
  </button>
);

const PaginationPrev: FC<{
  disabled?: boolean;
  onClick(): void;
}> = ({ disabled, onClick }) => (
  <PaginationButton
    aria-label="Prev"
    disabled={disabled}
    className="rounded-l-md"
    onClick={onClick}
  >
    <FontAwesomeIcon icon={faChevronLeft} />
  </PaginationButton>
);

const PaginationNext: FC<{
  disabled?: boolean;
  onClick(): void;
}> = ({ disabled, onClick }) => (
  <PaginationButton
    aria-label="Next"
    className="rounded-r-md"
    disabled={disabled}
    onClick={onClick}
  >
    <FontAwesomeIcon icon={faChevronRight} />
  </PaginationButton>
);

const PagionationPage: FC<{
  selected?: boolean;
  onClick(): void;
  page: number;
}> = ({ selected, onClick, page }) => (
  <PaginationButton selected={selected} disabled={selected} onClick={onClick}>
    {page}
  </PaginationButton>
);

export const PaginationStatus: FC<{
  current: number;
  pages: number;
}> = ({ current, pages }) => (
  <div className="text-slate-600">
    Page <strong>{current}</strong> of <strong>{pages}</strong>
  </div>
);

const L_WINDOW = 2;
const R_WINDOW = 2;

export const PaginationControl: FC<{
  current: number;
  pages: number;
  onPage(page: number): void;
}> = ({ current, pages, onPage }) => {
  const from = current - L_WINDOW < 1 ? 1 : current - L_WINDOW;
  const till = current + R_WINDOW > pages ? pages : current + R_WINDOW;

  const prev = current > 1;
  const next = current < pages;

  const indexes = [];
  for (let i = from; i <= till; i++) {
    indexes.push(i);
  }

  return (
    <nav
      role="navigation"
      aria-label="Pagination"
      className="isolate inline-flex -space-x-px rounded-md shadow"
    >
      <PaginationPrev disabled={!prev} onClick={() => onPage(current - 1)} />

      {from > 1 && <PaginationEllipsis />}

      {indexes.map((page) => (
        <PagionationPage
          key={page}
          page={page}
          selected={page === current}
          onClick={() => onPage(page)}
        />
      ))}

      {till < pages && <PaginationEllipsis />}

      <PaginationNext disabled={!next} onClick={() => onPage(current + 1)} />
    </nav>
  );
};

export const Pagination: FC<{
  current: number;
  pages: number;
  count: number;
  onPage(_: number): void;
}> = (props) => {
  if (props.pages == 0) return;

  return (
    <div className="mt-4 flex items-center justify-between border-t border-slate-200 pt-4">
      <PaginationStatus {...props} />
      <PaginationControl {...props} />
    </div>
  );
};
