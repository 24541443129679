import { type FC } from "react";

import { Aspect } from "./aspect";
import { Button } from "./button";
import { Modal } from "./modal";
import { ModalClose } from "./modal_close";
import { ModalContent } from "./modal_content";
import { ModalFooter } from "./modal_footer";
import { ModalHeader } from "./modal_header";
import { ModalTitle } from "./modal_title";
import { Picture } from "./picture";

export const Lightbox: FC<{
  title?: string;
  src: {
    alt: string;
    avif: string;
    jpeg: string;
    webp: string;
  };
  aspect: Aspect;
  onClose(): void;
}> = ({ aspect, src, title, onClose }) => (
  <Modal onClose={onClose}>
    <ModalHeader>
      <ModalTitle>{title}</ModalTitle>
      <ModalClose onClose={onClose} />
    </ModalHeader>
    <ModalContent>
      <Picture aspect={aspect} {...src} />
    </ModalContent>
    <ModalFooter>
      <Button type="button" color="slate" onClick={onClose}>
        Close
      </Button>
    </ModalFooter>
  </Modal>
);
