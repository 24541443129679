import { type LoaderFunctionArgs } from "react-router";

import {
  DashboardSkillsDetailsDocument,
  type DashboardSkillsDetailsQuery,
  type DashboardSkillsDetailsQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_SKILLS_DETAILS_LOADER = async (
  props: LoaderFunctionArgs,
) => {
  const { data } = await client.query<
    DashboardSkillsDetailsQuery,
    DashboardSkillsDetailsQueryVariables
  >({
    query: DashboardSkillsDetailsDocument,
    variables: { id: props.params.id! },
  });

  return data;
};
