import { type FC } from "react";
import { useNavigate } from "react-router";

import {
  type ConversationInput,
  useConversationBuildMutation,
} from "@app_schema";

import { DashboardConversationsForm } from "./conversations_form";

export const DashboardConversationsNew: FC = () => {
  const navigate = useNavigate();
  const [execute, { loading }] = useConversationBuildMutation();

  const save = async (input: ConversationInput) => {
    const result = await execute({ variables: { input } });
    const conversation = result?.data?.conversations.build.conversation;
    if (conversation) navigate(`/dashboard/conversations/${conversation.id}`);
  };

  return <DashboardConversationsForm save={save} loading={loading} />;
};
