import { type FC, type ReactNode } from "react";

import { clsx } from "clsx";

import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCircleCheck } from "@fortawesome/pro-duotone-svg-icons/faCircleCheck";
import { faCircleExclamation } from "@fortawesome/pro-duotone-svg-icons/faCircleExclamation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Icon } from "./icon";
import { TW, tw } from "./tw";
import { FormState } from "./types";

const STATE_STYLE: Record<FormState, TW> = {
  [FormState.Default]: "text-slate-500",
  [FormState.Valid]: "text-emerald-500",
  [FormState.Error]: "text-rose-500",
};

const STATE_ICON: Record<FormState.Valid | FormState.Error, IconProp> = {
  [FormState.Valid]: faCircleCheck,
  [FormState.Error]: faCircleExclamation,
};

const BASE_STYLE: TW = "relative";
const L_ICON_STYLE: TW = tw`[&>input]:pl-10`;
const R_ICON_STYLE: TW = tw`[&>input]:pr-10`;

export const Control: FC<{
  icon?: IconProp;
  state?: FormState;
  children: ReactNode;
}> = ({ icon, state = FormState.Default, children }) => (
  <div
    className={clsx(
      BASE_STYLE,
      icon && L_ICON_STYLE,
      state !== FormState.Default && R_ICON_STYLE,
      STATE_STYLE[state],
    )}
  >
    {children}
    {icon && (
      <Icon align="l">
        <FontAwesomeIcon icon={icon} />
      </Icon>
    )}
    {state !== FormState.Default && (
      <Icon align="r">
        <FontAwesomeIcon icon={STATE_ICON[state]} />
      </Icon>
    )}
  </div>
);

Control.displayName = "Control";
