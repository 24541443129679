import { type FC, type ReactNode } from "react";

import { Button } from "./button";
import { Modal } from "./modal";
import { ModalClose } from "./modal_close";
import { ModalContent } from "./modal_content";
import { ModalFooter } from "./modal_footer";
import { ModalHeader } from "./modal_header";
import { ModalTitle } from "./modal_title";

export const Dialog: FC<{
  title?: string;
  confirm?: string;
  cancel?: string;
  onConfirm(): void;
  onCancel(): void;
  children: ReactNode;
}> = ({
  title = "Are you sure?",
  confirm = "Confirm",
  cancel = "Cancel",
  onConfirm,
  onCancel,
  children,
}) => (
  <Modal onClose={onCancel}>
    <ModalHeader>
      <ModalTitle>{title}</ModalTitle>
      <ModalClose onClose={onCancel} />
    </ModalHeader>
    <ModalContent>{children}</ModalContent>
    <ModalFooter>
      <Button type="button" color="slate" onClick={onCancel}>
        {cancel}
      </Button>
      <Button type="button" color="rose" onClick={onConfirm}>
        {confirm}
      </Button>
    </ModalFooter>
  </Modal>
);
