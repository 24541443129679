import { type FC, useState } from "react";

import { faVials } from "@fortawesome/pro-duotone-svg-icons/faVials";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Skill } from "@app_schema";

import { Button } from "@styled/button";

import { SkillVerifyDialog } from "./skill_verify_dialog";

export const SkillVerifyButton: FC<{
  skill: Pick<Skill, "id" | "name">;
}> = ({ skill }) => {
  const [verifying, setVerifying] = useState<boolean>(false);
  const onToggle = () => setVerifying(!verifying);

  return (
    <>
      <Button type="button" onClick={onToggle}>
        <FontAwesomeIcon icon={faVials} /> Verify
      </Button>
      {verifying && <SkillVerifyDialog skill={skill} onClose={onToggle} />}
    </>
  );
};
