import { type FC, type ReactNode } from "react";

import { useKey } from "@application/hooks/use_key";

import { Portal } from "./portal";

export const Modal: FC<{
  onClose?(): void;
  children: ReactNode;
}> = ({ onClose, children }) => {
  useKey(() => {
    if (onClose) onClose();
  }, "Escape");

  return (
    <Portal>
      <div className="fixed inset-0 z-50 flex bg-slate-800/50">
        <div className="flex h-full w-full items-center justify-center px-8 py-8">
          <div className="flex max-h-full w-full max-w-4xl flex-col rounded-md bg-white shadow">
            {children}
          </div>
        </div>
      </div>
    </Portal>
  );
};
