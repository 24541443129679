import { type FC } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { type ConversationFragment, type ConversationInput } from "@app_schema";

import { Button } from "@styled/button";
import { Form } from "@styled/form";
import { Page } from "@styled/page";

import { AgentSelectField } from "./agent_select_field";

export const DashboardConversationsForm: FC<{
  conversation?: ConversationFragment;
  loading?: boolean;
  save(_: ConversationInput): void;
}> = ({ conversation, loading, save }) => {
  const form = useForm<ConversationInput>({
    defaultValues: conversation
      ? {
          agentID: conversation.agent.id,
        }
      : {},
  });

  const onSubmit = async (input: ConversationInput) => {
    if (loading) return;
    if (!input.agentID) return;
    save(input);
  };

  return (
    <Page>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          <AgentSelectField
            {...form.register("agentID", { required: "required" })}
            id="agent"
            label="Agent:"
          />

          <Button type="submit" loading={loading}>
            Save
          </Button>
        </Form>
      </FormProvider>
    </Page>
  );
};
