import { type FC } from "react";

import { faFilterSlash } from "@fortawesome/pro-duotone-svg-icons/faFilterSlash";
import { faUser } from "@fortawesome/pro-duotone-svg-icons/faUser";
import { faUsers } from "@fortawesome/pro-duotone-svg-icons/faUsers";

import { Switch } from "@styled/switch";
import { SwitchButton } from "@styled/switch_button";

export const MineSwitch: FC<{
  mine: boolean | undefined;
  onChange(mine: boolean | undefined): void;
}> = ({ mine, onChange }) => (
  <Switch>
    <SwitchButton
      active={mine === undefined}
      icon={faFilterSlash}
      text="All"
      onSelect={() => {
        onChange(undefined);
      }}
    />
    <SwitchButton
      active={mine === true}
      icon={faUser}
      text="Mine"
      onSelect={() => {
        onChange(true);
      }}
    />
    <SwitchButton
      active={mine === false}
      icon={faUsers}
      text="Team"
      onSelect={() => {
        onChange(false);
      }}
    />
  </Switch>
);
