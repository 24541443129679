import {
  DashboardSettingsDocument,
  type DashboardSettingsQuery,
  type DashboardSettingsQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_SETTINGS_LOADER = async () => {
  const { data } = await client.query<
    DashboardSettingsQuery,
    DashboardSettingsQueryVariables
  >({
    query: DashboardSettingsDocument,
  });
  return data;
};
