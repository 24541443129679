import { type LoaderFunctionArgs } from "react-router";

import {
  BlogAuthorDetailsDocument,
  type BlogAuthorDetailsQuery,
  type BlogAuthorDetailsQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const BLOG_AUTHOR_DETAILS_LOADER = async (props: LoaderFunctionArgs) => {
  const { data } = await client.query<
    BlogAuthorDetailsQuery,
    BlogAuthorDetailsQueryVariables
  >({
    query: BlogAuthorDetailsDocument,
    variables: { slug: props.params.slug! },
  });

  return data;
};
