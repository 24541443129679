import { type FC, Fragment } from "react";

import { type Breadcrumb } from "@application/types/breadcrumb";

import { BreadcrumbNavLink } from "./breadcrumb_nav_link";
import { BreadcrumbPipe } from "./breadcrumb_pipe";

export const Breadcrumbs: FC<{
  breadcrumbs: Array<Breadcrumb>;
}> = ({ breadcrumbs }) => (
  <div className="flex items-center gap-2 rounded-md border border-slate-200 bg-slate-100">
    {breadcrumbs.map((breadcrumb, index) => (
      <Fragment key={index}>
        {index !== 0 && <BreadcrumbPipe />}
        <BreadcrumbNavLink {...breadcrumb} />
      </Fragment>
    ))}
  </div>
);
