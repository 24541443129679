import { type FC } from "react";

import { faPauseCircle } from "@fortawesome/pro-duotone-svg-icons/faPauseCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { RunFragment, RunStatusEnum, useRunPauseMutation } from "@app_schema";

import { Button } from "@styled/button";

export const RunPauseButton: FC<{
  run: RunFragment;
}> = ({ run }) => {
  const [execute, { loading }] = useRunPauseMutation({
    variables: { runID: run.id },
  });

  return (
    <>
      <Button
        type="button"
        disabled={run.status !== RunStatusEnum.Running}
        loading={loading}
        onClick={() => execute()}
      >
        <FontAwesomeIcon icon={faPauseCircle} /> Pause
      </Button>
    </>
  );
};
