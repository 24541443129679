import { type LabelHTMLAttributes, forwardRef } from "react";

import { clsx } from "clsx";

import { tw } from "./tw";

type T = HTMLLabelElement;
type P = LabelHTMLAttributes<T>;

const BASE_STYLE = tw`font-medium text-slate-600`;

export const Label = forwardRef<T, P>(({ className, ...props }, ref) => (
  <label ref={ref} className={clsx(BASE_STYLE, className)} {...props} />
));

Label.displayName = "Label";
