import { type LoaderFunctionArgs } from "react-router";

import {
  DashboardRunsDetailsDocument,
  type DashboardRunsDetailsQuery,
  type DashboardRunsDetailsQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_RUNS_DETAILS_LOADER = async (
  props: LoaderFunctionArgs,
) => {
  const { data } = await client.query<
    DashboardRunsDetailsQuery,
    DashboardRunsDetailsQueryVariables
  >({
    query: DashboardRunsDetailsDocument,
    variables: { id: props.params.id! },
  });

  return data;
};
