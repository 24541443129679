import { type ProgressHTMLAttributes, forwardRef } from "react";

import { clsx } from "clsx";

import { tw } from "./tw";

type T = HTMLProgressElement;
type P = ProgressHTMLAttributes<T>;

const BASE_STYLE = tw`block w-full rounded bg-slate-200 [&::-moz-progress-bar]:rounded [&::-moz-progress-bar]:bg-indigo-500 [&::-webkit-progress-bar]:bg-transparent [&::-webkit-progress-value]:rounded [&::-webkit-progress-value]:bg-indigo-500`;

export const Progress = forwardRef<T, P>(({ className, ...props }, ref) => (
  <progress {...props} ref={ref} className={clsx(BASE_STYLE, className)} />
));
