import { type FC, type FormEventHandler, useState } from "react";
import { useNavigate } from "react-router";

import { faIdCardClip } from "@fortawesome/pro-duotone-svg-icons/faIdCardClip";

import { type Workflow, useWorkflowCloneMutation } from "@app_schema";

import { Button } from "@styled/button";
import { Form } from "@styled/form";
import { InputField } from "@styled/input_field";
import { Modal } from "@styled/modal";
import { ModalClose } from "@styled/modal_close";
import { ModalContent } from "@styled/modal_content";
import { ModalFooter } from "@styled/modal_footer";
import { ModalHeader } from "@styled/modal_header";
import { ModalTitle } from "@styled/modal_title";

export const WorkflowCloneModal: FC<{
  workflow: Pick<Workflow, "id" | "name">;
  onClose(): void;
}> = ({ workflow, onClose }) => {
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [execute, { loading, data }] = useWorkflowCloneMutation();
  const errors = data?.workflow.clone?.errors;

  const onSubmit: FormEventHandler = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (loading) return;

    const response = await execute({
      variables: { id: workflow.id, input: { name } },
    });

    const clone = response.data?.workflow.clone?.workflow;
    if (clone) navigate(`/dashboard/workflows/${clone.id}`);
  };

  return (
    <Modal onClose={onClose}>
      <Form onSubmit={onSubmit}>
        <ModalHeader>
          <ModalTitle>Clone "{workflow.name}"</ModalTitle>
          <ModalClose onClose={onClose} />
        </ModalHeader>

        <ModalContent>
          <p>
            Cloning a workflow copies the corresponding steps. It does not copy
            any schedules, webhooks, or runs associated with the workflow.
          </p>
          <InputField
            id="name"
            name="name"
            placeholder="Name"
            label="Name"
            icon={faIdCardClip}
            errors={errors}
            value={name}
            onChange={(event) => setName(event?.target.value)}
            required
          />
        </ModalContent>
        <ModalFooter>
          <Button
            type="button"
            disabled={loading}
            color="slate"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button type="submit" loading={loading} color="indigo">
            Clone
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
