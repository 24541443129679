import { type TextareaHTMLAttributes, forwardRef } from "react";

import { BaseField, type BaseFieldProps } from "./base_field";
import { TextArea } from "./text_area";

type T = HTMLTextAreaElement;
type P = TextareaHTMLAttributes<T> & BaseFieldProps & { mono?: boolean };

export const TextAreaField = forwardRef<T, P>(
  ({ id, icon, label, errors, name, hint, tooltip, ...props }, ref) => (
    <BaseField
      id={id}
      name={name}
      hint={hint}
      tooltip={tooltip}
      label={label}
      icon={icon}
      errors={errors}
    >
      {({ state }) => (
        <TextArea id={id} name={name} state={state} ref={ref} {...props} />
      )}
    </BaseField>
  ),
);
