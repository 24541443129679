import { type LoaderFunctionArgs } from "react-router";

import {
  BlogArticleDetailsDocument,
  type BlogArticleDetailsQuery,
  type BlogArticleDetailsQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const BLOG_ARTICLE_DETAILS_LOADER = async (
  props: LoaderFunctionArgs,
) => {
  const { data } = await client.query<
    BlogArticleDetailsQuery,
    BlogArticleDetailsQueryVariables
  >({
    query: BlogArticleDetailsDocument,
    variables: { slug: props.params.slug! },
  });

  return data;
};
