import { type ButtonHTMLAttributes, forwardRef } from "react";

import { faSpinner } from "@fortawesome/pro-duotone-svg-icons/faSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { style } from "./button_style";
import { type Color } from "./types";

type T = HTMLButtonElement;
type P = ButtonHTMLAttributes<T> & {
  color?: Color;
  loading?: boolean;
  full?: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
};

export const Button = forwardRef<T, P>(
  (
    { color, full, size, disabled, loading, children, className, ...props },
    ref,
  ) => (
    <button
      ref={ref}
      className={style({ color, full, size, className })}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : children}
    </button>
  ),
);
