import { type FC, type ReactNode } from "react";

import { Transition } from "@headlessui/react";

export const TransitionFade: FC<{
  show?: boolean;
  children: ReactNode;
}> = ({ show, children }) => (
  <Transition
    show={show}
    enter="transition-opacity duration-500"
    enterFrom={"opacity-0"}
    enterTo="opacity-100"
    leave="transition-opacity duration-500"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    {children}
  </Transition>
);
