import {
  DashboardRunsListDocument,
  type DashboardRunsListQuery,
  type DashboardRunsListQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_RUNS_LIST_LOADER = async () => {
  const { data } = await client.query<
    DashboardRunsListQuery,
    DashboardRunsListQueryVariables
  >({
    query: DashboardRunsListDocument,
    fetchPolicy: "network-only",
  });

  return data;
};
