import { type FC } from "react";

import { type Blog__Author } from "@app_schema";

import { BasicLink } from "@styled/basic_link";

export const BlogArticleAuthors: FC<{
  article: { authors: Array<Pick<Blog__Author, "id" | "slug" | "name">> };
}> = ({ article }) => (
  <div className="flex gap-2">
    {article.authors.map((author) => (
      <BasicLink to={`/blog/authors/${author.slug}`}>{author.name}</BasicLink>
    ))}
  </div>
);
