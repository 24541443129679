import { type FC } from "react";

import { faCube } from "@fortawesome/pro-duotone-svg-icons/faCube";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { type SkillFragment } from "@app_schema";

import { ButtonAnchor } from "@styled/button_anchor";

export const SkillPackageLink: FC<{ skill: SkillFragment }> = ({ skill }) => {
  if (!skill.package) return null;

  return (
    <ButtonAnchor href={skill.package.url} download>
      <FontAwesomeIcon icon={faCube} />
      <span>Download</span>
    </ButtonAnchor>
  );
};
