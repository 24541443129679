import { redirect } from "react-router";

import {
  LogoutDocument,
  type LogoutMutation,
  type LogoutMutationVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

import { reset } from "@application/utilities/reset";

export const LOGOUT_LOADER = async () => {
  await client.mutate<LogoutMutation, LogoutMutationVariables>({
    mutation: LogoutDocument,
  });

  reset();

  return redirect("/");
};
