import { type FC, type FormEventHandler, useState } from "react";

import { faPlusSquare } from "@fortawesome/pro-duotone-svg-icons/faPlusSquare";
import { faTrash } from "@fortawesome/pro-duotone-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { type JSONSchemaScalarNode } from "@application/types/json_schema_node";

import { Button } from "./button";
import { Input } from "./input";
import { List } from "./list";
import { ListItem } from "./list_item";
import { ListItemContent } from "./list_item_content";
import { ListItemControls } from "./list_item_controls";
import { ListItemText } from "./list_item_text";
import { ListItemTitle } from "./list_item_title";
import { Modal } from "./modal";
import { ModalClose } from "./modal_close";
import { ModalContent } from "./modal_content";
import { ModalFooter } from "./modal_footer";
import { ModalHeader } from "./modal_header";
import { ModalTitle } from "./modal_title";

const JSONSchemaEnumListItem: FC<{
  option: string;
  onDelete(): void;
}> = ({ option, onDelete }) => {
  return (
    <ListItem>
      <ListItemContent>
        <ListItemText>
          <ListItemTitle>{option}</ListItemTitle>
        </ListItemText>
        <ListItemControls>
          <Button color="rose" type="button" onClick={onDelete}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </ListItemControls>
      </ListItemContent>
    </ListItem>
  );
};

const JSONSchemaEnumList: FC<{
  options: string[];
  onChange(_: string[] | undefined): void;
}> = ({ options, onChange }) => {
  const onDelete = (index: number) => {
    const values = options.filter((_, i) => i !== index);
    onChange(values.length > 0 ? values : undefined);
  };

  return (
    <List>
      {options.map((option, index) => (
        <JSONSchemaEnumListItem
          key={index}
          option={option}
          onDelete={() => {
            onDelete(index);
          }}
        />
      ))}
    </List>
  );
};

export const JSONSchemaEnumDialog: FC<{
  node: JSONSchemaScalarNode;
  onChange(node: JSONSchemaScalarNode): void;
  onClose(): void;
}> = ({ node, onClose, onChange }) => {
  const [value, setValue] = useState<string>("");
  const onSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!value) return;
    if (node.enum?.includes(value)) return;

    const values = node.enum ? [...node.enum, value] : [value];
    values.sort();

    onChange({ ...node, enum: values });
    setValue("");
  };

  return (
    <Modal>
      <form onSubmit={onSubmit}>
        <ModalHeader>
          <ModalTitle>{node.title}</ModalTitle>
          <ModalClose onClose={onClose} />
        </ModalHeader>
        <ModalContent>
          {node.enum && (
            <JSONSchemaEnumList
              options={node.enum}
              onChange={(values) => {
                onChange({ ...node, enum: values });
              }}
            />
          )}
          <Input
            placeholder="Option"
            value={value}
            onChange={(event) => {
              setValue(event?.target.value);
            }}
          />
        </ModalContent>
        <ModalFooter>
          <Button type="submit" disabled={!value}>
            <FontAwesomeIcon icon={faPlusSquare} /> Enum
          </Button>
          <Button type="button" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
