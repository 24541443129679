import { type FC, type ReactNode } from "react";

import { clsx } from "clsx";

type Color = "slate" | "indigo" | "emerald" | "rose";

const COLOR_STYLE: Record<Color, string> = {
  emerald: "border-emerald-200 bg-emerald-100 text-emerald-600",
  indigo: "border-indigo-200 bg-indigo-100 text-indigo-600",
  slate: "border-slate-200 bg-slate-100 text-slate-600",
  rose: "border-rose-200 bg-rose-100 text-rose-600",
};

export const Pill: FC<{
  children: ReactNode;
  color?: Color;
}> = ({ color = "slate", ...props }) => (
  <div
    {...props}
    className={clsx(
      "flex items-center gap-2 rounded-full border px-3 py-2",
      COLOR_STYLE[color],
    )}
  />
);
