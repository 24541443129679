import { type FC } from "react";

import { type Execution, ExecutionStatusEnum } from "@app_schema";

import { Timer } from "@styled/timer";

export const ExecutionTimer: FC<{
  execution: Pick<Execution, "started" | "finished" | "status">;
}> = ({ execution: { started, finished, status } }) => (
  <Timer
    from={started}
    till={finished}
    paused={status !== ExecutionStatusEnum.Executing}
  />
);
