import { type FC, type FormEvent, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { faKey as faPassword } from "@fortawesome/pro-duotone-svg-icons/faKey";

import { useActivateMutation } from "@app_schema";

import { useTitle } from "@application/hooks/use_title";
import { useTrackPageView } from "@application/hooks/use_track_page_view";

import { AuthLayout } from "@layouts/auth_layout";

import { Button } from "@styled/button";
import { Card } from "@styled/card";
import { Form } from "@styled/form";
import { InputField } from "@styled/input_field";
import { Notification } from "@styled/notification";
import { Sentence } from "@styled/sentence";

import { World } from "../contexts/world";

export const Activate: FC = () => {
  useTrackPageView({ page: "activate" });
  useTitle("Workflow", "Activate");

  const navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const { login } = useContext(World);
  const { sid } = useParams<{ sid: string }>();
  const [execute, { loading, data }] = useActivateMutation();
  const errors = data?.activate?.errors;

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const input = { password };
    const result = await execute({ variables: { input, sid: sid! } });
    const token = result.data?.activate?.token;
    if (token) {
      login(token);
      navigate("/dashboard");
    }
  };

  return (
    <AuthLayout>
      <Card>
        <Form onSubmit={onSubmit}>
          <Notification color="indigo">
            Welcome. You are almost ready! To activate your account please
            choose a password.
          </Notification>

          {errors?.base && (
            <Notification color="rose">
              <Sentence>{errors.base}</Sentence>
            </Notification>
          )}

          <InputField
            autoFocus
            id="password"
            name="password"
            type="password"
            placeholder="Password"
            hint="Good passwords contain numbers, letters, symbols, and are at least 8 characters in length."
            icon={faPassword}
            errors={errors}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />

          <Button full loading={loading} type="submit">
            Activate
          </Button>
        </Form>
      </Card>
    </AuthLayout>
  );
};
