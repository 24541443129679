import { type FC } from "react";

import { type Run, RunStatusEnum } from "@app_schema";

import { Timer } from "@styled/timer";

export const RunTimer: FC<{
  run: Pick<Run, "started" | "finished" | "status">;
}> = ({ run: { started, finished, status } }) => (
  <Timer
    from={started}
    till={finished}
    paused={status !== RunStatusEnum.Running}
  />
);
