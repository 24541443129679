import { type FC } from "react";

import { Run, useRunDestoryMutation } from "@app_schema";

import { DestroyButton } from "@styled/destroy_button";

export const RunDestroyButton: FC<{
  run: Pick<Run, "id"> & { workflow: Pick<Run["workflow"], "name"> };
  onDestroy?(): void;
}> = ({ run, onDestroy }) => {
  const [execute, { loading }] = useRunDestoryMutation();

  const destroy = async () => {
    await execute({ variables: { id: run.id } });
    if (onDestroy) onDestroy();
  };

  return (
    <DestroyButton
      destroy={destroy}
      loading={loading}
      confirmation={
        <>
          Are you sure you want to delete run #{run.id} on "{run.workflow.name}
          "? If deleted any linked webhooks, schedules, and runs are also
          deleted.
        </>
      }
    />
  );
};
