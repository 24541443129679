import { type FC } from "react";

import { Skill, useSkillDestroyMutation } from "@app_schema";

import { DestroyButton } from "@styled/destroy_button";

export const SkillDestroyButton: FC<{
  skill: Pick<Skill, "id" | "name">;
}> = ({ skill }) => {
  const [execute, { loading }] = useSkillDestroyMutation({
    variables: {
      id: skill.id,
    },
  });

  return (
    <DestroyButton
      destroy={() => {
        execute();
      }}
      loading={loading}
      confirmation={<>Are you sure you want to delete "{skill.name}"?</>}
    />
  );
};
