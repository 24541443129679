import { type FC } from "react";

import { faTrash } from "@fortawesome/pro-duotone-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  StepFragment,
  WorkflowFragment,
  useStepDestroyMutation,
} from "@app_schema";

import { useDialog } from "@application/hooks/use_dialog";

import { Dialog } from "@styled/dialog";
import { NodeButton } from "@styled/node_button";

export const WorkflowStepDestroyNodeButton: FC<{
  workflow: WorkflowFragment;
  step: StepFragment;
}> = ({ workflow, step }) => {
  const { confirm, dialog } = useDialog();

  const [execute, { loading }] = useStepDestroyMutation({
    variables: {
      workflowID: workflow.id,
      stepID: step.id,
    },
  });

  const onClick = async () => {
    try {
      await confirm();
    } catch {
      return;
    }

    await execute();
  };

  return (
    <>
      {dialog && (
        <Dialog {...dialog} title="Are you sure?">
          Are you sure you want to delete "{step.name}"?
        </Dialog>
      )}

      <NodeButton loading={loading} onClick={onClick}>
        <FontAwesomeIcon icon={faTrash} />
      </NodeButton>
    </>
  );
};
