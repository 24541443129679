import { type FC } from "react";

import { type Blog__Article } from "@app_schema";

export const BlogArticleTags: FC<{
  article: Pick<Blog__Article, "tags">;
}> = ({ article }) => (
  <div className="flex flex-wrap gap-2">
    {article.tags.map((tag) => (
      <div className="font-medium text-slate-400">#{tag}</div>
    ))}
  </div>
);
