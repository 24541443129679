import { type FC } from "react";
import { useNavigate, useParams } from "react-router";

import {
  useDashboardStepsDetailsQuery,
  useStepChangeMutation,
} from "@app_schema";

import { DashboardStepsForm } from "./steps_form";

export const DashboardStepsEdit: FC = () => {
  const navigate = useNavigate();
  const { workflowID, stepID } = useParams<{
    workflowID: string;
    stepID: string;
  }>();
  const { data: defaults } = useDashboardStepsDetailsQuery({
    variables: { workflowID: workflowID!, stepID: stepID! },
  });
  const step = defaults?.workflow.step;

  const [execute, { loading, data }] = useStepChangeMutation();
  const errors = data?.workflow.steps.step.change?.errors;

  if (!step) return;

  return (
    <DashboardStepsForm
      loading={loading}
      save={async (input) => {
        const result = await execute({
          variables: { workflowID: workflowID!, stepID: stepID!, input },
        });
        const step = result.data?.workflow.steps.step.change.step;

        if (step)
          navigate(`/dashboard/workflows/${workflowID}/steps/${step.id}`);
      }}
      errors={errors}
      step={step}
      {...step}
    />
  );
};
