import { type FC, type ReactNode } from "react";

import { clsx } from "clsx";

type Color = "indigo" | "slate";

const STYLE_FOR_COLOR: Record<Color, string> = {
  indigo: "bg-indigo-100 border-indigo-200",
  slate: "bg-slate-100 border-slate-200",
};

export const Node: FC<{
  color?: Color;
  children: ReactNode;
}> = ({ color = "slate", children }) => (
  <div
    className={clsx("relative rounded-md border p-4", STYLE_FOR_COLOR[color])}
  >
    {children}
  </div>
);
