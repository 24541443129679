import { type HTMLAttributes, forwardRef } from "react";

import { clsx } from "clsx";

import { tw } from "./tw";

type Align = "l" | "r";

type T = HTMLSpanElement;
type P = HTMLAttributes<T> & {
  align: Align;
};

const BASE_STYLE = tw`point-events-none absolute bottom-0 top-0 flex size-10 items-center justify-center`;

const L_STYLE = tw`left-0`;
const R_STYLE = tw`right-0`;

export const Icon = forwardRef<T, P>(({ align, className, ...props }, ref) => (
  <span
    ref={ref}
    className={clsx(
      BASE_STYLE,
      align === "l" && L_STYLE,
      align === "r" && R_STYLE,
      className,
    )}
    {...props}
  />
));

Icon.displayName = "Icon";
