import mixpanel from "mixpanel-browser";

import { MIXPANEL_ENABLED } from "@application/initializers/mixpanel";

type Event = "signup" | "login";
type Properties = Record<string, string | number>;

export const track = (event: Event, properties?: Properties) => {
  if (MIXPANEL_ENABLED) {
    mixpanel.track(event, properties);
  }
};
