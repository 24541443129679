import { type FC } from "react";
import { useFormContext } from "react-hook-form";

import { useDashboardSkillsFieldQuery } from "@app_schema";

import { BaseField } from "@styled/base_field";
import { LabelCheckbox } from "@styled/label_checkbox";
import { LabelCheckboxDescription } from "@styled/label_checkbox_description";
import { LabelCheckboxName } from "@styled/label_checkbox_name";

export const DashboardSkillsField: FC<{
  name: string;
}> = ({ name }) => {
  const { register } = useFormContext();
  const { data } = useDashboardSkillsFieldQuery();
  const skills = data?.skills.filter(({ deleted }) => !deleted);

  return (
    <BaseField name="skill_ids" label="Skills:">
      {() => (
        <div className="flex flex-col gap-2">
          {skills?.map((vault) => (
            <LabelCheckbox {...register(name)} value={vault.id}>
              <LabelCheckboxName>{vault.name}</LabelCheckboxName>
              <LabelCheckboxDescription>
                {vault.description}
              </LabelCheckboxDescription>
            </LabelCheckbox>
          ))}
        </div>
      )}
    </BaseField>
  );
};
