import { type FC, type ReactNode, useContext } from "react";

import { MessageContext } from "./message_context";
import { MessagesContext } from "./messages_context";

export const MessageProvider: FC<{
  index: number;
  children: ReactNode;
}> = ({ index, children }) => {
  const { name, onDelete } = useContext(MessagesContext);

  return (
    <MessageContext.Provider
      value={{
        name: `${name}.${index}`,
        onDelete: () => {
          onDelete(index);
        },
      }}
      children={children}
    />
  );
};
