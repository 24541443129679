import { type FC } from "react";

import { useTitle } from "@application/hooks/use_title";
import { useTrackPageView } from "@application/hooks/use_track_page_view";

import { PageLayout } from "@layouts/page_layout";

import { Features } from "./home/features";
import { Hero } from "./home/hero";
import { Prices } from "./home/prices";
import { Screenshots } from "./home/screenshots";

export const Home: FC = () => {
  useTrackPageView({ page: "login" });
  useTitle("Workflow", "Home");

  return (
    <PageLayout>
      <Hero />
      <Screenshots />
      <Features />
      <Prices />
    </PageLayout>
  );
};
