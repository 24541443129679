import { type FC } from "react";
import { Link } from "react-router-dom";

import { type MailboxFragment } from "@app_schema";

import { Attribute } from "@styled/attribute";
import { Attributes } from "@styled/attributes";

export const MailboxAttributes: FC<{
  mailbox: MailboxFragment;
}> = ({ mailbox }) => (
  <Attributes>
    <Attribute name="Workflow">
      <Link to={`/dashboard/workflows/${mailbox.workflow.id}`}>
        {mailbox.workflow.name}
      </Link>
    </Attribute>
    <Attribute name="Address">{mailbox.address}</Attribute>
  </Attributes>
);
