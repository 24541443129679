import {
  DashboardSkillsListDocument,
  type DashboardSkillsListQuery,
  type DashboardSkillsListQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_SKILLS_LIST_LOADER = async () => {
  const { data } = await client.query<
    DashboardSkillsListQuery,
    DashboardSkillsListQueryVariables
  >({
    query: DashboardSkillsListDocument,
    fetchPolicy: "network-only",
  });

  return data;
};
