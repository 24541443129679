import { type FC } from "react";

import { type Run, RunStatusEnum } from "@app_schema";

import { DateTimeFormatter } from "@styled/date_time_formatter";

const NAMES = {
  [RunStatusEnum.Running]: "Running",
  [RunStatusEnum.Paused]: "Paused",
  [RunStatusEnum.Succeeded]: "Succeeded",
  [RunStatusEnum.Failed]: "Failed",
};

export const RunInfo: FC<{
  run: Pick<Run, "id" | "status" | "started" | "finished" | "error">;
}> = ({ run }) => (
  <>
    <div>Status: {NAMES[run.status]}</div>

    {run.error && <div>Error: {run.error}</div>}

    {run.started && (
      <div>
        Started: <DateTimeFormatter iso={run.started} />
      </div>
    )}

    {run.finished && (
      <div>
        Finished: <DateTimeFormatter iso={run.finished} />
      </div>
    )}
  </>
);
