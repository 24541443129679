import { type FC } from "react";

import { User, useUserDestoryMutation } from "@app_schema";

import { DestroyButton } from "@styled/destroy_button";

export const UsersDestroyButton: FC<{
  user: Pick<User, "id" | "name" | "deleted" | "me">;
}> = ({ user }) => {
  const [execute, { loading }] = useUserDestoryMutation();

  return (
    <DestroyButton
      disabled={user.me}
      destroy={() => {
        execute({ variables: { id: user.id } });
      }}
      loading={loading}
      confirmation={<>Are you sure you want to delete "{user.name}"?</>}
    />
  );
};
