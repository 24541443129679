export const groupParentID = <T extends { parentID?: string | null }>(
  entries: T[],
) => {
  const groups: Map<string, T[]> = new Map();

  for (const entry of entries) {
    const parentID = entry.parentID;
    if (!parentID) continue;

    const group = groups.get(parentID);
    if (group) {
      group.push(entry);
    } else {
      groups.set(parentID, [entry]);
    }
  }

  return groups;
};
