import { type FC, type ReactNode } from "react";

export const Attribute: FC<{
  name: string;
  children: ReactNode;
}> = ({ name, children }) => (
  <div className="flex flex-col">
    <div className="font-medium text-slate-400">{name}:</div>
    <div className="font-normal text-slate-600">{children}</div>
  </div>
);
