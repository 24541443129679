import { useEffect, useState } from "react";

export const useStorage = (
  storage: Storage,
  key: string,
): [string | undefined, (_: string | undefined) => void] => {
  const [value, setValue] = useState(() => storage.getItem(key) ?? undefined);

  useEffect(() => {
    const listener = () => {
      setValue(storage.getItem(key) ?? undefined);
    };

    addEventListener("storage", listener);

    return () => {
      removeEventListener("storage", listener);
    };
  }, [storage, key]);

  return [
    value,
    (value: string | undefined) => {
      setValue(value);

      if (value) storage.setItem(key, value);
      else storage.removeItem(key);
    },
  ];
};
