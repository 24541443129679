import { type FC, useEffect } from "react";

import { faConveyorBelt } from "@fortawesome/pro-duotone-svg-icons/faConveyorBelt";
import { faSpinner } from "@fortawesome/pro-duotone-svg-icons/faSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { type SkillFragment, useSkillPackageMutation } from "@app_schema";

import { Attribute } from "@styled/attribute";
import { Badge } from "@styled/badge";
import { Button } from "@styled/button";
import { Modal } from "@styled/modal";
import { ModalClose } from "@styled/modal_close";
import { ModalContent } from "@styled/modal_content";
import { ModalFooter } from "@styled/modal_footer";
import { ModalHeader } from "@styled/modal_header";
import { ModalTitle } from "@styled/modal_title";
import { Notification } from "@styled/notification";
import { Well } from "@styled/well";

import { SkillPackageLink } from "./skill_package_link";

export const SkillPackageDialog: FC<{
  skill: SkillFragment;
  onClose(): void;
}> = ({ skill, onClose }) => {
  const [execute, { loading, data }] = useSkillPackageMutation({
    variables: { id: skill.id },
  });
  const capture = data?.skill.package.capture;
  const error = data?.skill.package.error;
  const success = capture?.success;
  const stdout = capture?.stdout;
  const stderr = capture?.stderr;

  useEffect(() => {
    execute();
  }, [execute]);

  const run = () => {
    if (loading) return;
    execute();
  };

  return (
    <Modal onClose={onClose}>
      <ModalHeader>
        <ModalTitle>{skill.name}</ModalTitle>
        <ModalClose onClose={onClose} />
      </ModalHeader>

      <ModalContent>
        {error && <Notification color="rose">{error}</Notification>}

        {!data ? (
          <Notification color="slate">
            Packaging...
            <FontAwesomeIcon icon={faSpinner} spin />
          </Notification>
        ) : (
          <div className="flex flex-col gap-4">
            <Attribute name="STATUS">
              <Badge color={success ? "emerald" : "rose"}>
                {success ? "Success" : "Failure"}
              </Badge>
            </Attribute>

            {stdout && (
              <Well name="STDOUT">
                <pre>{stdout}</pre>
              </Well>
            )}

            {stderr && (
              <Well name="STDERR">
                <pre>{stderr}</pre>
              </Well>
            )}
          </div>
        )}
      </ModalContent>
      <ModalFooter>
        <SkillPackageLink skill={skill} />
        <Button type="button" loading={loading} onClick={run}>
          <FontAwesomeIcon icon={faConveyorBelt} />
          <span>Repackage</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
