import { type FC } from "react";

import { faRightToBracket } from "@fortawesome/pro-duotone-svg-icons/faRightToBracket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ButtonLink } from "@styled/button_link";

export const ButtonLinkLogin: FC = () => (
  <ButtonLink to="/login">
    <FontAwesomeIcon icon={faRightToBracket} />
    Login
  </ButtonLink>
);
