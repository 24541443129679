import { useEffect } from "react";

import mixpanel from "mixpanel-browser";

import { MIXPANEL_ENABLED } from "@application/initializers/mixpanel";

export const useTrackPageView = ({ page }: { page: string }) => {
  useEffect(() => {
    if (!MIXPANEL_ENABLED) return;
    mixpanel.track_pageview({ page });
  }, [page]);
};
