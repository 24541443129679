import { type FC } from "react";
import { useParams } from "react-router";

import {
  type Action__BranchFragment,
  type Action__CodeFragment,
  type Action__FormatterFragment,
  type Action__InputFragment,
  type Action__IteratorFragment,
  Action__IteratorKind,
  type Action__LearnFragment,
  type Action__NotifyFragment,
  type Action__OutputFragment,
  type Action__PromptFragment,
  Action__SpeechFragment,
  type Action__TranscriptionFragment,
  useDashboardStepsDetailsQuery,
} from "@app_schema";

import { Attribute } from "@styled/attribute";
import { Badge } from "@styled/badge";
import { Badges } from "@styled/badges";
import { Headline } from "@styled/headline";
import { Highlighter } from "@styled/highlighter";
import { HTML } from "@styled/html";
import { Page } from "@styled/page";
import { Well } from "@styled/well";

import { ConnectionBadgeLink } from "./connection_badge_link";
import { FormatterSummary } from "./formatter_summary";
import { SkillBadgeLink } from "./skill_badge_link";
import { StepsDestroyButton } from "./steps_destroy_button";
import { StepsModifyLink } from "./steps_modify_link";
import { StepsRunButton } from "./steps_run_button";

const ActionBranchDetails: FC<{
  action: Action__BranchFragment;
}> = ({ action }) => (
  <>
    <Attribute name="Kind">Code</Attribute>
    <Attribute name="Workflow">{action.workflow.name}</Attribute>
    {action.params && <Attribute name="Params">{action.params}</Attribute>}
  </>
);

const ActionCodeDetails: FC<{
  action: Action__CodeFragment;
}> = ({ action }) => (
  <>
    <Attribute name="Kind">Code</Attribute>
    <Attribute name="Language">{action.language}</Attribute>
    <Attribute name="Image">{action.image}</Attribute>
    <Attribute name="Template">
      <Well>
        <HTML html={action.html} />
      </Well>
    </Attribute>
  </>
);

const ActionFormatterDetails: FC<{
  action: Action__FormatterFragment;
}> = ({ action }) => (
  <>
    <Attribute name="Kind">Formatter</Attribute>
    <Attribute name="Language">{action.language}</Attribute>
    <Attribute name="Template">
      <Well>
        <HTML html={action.html} />
      </Well>
    </Attribute>
  </>
);

const ActionLearnDetails: FC<{
  action: Action__LearnFragment;
}> = ({ action }) => (
  <>
    <Attribute name="Kind">Learn</Attribute>
    <Attribute name="Name">{action.name}</Attribute>
    <Attribute name="Documentation">{action.documentation}</Attribute>
    <Attribute name="Code">{action.code}</Attribute>
    <Attribute name="Test">{action.test}</Attribute>
  </>
);

const ActionNotifyDetails: FC<{
  action: Action__NotifyFragment;
}> = ({ action }) => (
  <>
    <Attribute name="Language">{action.language}</Attribute>
    <Attribute name="To">{action.to}</Attribute>
    <Attribute name="Subject">{action.subject}</Attribute>
    <Attribute name="Body">{action.body}</Attribute>
  </>
);

const ActionPromptDetails: FC<{
  action: Action__PromptFragment;
}> = ({ action }) => (
  <>
    <Attribute name="Kind">Prompt</Attribute>
    <Attribute name="Model">{action.model}</Attribute>
    <Attribute name="Temperature">{action.temperature}</Attribute>
    <Attribute name="Messages">
      {action.messages.map((message) => (
        <div>
          <Well>
            {message.role}
            <Highlighter template={message.template} />
            {message.attachments
              .map((attachment) => attachment.path)
              .join(" + ")}
          </Well>
        </div>
      ))}
    </Attribute>
    <Attribute name="Tools">
      <Badges>
        {action.tools.map((tool) => (
          <SkillBadgeLink key={tool.id} skill={tool.skill} />
        ))}
      </Badges>
      {action.tools.length === 0 && <>N/A</>}
    </Attribute>
    {action.formatter && (
      <Attribute name="Formatter">
        <Well>
          <pre>
            <FormatterSummary formatter={action.formatter} />
          </pre>
        </Well>
      </Attribute>
    )}
  </>
);

const ActionSpeechDetails: FC<{
  action: Action__SpeechFragment;
}> = ({ action }) => (
  <>
    <Attribute name="Kind">Speech</Attribute>
    <Attribute name="Template">
      <Well>
        <Highlighter template={action.template} />
      </Well>
    </Attribute>
    <Attribute name="Voice">{action.voice}</Attribute>
    <Attribute name="Model">{action.speechModel}</Attribute>
    <Attribute name="Format">{action.speechFormat}</Attribute>
    <Attribute name="Speed">{action.speed ?? "Default"}</Attribute>
  </>
);

const ActionTranscriptionDetails: FC<{
  action: Action__TranscriptionFragment;
}> = ({ action }) => (
  <>
    <Attribute name="Kind">Transcription</Attribute>
    <Attribute name="Model">{action.transcriptionModel}</Attribute>
    <Attribute name="Path">{action.path}</Attribute>
    <Attribute name="Temperature">{action.temperature}</Attribute>
    <Attribute name="Prompt">{action.prompt || "N/A"}</Attribute>
    <Attribute name="Format">{action.format}</Attribute>
  </>
);

const ActionIteratorDetails: FC<{ action: Action__IteratorFragment }> = ({
  action,
}) => (
  <>
    <Attribute name="Kind">{action.iteratorKind}</Attribute>
    {action.iteratorKind === Action__IteratorKind.Times && (
      <Attribute name="Times">Iterations: {action.timesIterations}</Attribute>
    )}
    {action.iteratorKind === Action__IteratorKind.Over && (
      <Attribute name="Over">Key: {action.overKey}</Attribute>
    )}
    {action.iteratorKind === Action__IteratorKind.Until && (
      <Attribute name="Until">Key: {action.untilKey}</Attribute>
    )}
  </>
);

const ActionInputDetails: FC<{
  action: Action__InputFragment;
}> = ({ action }) => (
  <>
    <Attribute name="Kind">{action.inputKind}</Attribute>
  </>
);

const ActionOutputDetails: FC<{
  action: Action__OutputFragment;
}> = ({ action }) => (
  <>
    <Attribute name="Language">{action.language}</Attribute>
    <Attribute name="Template">
      <Well>
        <HTML html={action.html} />
      </Well>
    </Attribute>
  </>
);

export const DashboardStepsDetails: FC = () => {
  const { workflowID, stepID } = useParams<{
    workflowID: string;
    stepID: string;
  }>();

  const { data: defaults } = useDashboardStepsDetailsQuery({
    variables: { workflowID: workflowID!, stepID: stepID! },
  });
  const step = defaults?.workflow.step;
  if (!step) return null;
  const action = step.action;

  return (
    <Page>
      <Headline title={step?.name}>
        <StepsRunButton workflowID={workflowID!} stepID={stepID!} step={step} />
        <StepsModifyLink workflowID={workflowID!} stepID={stepID!} />
        <StepsDestroyButton
          workflowID={workflowID!}
          stepID={stepID!}
          step={step}
        />
      </Headline>

      <Attribute name="Name">{step.name}</Attribute>

      {step.secrets.length > 0 && (
        <Attribute name="Secrets">
          <Badges>
            {step.secrets.map((secret) => (
              <Badge key={secret.id}>{secret.key}</Badge>
            ))}
          </Badges>
        </Attribute>
      )}

      {step.connections.length > 0 && (
        <Attribute name="Connections">
          <Badges>
            {step.connections.map((connection) => (
              <ConnectionBadgeLink
                key={connection.id}
                connection={connection}
              />
            ))}
          </Badges>
        </Attribute>
      )}

      {action.__typename === "Action__Branch" && (
        <ActionBranchDetails action={action} />
      )}

      {action.__typename === "Action__Code" && (
        <ActionCodeDetails action={action} />
      )}

      {action.__typename === "Action__Learn" && (
        <ActionLearnDetails action={action} />
      )}

      {action.__typename === "Action__Prompt" && (
        <ActionPromptDetails action={action} />
      )}

      {action.__typename === "Action__Formatter" && (
        <ActionFormatterDetails action={action} />
      )}

      {action.__typename === "Action__Iterator" && (
        <ActionIteratorDetails action={action} />
      )}

      {action.__typename === "Action__Input" && (
        <ActionInputDetails action={action} />
      )}

      {action.__typename === "Action__Notify" && (
        <ActionNotifyDetails action={action} />
      )}

      {action.__typename === "Action__Output" && (
        <ActionOutputDetails action={action} />
      )}

      {action.__typename === "Action__Speech" && (
        <ActionSpeechDetails action={action} />
      )}

      {action.__typename === "Action__Transcription" && (
        <ActionTranscriptionDetails action={action} />
      )}
    </Page>
  );
};
