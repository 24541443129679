import { type FC } from "react";

import { faTimes } from "@fortawesome/pro-duotone-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ModalClose: FC<{ onClose(): void }> = ({ onClose }) => (
  <button
    type="button"
    className="z-50 -m-2 flex w-8 items-center justify-center rounded-md bg-white p-2 transition-colors hover:bg-slate-200"
    onClick={onClose}
  >
    <FontAwesomeIcon icon={faTimes} />
  </button>
);
