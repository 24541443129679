import { type ButtonHTMLAttributes, type FC } from "react";

import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/pro-duotone-svg-icons/faSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type T = HTMLButtonElement;
type P = ButtonHTMLAttributes<T> & {
  icon: IconProp;
  loading?: boolean;
};

export const IconButton: FC<P> = ({
  loading,
  icon,
  type = "button",
  ...props
}) => (
  <button
    type={type}
    {...props}
    className="flex h-8 w-8 items-center justify-center rounded-md px-2 py-1 text-slate-500 hover:bg-indigo-500 hover:text-white disabled:bg-transparent disabled:text-slate-500/50"
  >
    <FontAwesomeIcon icon={loading ? faSpinner : icon} spin={loading} />
  </button>
);
