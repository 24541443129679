import { type InputHTMLAttributes, forwardRef } from "react";

import { BaseField, type BaseFieldProps } from "./base_field";
import { Input } from "./input";

type T = HTMLInputElement;
type P = InputHTMLAttributes<T> & BaseFieldProps;

export const InputField = forwardRef<T, P>(
  ({ id, icon, label, errors, name, hint, tooltip, ...props }, ref) => (
    <BaseField
      id={id}
      name={name}
      hint={hint}
      tooltip={tooltip}
      label={label}
      icon={icon}
      errors={errors}
    >
      {({ state }) => (
        <Input id={id} name={name} state={state} ref={ref} {...props} />
      )}
    </BaseField>
  ),
);

InputField.displayName = "InputField";
