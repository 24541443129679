import { type FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useDashboardAgentsDetailsQuery } from "@app_schema";

import { Attribute } from "@styled/attribute";
import { Badges } from "@styled/badges";
import { Headline } from "@styled/headline";
import { ModifyLink } from "@styled/modify_link";
import { Page } from "@styled/page";

import { AgentConverseButton } from "./agent_converse_button";
import { AgentDestroyButton } from "./agent_destroy_button";
import { AgentName } from "./agent_name";
import { ConnectionBadgeLink } from "./connection_badge_link";
import { SecretBadgeLink } from "./secret_badge_link";
import { SkillBadgeLink } from "./skill_badge_link";
import { VaultBadgeLink } from "./vault_badge_link";

export const DashboardAgentsDetails: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  if (!id) throw new Error("undefined ID");
  const { data } = useDashboardAgentsDetailsQuery({ variables: { id } });
  const agent = data?.agent;

  if (!agent) return;

  return (
    <Page>
      <Headline title={<AgentName agent={agent} />}>
        <AgentConverseButton agent={agent} />
        <ModifyLink to={`/dashboard/agents/${agent.id}/edit`} />
        <AgentDestroyButton
          agent={agent}
          onDestroy={() => navigate("/dashboard/agents")}
        />
      </Headline>

      <Attribute name="Name">{agent.name}</Attribute>
      <Attribute name="Temperature">{agent.temperature}</Attribute>
      <Attribute name="Target">{agent.target}</Attribute>

      <Attribute name="Instructions">
        {agent.instructions ? <pre>{agent.instructions}</pre> : "N/A"}
      </Attribute>

      <Attribute name="Connections">
        {agent.connections.length === 0 ? (
          <>None</>
        ) : (
          <Badges>
            {agent.connections.map((connection) => (
              <ConnectionBadgeLink
                key={connection.id}
                connection={connection}
              />
            ))}
          </Badges>
        )}
      </Attribute>

      <Attribute name="Secrets">
        {agent.secrets.length === 0 ? (
          <>None</>
        ) : (
          <Badges>
            {agent.secrets.map((secret) => (
              <SecretBadgeLink key={secret.id} secret={secret} />
            ))}
          </Badges>
        )}
      </Attribute>

      <Attribute name="Skills">
        {agent.skills.length === 0 ? (
          <>None</>
        ) : (
          <Badges>
            {agent.skills.map((skill) => (
              <SkillBadgeLink key={skill.id} skill={skill} />
            ))}
          </Badges>
        )}
      </Attribute>

      <Attribute name="Vaults">
        {agent.vaults.length === 0 ? (
          <>None</>
        ) : (
          <Badges>
            {agent.vaults.map((vault) => (
              <VaultBadgeLink key={vault.id} vault={vault} />
            ))}
          </Badges>
        )}
      </Attribute>
    </Page>
  );
};
