import { type FC } from "react";

import { clsx } from "clsx";

import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ACTIVE_STYLE = "bg-white shadow font-semibold";
const INACTIVE_STYLE = "font-medium";

const ACTIVE_ICON_STYLE = "text-indigo-600";
const INACTIVE_ICON_STYLE = "text-slate-600";

export const SwitchButton: FC<{
  icon: IconProp;
  text: string;
  active?: boolean;
  onSelect(): void;
}> = ({ active, icon, text, onSelect }) => (
  <button
    type="button"
    disabled={active}
    onClick={() => onSelect()}
    className={clsx(
      "flex items-center justify-center gap-2 rounded-full px-6 py-2",
      active ? ACTIVE_STYLE : INACTIVE_STYLE,
    )}
  >
    <FontAwesomeIcon
      icon={icon}
      className={active ? ACTIVE_ICON_STYLE : INACTIVE_ICON_STYLE}
    />
    <span>{text}</span>
  </button>
);
