import { type HTMLAttributes, forwardRef } from "react";

import { clsx } from "clsx";

type T = HTMLDivElement;
type P = HTMLAttributes<T>;

export const Badges = forwardRef<T, P>(({ className, ...props }, ref) => (
  <div ref={ref} className={clsx("flex gap-2", className)} {...props} />
));
