import { type FC, type FormEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";

import { faPaperPlane } from "@fortawesome/pro-duotone-svg-icons/faPaperPlane";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { type Agent, useConversationBuildMutation } from "@app_schema";

import { Button } from "@styled/button";
import { Input } from "@styled/input";
import { Modal } from "@styled/modal";
import { ModalClose } from "@styled/modal_close";
import { ModalContent } from "@styled/modal_content";
import { ModalFooter } from "@styled/modal_footer";
import { ModalHeader } from "@styled/modal_header";
import { ModalTitle } from "@styled/modal_title";

export const AgentConverseDialog: FC<{
  agent: Pick<Agent, "id" | "name">;
  onClose(): void;
}> = ({ agent, onClose }) => {
  const [text, setText] = useState("");
  const navigate = useNavigate();
  const [execute, { loading }] = useConversationBuildMutation();

  const onSubmit: FormEventHandler = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (loading) return;
    if (!text) return;

    const response = await execute({
      variables: {
        input: {
          agentID: agent.id,
          message: { text },
        },
      },
    });

    const converation = response.data?.conversations.build.conversation;
    if (converation) navigate(`/dashboard/conversations/${converation.id}`);
  };

  return (
    <Modal>
      <form onSubmit={onSubmit}>
        <ModalHeader>
          <ModalTitle>{agent.name}</ModalTitle>
          <ModalClose onClose={onClose} />
        </ModalHeader>

        <ModalContent>
          <Input
            autoFocus
            required
            placeholder={`Message...`}
            value={text}
            onChange={(event) => {
              setText(event.target.value);
            }}
          />
        </ModalContent>

        <ModalFooter>
          <Button
            color="slate"
            disabled={loading}
            type="button"
            onClick={onClose}
          >
            Close
          </Button>
          <Button loading={loading} type="submit" disabled={!text}>
            <FontAwesomeIcon icon={faPaperPlane} /> Send
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
