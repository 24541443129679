import { type FC, type ReactNode } from "react";

import { useClassList } from "@application/hooks/use_class_list";

import { Footer } from "@styled/footer";
import { Header } from "@styled/header";

export const AuthLayout: FC<{
  children: ReactNode;
}> = ({ children }) => {
  useClassList(document.body, "bg-slate-50");

  return (
    <div className="min-w-lg container mx-auto max-w-md space-y-8 px-4 py-8">
      <Header />

      {children}

      <Footer />
    </div>
  );
};
