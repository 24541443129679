import { type FC, type FormEvent, useState } from "react";

import { faEnvelope as faEmail } from "@fortawesome/pro-duotone-svg-icons/faEnvelope";
import { faIdCardClip as faName } from "@fortawesome/pro-duotone-svg-icons/faIdCardClip";

import { MeFragment, useUserChangeMutation } from "@app_schema";

import { Button } from "@styled/button";
import { Form } from "@styled/form";
import { InputField } from "@styled/input_field";
import { Notification } from "@styled/notification";
import { Sentence } from "@styled/sentence";

export const SettingsUserChangeForm: FC<{
  me: MeFragment;
}> = ({ me }) => {
  const [execute, { loading, data }] = useUserChangeMutation();
  const errors = data?.user.change.errors;

  const [name, setName] = useState<string>(me.name);
  const [email, setEmail] = useState<string>(me.email);

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (loading) return;

    execute({
      variables: {
        input: {
          name,
          email,
        },
      },
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      {errors?.base && (
        <Notification color="rose">
          <Sentence>{errors.base}</Sentence>
        </Notification>
      )}

      <InputField
        id="name"
        name="name"
        type="text"
        label="Name:"
        placeholder="First Last"
        icon={faName}
        value={name}
        errors={errors}
        onChange={(event) => setName(event.target.value)}
      />

      <InputField
        id="email"
        name="email"
        type="email"
        label="Email:"
        placeholder="me@workflow.ing"
        icon={faEmail}
        value={email}
        errors={errors}
        onChange={(event) => setEmail(event.target.value)}
      />

      <Button type="submit" loading={loading}>
        Save Profile
      </Button>
    </Form>
  );
};
