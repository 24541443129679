import { type FC } from "react";

const TAB_SIZE = 4;

const format = (
  json: unknown | null | undefined,
): string | number | boolean | null | undefined => {
  if (json === null) return json;
  if (json === undefined) return json;
  if (typeof json === "string") return json;
  if (typeof json === "number") return json;
  if (typeof json === "boolean") return json;
  return JSON.stringify(json, null, TAB_SIZE);
};

export const JSONFormatter: FC<{ json: unknown | null | undefined }> = ({
  json,
}) => {
  return <pre className="overflow-scroll">{format(json) ?? "N/A"}</pre>;
};
