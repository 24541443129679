import { type FC, useState } from "react";

import { faPencilSquare } from "@fortawesome/pro-duotone-svg-icons/faPencilSquare";
import { faPlusSquare } from "@fortawesome/pro-duotone-svg-icons/faPlusSquare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { type JSONSchemaScalarNode } from "@application/types/json_schema_node";

import { Button } from "./button";
import { JSONSchemaEnumDialog } from "./json_schema_enum_dialog";

export const JSONSchemaEnumButton: FC<{
  node: JSONSchemaScalarNode;
  onChange(node: JSONSchemaScalarNode): void;
}> = ({ node, onChange }) => {
  const [visible, setVisible] = useState(false);
  const onToggle = () => {
    setVisible(!visible);
  };

  const options = node.enum;

  return (
    <>
      <Button color="white" type="button" onClick={onToggle}>
        <FontAwesomeIcon
          icon={options !== undefined ? faPencilSquare : faPlusSquare}
        />
        <span className="whitespace-nowrap">
          {options ? `${options.length} enum(s)` : "enum"}
        </span>
      </Button>
      {visible && (
        <JSONSchemaEnumDialog
          node={node}
          onChange={onChange}
          onClose={onToggle}
        />
      )}
    </>
  );
};
