import { type FC, useState } from "react";

import { faPlayCircle } from "@fortawesome/pro-duotone-svg-icons/faPlayCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { type Step } from "@app_schema";

import { Button } from "@styled/button";

import { StepsRunDialog } from "./steps_run_dialog";

export const StepsRunButton: FC<{
  workflowID: string;
  stepID: string;
  step: Pick<Step, "id" | "name"> & {
    action: Pick<Step["action"], "variables">;
  };
}> = ({ workflowID, stepID, step }) => {
  const [running, setRunning] = useState<boolean>(false);

  return (
    <>
      {running && (
        <StepsRunDialog
          workflowID={workflowID}
          stepID={stepID}
          step={step}
          onClose={() => setRunning(!running)}
        />
      )}

      <Button type="button" onClick={() => setRunning(!running)}>
        <FontAwesomeIcon icon={faPlayCircle} /> Run
      </Button>
    </>
  );
};
