import { LoaderFunctionArgs } from "react-router";

import {
  DashboardWorkflowsDetailsDocument,
  type DashboardWorkflowsDetailsQuery,
  type DashboardWorkflowsDetailsQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_WORKFLOWS_DETAILS_LOADER = async (
  props: LoaderFunctionArgs,
) => {
  const { data } = await client.query<
    DashboardWorkflowsDetailsQuery,
    DashboardWorkflowsDetailsQueryVariables
  >({
    query: DashboardWorkflowsDetailsDocument,
    variables: { workflowID: props.params.workflowID! },
  });

  return data;
};
