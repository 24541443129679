import { type FC } from "react";

import { type ApolloError } from "@apollo/client";

import { Button } from "./button";
import { Notification } from "./notification";
import { Color } from "./types";

export const Retry: FC<{
  color?: Color;
  error: ApolloError;
  retry?(): void;
}> = ({ error, retry, color = "rose" }) => (
  <Notification color={color}>
    <span>{error.message}</span>

    {retry && (
      <Button type="button" color={color} onClick={retry}>
        Retry
      </Button>
    )}
  </Notification>
);
