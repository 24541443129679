import { type ButtonHTMLAttributes, forwardRef } from "react";

import { faSpinner } from "@fortawesome/pro-duotone-svg-icons/faSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { type Color } from "./types";

type T = HTMLButtonElement;
type P = ButtonHTMLAttributes<T> & {
  color?: Color;
  loading?: boolean;
};

export const NodeButton = forwardRef<T, P>(
  ({ disabled, loading, children, ...props }, ref) => (
    <button
      type="button"
      ref={ref}
      className="rounded-md px-3 py-2 text-slate-500 hover:bg-indigo-500 hover:text-white disabled:bg-transparent disabled:text-slate-500/50"
      disabled={disabled || loading}
      {...props}
    >
      {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : children}
    </button>
  ),
);
