import { type FC } from "react";
import { NavLink } from "react-router-dom";

import { clsx } from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { type Breadcrumb } from "@application/types/breadcrumb";

export const BreadcrumbNavLink: FC<Breadcrumb> = ({ icon, name, to }) => (
  <NavLink
    end
    to={to}
    className={({ isActive, isPending }) =>
      clsx(
        "flex items-center gap-2 px-3 py-2 text-slate-600",
        !isActive && !isPending && "hover:text-slate-800",
        isActive || isPending ? "font-bold" : "font-medium",
      )
    }
  >
    {icon && <FontAwesomeIcon icon={icon} />}
    {name && <div>{name}</div>}
  </NavLink>
);
