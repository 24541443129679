import { type FC, type ReactNode } from "react";
import { FieldArrayWithId } from "react-hook-form";

import {
  type Action__Prompt__AttachmentInput,
  Action__Prompt__AttachmentKind,
  type StepInput,
} from "@app_schema";

import { AttachmentsContext } from "./attachments_context";
import { useAttachmentsFieldArray } from "./use_attachments_field_array";

const DEFAULT_ATTACHMENT: Action__Prompt__AttachmentInput = {
  path: "",
  kind: Action__Prompt__AttachmentKind.Path,
};

type Fields = FieldArrayWithId<
  StepInput,
  `action.prompt.messages.${number}.attachments`,
  "key"
>[];

export const AttachmentsProvider: FC<{
  name: `action.prompt.messages.${number}.attachments`;
  children(fields: Fields): ReactNode;
}> = ({ name, children }) => {
  const { append, fields, remove, update } = useAttachmentsFieldArray(name);

  const onBuild = (attachment?: Action__Prompt__AttachmentInput) => {
    append(attachment ?? DEFAULT_ATTACHMENT);
  };

  const onDelete = (index: number) => {
    const { id, _destroy } = fields[index];
    if (id) update(index, { id, _destroy: !_destroy });
    else remove(index);
  };

  return (
    <AttachmentsContext.Provider
      value={{
        name,
        onBuild,
        onDelete,
      }}
      children={children(fields)}
    />
  );
};
