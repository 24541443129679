import { type FC, type ReactNode } from "react";

import { MenuLinks } from "./menu_links";
import { MenuProfile } from "./menu_profile";

const MenuContainer: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="flex h-full flex-col space-y-4">{children}</div>
);

export const Menu: FC = () => (
  <MenuContainer>
    <MenuProfile />
    <MenuLinks />
  </MenuContainer>
);
