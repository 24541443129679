import { useEffect } from "react";

export const useClassList = (element: HTMLElement, ...tokens: string[]) => {
  useEffect(() => {
    element.classList.add(...tokens);
    return () => {
      element.classList.remove(...tokens);
    };
  }, [element, tokens]);
};
