import { type FC, useRef, useState } from "react";

import { faPaperclip } from "@fortawesome/pro-duotone-svg-icons/faPaperclip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button } from "./button";

export const ButtonFile: FC<{
  disabled?: boolean;
  onSelect(files: File[]): void;
  className?: string;
}> = ({ disabled, className, onSelect }) => {
  const input = useRef<HTMLInputElement>(null);
  const [key, setKey] = useState<number>(0);

  return (
    <>
      <input
        type="file"
        multiple
        key={key}
        ref={input}
        onChange={(event) => {
          const files = event.target.files;
          if (files) onSelect([...files]);
          setKey((key) => key + 1);
        }}
        style={{ display: "none" }}
      />

      <Button
        type="button"
        disabled={disabled}
        onClick={() => input?.current?.click()}
        className={className}
      >
        <FontAwesomeIcon icon={faPaperclip} />
      </Button>
    </>
  );
};
