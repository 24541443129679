import {
  DashboardConversationsListDocument,
  type DashboardConversationsListQuery,
  type DashboardConversationsListQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_CONVERSATIONS_LIST_LOADER = async () => {
  const { data } = await client.query<
    DashboardConversationsListQuery,
    DashboardConversationsListQueryVariables
  >({
    query: DashboardConversationsListDocument,
    fetchPolicy: "network-only",
  });

  return data;
};
