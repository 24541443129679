import { type FC } from "react";
import { useParams } from "react-router-dom";

import { useBlogArticleDetailsQuery } from "@app_schema";

import { useTitle } from "@application/hooks/use_title";
import { useTrackPageView } from "@application/hooks/use_track_page_view";

import { Breadcrumbs } from "@styled/breadcrumbs";

import { BlogArticleAuthors } from "./blog_article_authors";
import { BlogArticleContent } from "./blog_article_content";
import { BlogArticleDate } from "./blog_article_date";
import { BlogArticleTags } from "./blog_article_tags";
import { BlogHeading } from "./blog_heading";
import { BlogPage } from "./blog_page";
import { BlogSubtitle } from "./blog_subtitle";
import { BlogTitle } from "./blog_title";

export const BlogArticleDetails: FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const { data } = useBlogArticleDetailsQuery({ variables: { slug: slug! } });
  const article = data?.blog?.article;

  useTitle("Workflow", "Blog", article?.title);
  useTrackPageView({ page: `blog/articles/${slug}` });

  if (!article) return null;

  const breadcrumbs = [
    { name: "Blog", to: "/blog" },
    { name: article.title, to: `/blog/articles/${article.slug}` },
  ];

  return (
    <BlogPage>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <BlogHeading>
        <BlogArticleDate article={article} />
        <BlogTitle>{article.title}</BlogTitle>
        <BlogSubtitle>{article.summary}</BlogSubtitle>
      </BlogHeading>

      <BlogArticleAuthors article={article} />

      <BlogArticleTags article={article} />
      <BlogArticleContent article={article} />
    </BlogPage>
  );
};
