import { useFieldArray, useFormContext } from "react-hook-form";

import { type StepInput } from "@app_schema";

export const useMessagesFieldArray = (name: `action.prompt.messages`) => {
  const form = useFormContext<StepInput>();
  return useFieldArray({
    keyName: "key",
    control: form.control,
    name,
  });
};
