import { type FC } from "react";
import { Link } from "react-router-dom";

import { type Run, type User, type Workflow } from "@app_schema";

export const RunName: FC<{
  run: Pick<Run, "id"> & {
    user?: Pick<User, "id" | "me" | "name">;
    workflow: Pick<Workflow, "id" | "name">;
  };
}> = ({ run }) => (
  <>
    <span>#{run.id}</span>
    <span> - </span>
    <Link to={`/dashboard/workflows/${run.workflow.id}`}>
      {run.workflow.name} {run.user && !run.user.me && <> by {run.user.name}</>}
    </Link>
  </>
);
