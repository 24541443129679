import { type InputHTMLAttributes, forwardRef } from "react";

import { clsx } from "clsx";

import { FIELD_FILE_STYLE, FIELD_STATE_STYLE, FIELD_STYLE } from "./config";
import { FormState } from "./types";

type T = HTMLInputElement;
type P = InputHTMLAttributes<T> & {
  state?: FormState;
};

export const Input = forwardRef<T, P>(
  ({ state = FormState.Default, className, ...props }, ref) => (
    <input
      ref={ref}
      className={clsx(
        FIELD_STYLE,
        FIELD_FILE_STYLE,
        FIELD_STATE_STYLE[state],
        className,
      )}
      {...props}
    />
  ),
);

Input.displayName = "Input";
