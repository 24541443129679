import { type FC, type FormEvent, useState } from "react";

import { faKey } from "@fortawesome/pro-duotone-svg-icons/faKey";
import { faLock } from "@fortawesome/pro-duotone-svg-icons/faLock";
import { faLockKeyhole } from "@fortawesome/pro-duotone-svg-icons/faLockKeyhole";
import { faPencil } from "@fortawesome/pro-duotone-svg-icons/faPencil";
import { faPlusSquare } from "@fortawesome/pro-duotone-svg-icons/faPlusSquare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  SecretFragment,
  useDashboardSettingsQuery,
  useSecretBuildMutation,
  useSecretChangeMutation,
  useSecretDestroyMutation,
} from "@app_schema";

import { Button } from "@styled/button";
import { DestroyButton } from "@styled/destroy_button";
import { Fields } from "@styled/fields";
import { Form } from "@styled/form";
import { InputField } from "@styled/input_field";

const SettingsSecretDestroyButton: FC<{
  secret: SecretFragment;
  disabled?: boolean;
}> = ({ secret, disabled }) => {
  const [destroy, { loading }] = useSecretDestroyMutation({
    variables: { id: secret.id },
  });

  return (
    <DestroyButton
      disabled={disabled}
      loading={loading}
      confirmation={
        <>
          Are you sure you want to delete <strong>{secret.key}</strong>?
        </>
      }
      destroy={destroy}
    />
  );
};

const SettingsSecretBuilder: FC<{
  onSave(): void;
}> = ({ onSave }) => {
  const [key, setKey] = useState<string>("");
  const [value, setValue] = useState<string>("");

  const [execute, { loading, data }] = useSecretBuildMutation();
  const errors = data?.secrets.build.errors;

  const onSubmit = async (event: FormEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if (loading) return;

    const response = await execute({
      variables: { input: { key, value } },
    });
    if (!response.data?.secrets.build.errors) onSave();
  };

  return (
    <Form onSubmit={onSubmit}>
      <Fields>
        <InputField
          icon={faKey}
          errors={errors}
          name="key"
          placeholder="Key"
          value={key}
          onChange={(event) => setKey(event.target.value)}
        />
        <InputField
          icon={faLock}
          errors={errors}
          name="value"
          placeholder="Value"
          value={value}
          onChange={(event) => setValue(event.target.value)}
        />
        <Button type="submit" loading={loading} disabled={!key || !value}>
          <FontAwesomeIcon icon={faLockKeyhole} /> Save
        </Button>
      </Fields>
    </Form>
  );
};

const SettingsSecretEntry: FC<{
  secret: SecretFragment;
  onSave(): void;
}> = ({ secret }) => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const [key, setKey] = useState<string>(secret.key);
  const [value, setValue] = useState<string>("");

  const [execute, { loading, data }] = useSecretChangeMutation();
  const errors = data?.secret.change.errors;

  const onSubmit = async (event: FormEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if (loading) return;

    const response = await execute({
      variables: { id: secret.id, input: { key, value } },
    });
    setDisabled(!response.data?.secret.change.errors);
  };

  return (
    <Form onSubmit={onSubmit}>
      <Fields>
        <InputField
          icon={faKey}
          disabled={disabled}
          errors={errors}
          name="key"
          placeholder="Key"
          value={disabled ? secret.key : key}
          onChange={(event) => setKey(event.target.value)}
        />
        <InputField
          icon={faLock}
          disabled={disabled}
          errors={errors}
          name="value"
          placeholder="Value"
          value={disabled ? secret.value : value}
          onChange={(event) => setValue(event.target.value)}
        />
        {disabled && (
          <Button type="button" onClick={() => setDisabled(!disabled)}>
            <FontAwesomeIcon icon={faPencil} /> Modify
          </Button>
        )}
        {!disabled && (
          <Button type="submit" loading={loading} disabled={!key || !value}>
            <FontAwesomeIcon icon={faLockKeyhole} /> Save
          </Button>
        )}
        <SettingsSecretDestroyButton secret={secret} />
      </Fields>
    </Form>
  );
};

export const SettingsSecretsForm: FC = () => {
  const { data, refetch } = useDashboardSettingsQuery();

  const secrets = data?.secrets.filter(({ deleted }) => !deleted);
  const [building, setBuilding] = useState<boolean>();

  return (
    <>
      {secrets?.map((secret) => (
        <SettingsSecretEntry key={secret.id} secret={secret} onSave={refetch} />
      ))}
      {!building && (
        <Button type="button" onClick={() => setBuilding(!building)}>
          <FontAwesomeIcon icon={faPlusSquare} /> New
        </Button>
      )}
      {building && (
        <SettingsSecretBuilder
          onSave={() => {
            setBuilding(!building);
            refetch();
          }}
        />
      )}
    </>
  );
};
