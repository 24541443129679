import { type FC } from "react";

import { faDown } from "@fortawesome/pro-duotone-svg-icons/faDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  StepFragment,
  WorkflowFragment,
  useStepIncrementMutation,
} from "@app_schema";

import { NodeButton } from "@styled/node_button";

export const WorkflowStepIncrementNodeButton: FC<{
  workflow: WorkflowFragment;
  step: StepFragment;
  disabled?: boolean;
}> = ({ workflow, step, disabled }) => {
  const [execute, { loading }] = useStepIncrementMutation({
    variables: {
      workflowID: workflow.id,
      stepID: step.id,
    },
  });

  const onClick = async () => {
    await execute();
  };

  return (
    <NodeButton disabled={disabled} loading={loading} onClick={onClick}>
      <FontAwesomeIcon icon={faDown} />
    </NodeButton>
  );
};
