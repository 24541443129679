import { type FC, useEffect } from "react";

import { faSpinner } from "@fortawesome/pro-duotone-svg-icons/faSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Skill, useSkillVerifyMutation } from "@app_schema";

import { Attribute } from "@styled/attribute";
import { Badge } from "@styled/badge";
import { Button } from "@styled/button";
import { Modal } from "@styled/modal";
import { ModalClose } from "@styled/modal_close";
import { ModalContent } from "@styled/modal_content";
import { ModalFooter } from "@styled/modal_footer";
import { ModalHeader } from "@styled/modal_header";
import { ModalTitle } from "@styled/modal_title";
import { Notification } from "@styled/notification";
import { Well } from "@styled/well";

export const SkillVerifyDialog: FC<{
  skill: Pick<Skill, "id" | "name">;
  onClose(): void;
}> = ({ skill, onClose }) => {
  const [execute, { loading, data }] = useSkillVerifyMutation({
    variables: { id: skill.id },
  });
  const capture = data?.skill.verify.capture;
  const success = capture?.success;
  const stdout = capture?.stdout;
  const stderr = capture?.stderr;

  useEffect(() => {
    execute();
  }, [execute]);

  const verify = () => {
    if (loading) return;
    execute();
  };

  return (
    <Modal onClose={onClose}>
      <ModalHeader>
        <ModalTitle>{skill.name}</ModalTitle>
        <ModalClose onClose={onClose} />
      </ModalHeader>

      <ModalContent>
        {!data ? (
          <Notification color="slate">
            Verifying...
            <FontAwesomeIcon icon={faSpinner} spin />
          </Notification>
        ) : (
          <div className="flex flex-col gap-4">
            <Attribute name="STATUS">
              <Badge color={success ? "emerald" : "rose"}>
                {success ? "Pass" : "Fail"}
              </Badge>
            </Attribute>

            {stdout && (
              <Well name="STDOUT">
                <pre>{stdout}</pre>
              </Well>
            )}

            {stderr && (
              <Well name="STDERR">
                <pre>{stderr}</pre>
              </Well>
            )}
          </div>
        )}
      </ModalContent>
      <ModalFooter>
        <Button type="button" loading={loading} onClick={verify}>
          Verify
        </Button>
      </ModalFooter>
    </Modal>
  );
};
