import { type FC, type FormEvent, useEffect } from "react";
import { useNavigate, useParams } from "react-router";

import { Status, useVerifyMutation } from "@app_schema";

import { useTitle } from "@application/hooks/use_title";
import { useTrackPageView } from "@application/hooks/use_track_page_view";

import { AuthLayout } from "@layouts/auth_layout";

import { Button } from "@styled/button";
import { Card } from "@styled/card";
import { Form } from "@styled/form";
import { Notification } from "@styled/notification";
import { Sentence } from "@styled/sentence";

export const Verify: FC = () => {
  useTrackPageView({ page: "verify" });
  useTitle("Workflow", "Verify");

  const navigate = useNavigate();
  const { sid } = useParams<{ sid: string }>();
  const [execute, { loading, data }] = useVerifyMutation();
  const errors = data?.verify?.errors;

  const verify = async (sid: string) => {
    const result = await execute({ variables: { sid } });
    if (result.data?.verify.status === Status.Ok) navigate("/dashboard");
  };

  useEffect(() => {
    if (!sid) return;
    verify(sid);
  }, [sid]);

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (!sid) return;
    verify(sid);
  };

  return (
    <AuthLayout>
      <Card>
        <Form onSubmit={onSubmit}>
          {loading && (
            <Notification color="indigo">
              Standbye while your account is activated.
            </Notification>
          )}

          {errors?.base && (
            <Notification color="rose">
              <Sentence>{errors.base}</Sentence>
            </Notification>
          )}

          <Button full loading={loading} type="submit">
            Verify
          </Button>
        </Form>
      </Card>
    </AuthLayout>
  );
};
