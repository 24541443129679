import { type FC } from "react";

import HERO_1_AVIF from "@application/images/home/hero/1.avif";
import HERO_1_JPEG from "@application/images/home/hero/1.jpeg";
import HERO_1_WEBP from "@application/images/home/hero/1.webp";
import HERO_2_AVIF from "@application/images/home/hero/2.avif";
import HERO_2_JPEG from "@application/images/home/hero/2.jpeg";
import HERO_2_WEBP from "@application/images/home/hero/2.webp";
import HERO_3_AVIF from "@application/images/home/hero/3.avif";
import HERO_3_JPEG from "@application/images/home/hero/3.jpeg";
import HERO_3_WEBP from "@application/images/home/hero/3.webp";

import { ButtonLinkLogin } from "@styled/button_link_login";
import { ButtonLinkSignup } from "@styled/button_link_signup";
import { Carousel } from "@styled/carousel";

const HERO_SRCS = [
  {
    alt: "A screenshot of a list of workflows",
    avif: HERO_1_AVIF,
    jpeg: HERO_1_JPEG,
    webp: HERO_1_WEBP,
  },
  {
    alt: "A screenshot of a list of runs",
    avif: HERO_2_AVIF,
    jpeg: HERO_2_JPEG,
    webp: HERO_2_WEBP,
  },
  {
    alt: "A screenshot of a list of schedules",
    avif: HERO_3_AVIF,
    jpeg: HERO_3_JPEG,
    webp: HERO_3_WEBP,
  },
];

export const Hero: FC = () => (
  <div className="flex flex-col gap-8">
    <h1 className="text-center text-4xl font-black text-slate-800 md:text-6xl">
      Let AI Build Your Next Workflow
    </h1>

    <p className="mx-auto max-w-6xl text-center leading-8 text-slate-600 md:text-2xl">
      Our agents allow customers to leverage LLMs to automate complex workflows.
      The process of learning skills to handle a variety of tasks is simplified
      within our cloud execution platform. Learn a process once. Run it
      repeatedly. It can code. It can execute. It can automate.
    </p>

    <div className="flex justify-center gap-4">
      <ButtonLinkLogin />
      <ButtonLinkSignup />
    </div>

    <Carousel srcs={HERO_SRCS} aspect="8/5" />
  </div>
);
