import {
  BlogListDocument,
  type BlogListQuery,
  type BlogListQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const BLOG_LIST_LOADER = async () => {
  const { data } = await client.query<BlogListQuery, BlogListQueryVariables>({
    query: BlogListDocument,
  });

  return data;
};
