import { type FC, type ReactNode } from "react";

export const NodeGroup: FC<{
  root: ReactNode;
  children: ReactNode;
}> = ({ children, root }) => (
  <div className="mt-4 flex flex-col gap-4 rounded-md border border-dashed border-slate-200 p-4">
    <div className="-mt-8">{root}</div>
    {children}
  </div>
);
