import { type FC } from "react";

import { faLoader } from "@fortawesome/pro-duotone-svg-icons/faLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Loader: FC = () => (
  <div className="flex h-full w-full items-center justify-center text-base text-slate-400">
    <FontAwesomeIcon icon={faLoader} spin size="3x" />
  </div>
);
