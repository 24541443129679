import { type FC } from "react";

import SCREENSHOT_1_AVIF from "@application/images/home/screenshots/1.avif";
import SCREENSHOT_1_JPEG from "@application/images/home/screenshots/1.jpeg";
import SCREENSHOT_1_WEBP from "@application/images/home/screenshots/1.webp";
import SCREENSHOT_2_AVIF from "@application/images/home/screenshots/2.avif";
import SCREENSHOT_2_JPEG from "@application/images/home/screenshots/2.jpeg";
import SCREENSHOT_2_WEBP from "@application/images/home/screenshots/2.webp";
import SCREENSHOT_3_AVIF from "@application/images/home/screenshots/3.avif";
import SCREENSHOT_3_JPEG from "@application/images/home/screenshots/3.jpeg";
import SCREENSHOT_3_WEBP from "@application/images/home/screenshots/3.webp";

import { Screenshot } from "./screenshot";
import { Section } from "./section";

const SCREENSHOT_1_SRC = {
  alt: "A screenshot of a page for building the steps of a workflow.",
  avif: SCREENSHOT_1_AVIF,
  jpeg: SCREENSHOT_1_JPEG,
  webp: SCREENSHOT_1_WEBP,
};

const SCREENSHOT_2_SRC = {
  alt: "A screenshot of a page listing the results of a run for of a workflow.",
  avif: SCREENSHOT_2_AVIF,
  jpeg: SCREENSHOT_2_JPEG,
  webp: SCREENSHOT_2_WEBP,
};

const SCREENSHOT_3_SRC = {
  alt: "A screenshot of a page detailing a skill with the code, test, and documentation.",
  avif: SCREENSHOT_3_AVIF,
  jpeg: SCREENSHOT_3_JPEG,
  webp: SCREENSHOT_3_WEBP,
};

const SCREENSHOTS = [
  {
    src: SCREENSHOT_1_SRC,
    text: "Automate building and maintaining workflows",
  },
  {
    src: SCREENSHOT_2_SRC,
    text: "Interact with workflow runs in real-time",
  },
  {
    src: SCREENSHOT_3_SRC,
    text: "Watch agents explore and learn new skills",
  },
];

export const Screenshots: FC = () => (
  <Section
    title="Automate Your Next Workflow"
    subtitle="Use our AI agents to simplify complex workflows with ease."
    children={
      <div className="grid grid-cols-1 gap-8 sm:grid-cols-1 lg:grid-cols-3">
        {SCREENSHOTS.map((feature, key) => (
          <Screenshot key={key} {...feature} />
        ))}
      </div>
    }
  />
);
