import { type HTMLAttributes, forwardRef } from "react";

import { clsx } from "clsx";

type T = HTMLHeadingElement;
type P = HTMLAttributes<T>;

export const Title = forwardRef<T, P>(({ className, ...props }, ref) => (
  <h1
    ref={ref}
    className={clsx(
      "border-b border-slate-200 py-4 text-3xl text-slate-800",
      className,
    )}
    {...props}
  />
));
