import { type FC, type ReactNode } from "react";

import { Footer } from "@styled/footer";
import { Header } from "@styled/header";

export const PageLayout: FC<{
  children: ReactNode;
}> = ({ children }) => (
  <div className="container mx-auto flex flex-col gap-8 px-8 py-8">
    <Header />

    {children}

    <Footer />
  </div>
);
