import { type FC, type ReactNode } from "react";

import { Transition } from "@headlessui/react";

type Direction = "next" | "prev";

const ENTER_FROM_FOR_DIRECTION: Record<Direction, string> = {
  next: "translate-x-full",
  prev: "-translate-x-full",
};

const LEAVE_TO_FOR_DIRECTION: Record<Direction, string> = {
  next: "-translate-x-full",
  prev: "translate-x-full",
};

export const TransitionSlide: FC<{
  show?: boolean;
  direction?: "next" | "prev";
  children: ReactNode;
}> = ({ show, direction, children }) => (
  <Transition
    show={show}
    enter="transition-all duration-500"
    enterFrom={direction ? ENTER_FROM_FOR_DIRECTION[direction] : undefined}
    enterTo="translate-x-0"
    leave="transition-all duration-500"
    leaveFrom="translate-x-0"
    leaveTo={direction ? LEAVE_TO_FOR_DIRECTION[direction] : undefined}
  >
    {children}
  </Transition>
);
