import { type FC } from "react";

import { faUserPlus } from "@fortawesome/pro-duotone-svg-icons/faUserPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ButtonLink } from "@styled/button_link";

export const ButtonLinkSignup: FC = () => (
  <ButtonLink to="/signup">
    <FontAwesomeIcon icon={faUserPlus} />
    Signup
  </ButtonLink>
);
