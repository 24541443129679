import { type FC } from "react";
import { NavLink } from "react-router-dom";

import { clsx } from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { type Breadcrumb } from "@application/types/breadcrumb";

export const MenuNavLink: FC<Breadcrumb> = ({ icon, name, ...props }) => (
  <NavLink
    {...props}
    className={({ isActive, isPending }) =>
      clsx(
        "flex items-center gap-2 rounded-md px-3 py-2 font-semibold text-slate-800 transition hover:bg-slate-200",
        (isActive || isPending) && "bg-slate-200",
      )
    }
  >
    {icon && <FontAwesomeIcon icon={icon} />}
    {name && <div>{name}</div>}
  </NavLink>
);
