import { type FC, useState } from "react";

import { faComments } from "@fortawesome/pro-duotone-svg-icons/faComments";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { type Agent } from "@app_schema";

import { Button } from "@styled/button";

import { AgentConverseDialog } from "./agent_converse_dialog";

export const AgentConverseButton: FC<{
  agent: Pick<Agent, "id" | "name">;
}> = ({ agent }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const onToggle = () => setVisible((visible) => !visible);

  return (
    <>
      {visible && <AgentConverseDialog agent={agent} onClose={onToggle} />}
      <Button type="button" onClick={onToggle}>
        <FontAwesomeIcon icon={faComments} /> Chat
      </Button>
    </>
  );
};
