import { createContext } from "react";

import { type Action__Prompt__MessageInput } from "@app_schema";

export const MessagesContext = createContext<{
  name: `action.prompt.messages`;
  onBuild(message?: Action__Prompt__MessageInput): void;
  onDelete(index: number): void;
}>({
  name: "action.prompt.messages",
  onBuild: () => {},
  onDelete: () => {},
});
