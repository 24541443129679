import type { FC, ReactNode } from "react";

import clsx from "clsx";

export const RichTextAreaBar: FC<{
  children: ReactNode;
  className?: string;
}> = ({ className, ...props }) => (
  <div className={clsx("border-slate-200 p-2", className)} {...props} />
);
