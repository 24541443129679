import { type FC, type FormEvent, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { faEnvelope as faEmail } from "@fortawesome/pro-duotone-svg-icons/faEnvelope";
import { faKey as faPassword } from "@fortawesome/pro-duotone-svg-icons/faKey";

import { useAuthenticated } from "@application/hooks/use_authenticated";
import { useLogin } from "@application/hooks/use_login";
import { useTitle } from "@application/hooks/use_title";
import { useTrackPageView } from "@application/hooks/use_track_page_view";

import { track } from "@application/utilities/track";

import { AuthLayout } from "@layouts/auth_layout";

import { BasicLink } from "@styled/basic_link";
import { Button } from "@styled/button";
import { Card } from "@styled/card";
import { Form } from "@styled/form";
import { InputField } from "@styled/input_field";
import { Notification } from "@styled/notification";
import { Sentence } from "@styled/sentence";

const SignupLink: FC = () => <BasicLink to="/signup">Signup</BasicLink>;

export const Login: FC = () => {
  useTrackPageView({ page: "login" });
  useTitle("Workflow", "Login");

  const authenticated = useAuthenticated();
  const location = useLocation();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const { login, loading, errors } = useLogin();

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    track("login", { email });
    login({ email, password });
  };

  if (authenticated) {
    const params = new URLSearchParams(location.search);
    return <Navigate to={params.get("back") ?? "/dashboard"} />;
  }

  return (
    <AuthLayout>
      <Card>
        <Form onSubmit={onSubmit}>
          {errors?.base && (
            <Notification color="rose">
              <Sentence>{errors.base}</Sentence>
            </Notification>
          )}

          <InputField
            id="email"
            name="email"
            type="email"
            label="Email:"
            placeholder="me@workflow.ing"
            icon={faEmail}
            errors={errors}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />

          <InputField
            id="password"
            name="password"
            type="password"
            label="Password:"
            placeholder="abc$123!"
            icon={faPassword}
            errors={errors}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />

          <Button full loading={loading} type="submit">
            Login
          </Button>
        </Form>

        <p className="text-center text-slate-600">
          Need an account? <SignupLink />
        </p>
      </Card>
    </AuthLayout>
  );
};
