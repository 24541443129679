import { type InputHTMLAttributes, forwardRef } from "react";

import { clsx } from "clsx";

import { SELECT_PLAIN_STYLE, SELECT_STATE_STYLE, SELECT_STYLE } from "./config";
import { FormState } from "./types";

type T = HTMLSelectElement;
type P = InputHTMLAttributes<T> & {
  plain?: boolean;
  mode?: "plain";
  state?: FormState;
  full?: boolean;
};

export const Select = forwardRef<T, P>(
  ({ full, plain, state = FormState.Default, className, ...props }, ref) => (
    <select
      ref={ref}
      className={clsx(
        SELECT_STYLE,
        SELECT_STATE_STYLE[state],
        plain && SELECT_PLAIN_STYLE,
        full && "w-full",
        className,
      )}
      {...props}
    />
  ),
);

Select.displayName = "Select";
