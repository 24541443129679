import { type FC } from "react";

import { faCheck } from "@fortawesome/pro-duotone-svg-icons/faCheck";
import { faCopy } from "@fortawesome/pro-duotone-svg-icons/faCopy";

import {
  type ExecutionDetailsFragment,
  type ExecutionMinimalFragment,
  type RunFragment,
} from "@app_schema";

import { useClipboard } from "@application/hooks/use_clipboard";

import { IconButton } from "@styled/icon_button";

function isExecutionDetailsFragment(
  execution: ExecutionMinimalFragment | ExecutionDetailsFragment,
): execution is ExecutionDetailsFragment {
  return "result" in execution;
}

export const ExecutionCopyIconButton: FC<{
  run: RunFragment;
  execution: ExecutionMinimalFragment | ExecutionDetailsFragment;
}> = ({ execution }) => {
  if (!isExecutionDetailsFragment(execution) || !execution.result) {
    return <IconButton disabled icon={faCopy} />;
  }

  const { copy, copied } = useClipboard(execution.result);

  return <IconButton onClick={copy} icon={copied ? faCheck : faCopy} />;
};
