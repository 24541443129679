import { useFieldArray, useFormContext } from "react-hook-form";

import { type StepInput } from "@app_schema";

export const useAttachmentsFieldArray = (
  name: `action.prompt.messages.${number}.attachments`,
) => {
  const form = useFormContext<StepInput>();
  return useFieldArray({
    keyName: "key",
    control: form.control,
    name,
  });
};
