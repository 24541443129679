import { type FC, type FormEvent, useState } from "react";
import { useNavigate } from "react-router";

import { faEnvelope as faEmail } from "@fortawesome/pro-duotone-svg-icons/faEnvelope";
import { faIdCardClip as faName } from "@fortawesome/pro-duotone-svg-icons/faIdCardClip";

import { useUserBuildMutation } from "@app_schema";

import { Button } from "@styled/button";
import { Form } from "@styled/form";
import { InputField } from "@styled/input_field";
import { Notification } from "@styled/notification";
import { Page } from "@styled/page";
import { Sentence } from "@styled/sentence";

export const DashboardUsersForm: FC = () => {
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [execute, { loading, data }] = useUserBuildMutation();
  const errors = data?.users?.build?.errors;

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (loading) return;
    const result = await execute({
      variables: { input: { name, email } },
      update: (cache) => {
        cache.reset();
      },
    });
    const build = result.data?.users?.build;
    if (build?.user) navigate("/dashboard/users");
  };

  return (
    <Page>
      <Form onSubmit={onSubmit}>
        {errors?.base && (
          <Notification color="rose">
            <Sentence>{errors.base}</Sentence>
          </Notification>
        )}

        <InputField
          id="name"
          name="name"
          type="text"
          label="Name:"
          placeholder="First Last"
          icon={faName}
          value={name}
          errors={errors}
          onChange={(event) => setName(event.target.value)}
        />

        <InputField
          id="email"
          name="email"
          type="email"
          label="Email:"
          placeholder="me@workflow.ing"
          icon={faEmail}
          value={email}
          errors={errors}
          onChange={(event) => setEmail(event.target.value)}
        />

        <Button type="submit" loading={loading}>
          Save
        </Button>
      </Form>
    </Page>
  );
};
