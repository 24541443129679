import { type FC, ReactNode, useState } from "react";

import { clsx } from "clsx";

import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft } from "@fortawesome/pro-duotone-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/pro-duotone-svg-icons/faChevronRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Aspect, STYLE_FOR_ASPECT } from "./aspect";
import { Picture } from "./picture";
import { TransitionSlide } from "./transition_slide";

const CarouselContainer: FC<{
  id: string;
  children: ReactNode;
}> = (props) => (
  <div
    aria-roledescription="carousel"
    className="flex flex-col gap-2"
    {...props}
  />
);

const CarouselNav: FC<{
  children: ReactNode;
}> = (props) => (
  <nav className="flex flex-row items-center justify-center gap-2" {...props} />
);

const CarouselButton: FC<{
  label: string;
  controls: string;
  children: ReactNode;
  onClick(): void;
}> = ({ label, controls, ...props }) => (
  <button
    aria-controls={controls}
    aria-label={label}
    type="button"
    className="flex h-6 w-6 transform items-center justify-center rounded-full border-2 border-slate-200 hover:border-slate-400"
    {...props}
  />
);

const CarouselDotButton: FC<{
  label: string;
  controls: string;
  current: boolean;
  onClick(): void;
}> = ({ label, controls, current, ...props }) => (
  <button
    aria-controls={controls}
    aria-label={label}
    disabled={current}
    type="button"
    className={clsx(
      "flex h-4 w-4 transform items-center justify-center rounded-full",
      current
        ? "bg-slate-400 hover:bg-indigo-400"
        : "bg-slate-200 hover:bg-indigo-400",
    )}
    {...props}
  />
);

const CarouselIconButton: FC<{
  controls: string;
  icon: IconProp;
  label: string;
  onClick(): void;
}> = ({ icon, ...props }) => (
  <CarouselButton {...props}>
    <FontAwesomeIcon icon={icon} />
  </CarouselButton>
);

const CarouselNextButton: FC<{
  controls: string;
  onClick(): void;
}> = (props) => (
  <CarouselIconButton {...props} label="Next" icon={faChevronRight} />
);

const CarouselPrevButton: FC<{
  controls: string;
  onClick(): void;
}> = (props) => (
  <CarouselIconButton {...props} label="Prev" icon={faChevronLeft} />
);

const CarouselOuter: FC<{
  children: ReactNode;
  aspect: Aspect;
}> = ({ aspect, ...props }) => (
  <div
    {...props}
    className={clsx(
      "rounded-xl border border-slate-200 bg-white p-2",
      STYLE_FOR_ASPECT[aspect],
    )}
  />
);

const CarouselInner: FC<{
  children: ReactNode;
  aspect: Aspect;
}> = ({ aspect, ...props }) => (
  <div
    {...props}
    className={clsx(
      STYLE_FOR_ASPECT[aspect],
      "relative overflow-hidden rounded-md border border-slate-200",
    )}
  />
);

export const Carousel: FC<{
  id?: string;
  aspect: Aspect;
  srcs: Array<{
    avif: string;
    jpeg: string;
    webp: string;
    alt: string;
  }>;
}> = ({ id = "carousel", aspect, srcs }) => {
  const [direction, setDirection] = useState<"next" | "prev" | undefined>();
  const [index, setIndex] = useState(0);

  const next = () => {
    setDirection("next");
    setIndex((index) => (index + 1 + srcs.length) % srcs.length);
  };

  const prev = () => {
    setDirection("prev");
    setIndex((index) => (index - 1 + srcs.length) % srcs.length);
  };

  const nav = (i: number) => {
    if (i < index) setDirection("prev");
    if (i > index) setDirection("next");
    setIndex(i);
  };

  return (
    <CarouselContainer id={id}>
      <CarouselOuter aspect={aspect}>
        <CarouselInner aspect={aspect}>
          {srcs.map((src, i) => (
            <TransitionSlide key={i} show={i === index} direction={direction}>
              <Picture
                aria-roledescription="slide"
                aria-label={src.alt}
                className="absolute inset-0 h-full w-full"
                aspect={aspect}
                {...src}
              />
            </TransitionSlide>
          ))}
        </CarouselInner>
      </CarouselOuter>
      <CarouselNav>
        <CarouselPrevButton controls={id} onClick={prev} />

        {srcs.map((src, i) => (
          <CarouselDotButton
            key={i}
            current={i === index}
            label={src.alt}
            controls={id}
            onClick={() => nav(i)}
          />
        ))}

        <CarouselNextButton controls={id} onClick={next} />
      </CarouselNav>
    </CarouselContainer>
  );
};
