import { type FC, type ReactNode } from "react";

import { type IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faEllipsis } from "@fortawesome/pro-duotone-svg-icons/faEllipsis";
import { faRobot } from "@fortawesome/pro-duotone-svg-icons/faRobot";
import { faUserVneck } from "@fortawesome/pro-duotone-svg-icons/faUserVneck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { type AttachmentFragment } from "@app_schema";

import { FileList } from "./file_list";
import { Prose } from "./prose";

type Role = "user" | "system" | "assistant";

const ICONS: Record<Role, IconDefinition> = {
  assistant: faRobot,
  system: faRobot,
  user: faUserVneck,
};

const NAMES: Record<Role, string> = {
  assistant: "Workflow",
  system: "Workflow",
  user: "You",
};

const ChatBubbleAvatar: FC<{ role: Role }> = ({ role }) => (
  <div className="flex size-10 items-center justify-center rounded-md bg-slate-200 text-slate-800">
    <FontAwesomeIcon icon={ICONS[role]} />
  </div>
);

export const ChatBubble: FC<{
  html?: string | null;
  role: Role;
  files?: AttachmentFragment[];
  actions?: ReactNode;
}> = ({ html, role, files, actions }) => (
  <div className="flex gap-4">
    <div className="flex-none">
      <ChatBubbleAvatar role={role} />
    </div>
    <div className="flex-auto">
      <div className="flex flex-col gap-2">
        <div className="font-semibold">{NAMES[role]}</div>
        {html ? (
          <Prose
            className="break-all"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        ) : (
          <div>
            <FontAwesomeIcon icon={faEllipsis} beat />
          </div>
        )}
        {files && files.length > 0 && <FileList attachments={files} />}
        {actions && <div className="flex gap-2">{actions}</div>}
      </div>
    </div>
  </div>
);
