import { type FC } from "react";
import { Link } from "react-router-dom";

import { faCheck } from "@fortawesome/pro-duotone-svg-icons/faCheck";
import { faExclamation } from "@fortawesome/pro-duotone-svg-icons/faExclamation";
import { faPause } from "@fortawesome/pro-duotone-svg-icons/faPause";
import { faSpinner } from "@fortawesome/pro-duotone-svg-icons/faSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { type Run, RunStatusEnum } from "@app_schema";

import { Dot } from "@styled/dot";
import { Tooltip } from "@styled/tooltip";

import { RunInfo } from "./run_info";

const ICONS = {
  [RunStatusEnum.Running]: faSpinner,
  [RunStatusEnum.Paused]: faPause,
  [RunStatusEnum.Succeeded]: faCheck,
  [RunStatusEnum.Failed]: faExclamation,
};

const COLORS: Record<RunStatusEnum, "emerald" | "indigo" | "slate" | "rose"> = {
  [RunStatusEnum.Running]: "slate",
  [RunStatusEnum.Paused]: "indigo",
  [RunStatusEnum.Succeeded]: "emerald",
  [RunStatusEnum.Failed]: "rose",
};

const RunLink: FC<{
  run: Pick<Run, "id" | "status" | "started" | "finished" | "error">;
}> = ({ run }) => (
  <Tooltip tip={<RunInfo run={run} />}>
    <Link to={`/dashboard/runs/${run.id}`}>
      <Dot color={COLORS[run.status]}>
        <FontAwesomeIcon
          icon={ICONS[run.status]}
          spin={run.status === RunStatusEnum.Running}
        />
      </Dot>
    </Link>
  </Tooltip>
);

export const RunRecent: FC<{
  runs: Array<Pick<Run, "id" | "status" | "started" | "finished" | "error">>;
}> = ({ runs }) => (
  <div className="flex gap-2">
    <span>Runs:</span>
    {runs.map((run) => (
      <RunLink key={run.id} run={run} />
    ))}
  </div>
);
