import { type FC, useState } from "react";

import { useDashboardMailboxesListQuery } from "@app_schema";

import { Fields } from "@styled/fields";
import { List } from "@styled/list";
import { ListItem } from "@styled/list_item";
import { ListItemContent } from "@styled/list_item_content";
import { ListItemControls } from "@styled/list_item_controls";
import { ListItemSummary } from "@styled/list_item_summary";
import { ListItemText } from "@styled/list_item_text";
import { ListItemTitle } from "@styled/list_item_title";
import { MineSwitch } from "@styled/mine_switch";
import { Notification } from "@styled/notification";
import { SearchInputField } from "@styled/search_input_field";

import { MailboxAttributes } from "./mailbox_attributes";
import { MailboxDestroyButton } from "./mailbox_destroy_button";
import { RunRecent } from "./run_recent";
import { DashboardTriggersContainer } from "./triggers_container";

export const DashboardMailboxesList: FC = () => {
  const [mine, setMine] = useState<boolean | undefined>();
  const [query, setQuery] = useState<string | undefined>();
  const { data, loading } = useDashboardMailboxesListQuery({
    variables: { query, mine },
  });
  const mailboxes = data?.mailboxes.filter(({ deleted }) => !deleted);

  return (
    <DashboardTriggersContainer loading={loading}>
      <Fields>
        <SearchInputField query={query} onSearch={setQuery} />
        <MineSwitch mine={mine} onChange={setMine} />
      </Fields>

      <Notification color="slate">
        Mailboxes allow you to trigger workflow runs via mail. Simply send a
        message to the designated address. Runs can be automated by setting up
        forwarding rules with your mail provider.
      </Notification>

      <List>
        {mailboxes?.map((mailbox) => (
          <ListItem key={mailbox.id}>
            <ListItemContent>
              <ListItemText>
                <ListItemTitle>
                  <span>{mailbox.description}</span>
                </ListItemTitle>
                <ListItemSummary>
                  <MailboxAttributes mailbox={mailbox} />
                </ListItemSummary>
                {mailbox.runs.length > 0 && (
                  <ListItemSummary>
                    <RunRecent runs={mailbox.runs} />
                  </ListItemSummary>
                )}
              </ListItemText>

              <ListItemControls>
                <MailboxDestroyButton mailbox={mailbox} />
              </ListItemControls>
            </ListItemContent>
          </ListItem>
        ))}
      </List>
    </DashboardTriggersContainer>
  );
};
