import { type FC } from "react";
import { Link } from "react-router-dom";

import { DateTime } from "luxon";

import { BasicLink } from "./basic_link";

export const Copyright: FC = () => (
  <div className="flex flex-col gap-4">
    <nav>
      <ul className="flex justify-center gap-4">
        <li>
          <BasicLink to="/login">Login</BasicLink>
        </li>
        <li>
          <BasicLink to="/signup">Signup</BasicLink>
        </li>
        <li>
          <BasicLink to="/blog">Blog</BasicLink>
        </li>
      </ul>
    </nav>
    <p className="text-center text-slate-600">
      © {DateTime.local().year} <Link to={"/"}>workflow.ing</Link>
    </p>
  </div>
);
