import { type InputHTMLAttributes, forwardRef } from "react";

import { clsx } from "clsx";

type T = HTMLInputElement;
type P = InputHTMLAttributes<T>;

export const Checkbox = forwardRef<T, P>(({ className, ...props }, ref) => (
  <input
    type="checkbox"
    ref={ref}
    className={clsx(
      "h-4 w-4 rounded-md border-slate-200 text-indigo-500 focus:ring-indigo-500",
      className,
    )}
    {...props}
  />
));
