import { type FC, useState } from "react";

import { useDashboardAgentsListQuery } from "@app_schema";

import { useDebounce } from "@application/hooks/use_debounce";

import { Fields } from "@styled/fields";
import { Headline } from "@styled/headline";
import { List } from "@styled/list";
import { ListItem } from "@styled/list_item";
import { ListItemContent } from "@styled/list_item_content";
import { ListItemControls } from "@styled/list_item_controls";
import { ListItemText } from "@styled/list_item_text";
import { ListItemTitle } from "@styled/list_item_title";
import { MineSwitch } from "@styled/mine_switch";
import { NewLink } from "@styled/new_link";
import { Page } from "@styled/page";
import { SearchInputField } from "@styled/search_input_field";
import { ViewLink } from "@styled/view_link";

import { AgentConverseButton } from "./agent_converse_button";
import { AgentName } from "./agent_name";

export const DashboardAgentsList: FC = () => {
  const [mine, setMine] = useState<boolean | undefined>();
  const [query, setQuery] = useState<string | undefined>();

  const { data } = useDashboardAgentsListQuery({
    variables: { mine, query: useDebounce(query) },
  });
  const agents = data?.agents;

  return (
    <Page>
      <Headline title="Agents">
        <NewLink to="/dashboard/agents/new" />
      </Headline>

      <Fields>
        <SearchInputField query={query} onSearch={setQuery} />
        <MineSwitch mine={mine} onChange={setMine} />
      </Fields>

      <List>
        {agents?.map((agent) => (
          <ListItem key={agent.id}>
            <ListItemContent>
              <ListItemText>
                <ListItemTitle>
                  <AgentName agent={agent} />
                </ListItemTitle>
              </ListItemText>

              <ListItemControls>
                <AgentConverseButton agent={agent} />
                <ViewLink to={`/dashboard/agents/${agent.id}`} />
              </ListItemControls>
            </ListItemContent>
          </ListItem>
        ))}
      </List>
    </Page>
  );
};
