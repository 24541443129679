import {
  DashboardMailboxesListDocument,
  type DashboardMailboxesListQuery,
  type DashboardMailboxesListQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_MAILBOXES_LIST_LOADER = async () => {
  const { data } = await client.query<
    DashboardMailboxesListQuery,
    DashboardMailboxesListQueryVariables
  >({
    query: DashboardMailboxesListDocument,
    fetchPolicy: "network-only",
  });

  return data;
};
