import { type FC } from "react";
import { Navigate, Outlet, useLocation } from "react-router";

import { useAuthenticated } from "@application/hooks/use_authenticated";

import { Dashboard as Layout } from "@layouts/dashboard";

export const Dashboard: FC = () => {
  const authenticated = useAuthenticated();
  const location = useLocation();

  if (!authenticated) return <Navigate to={"/login"} state={{ location }} />;

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};
