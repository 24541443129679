import { type FC } from "react";
import { Link } from "react-router-dom";

import { type Skill } from "@app_schema";

import { Badge } from "@styled/badge";

export const SkillBadgeLink: FC<{
  skill: Pick<Skill, "id" | "name">;
}> = ({ skill }) => (
  <Link to={`/dashboard/skills/${skill.id}`}>
    <Badge>{skill.name}</Badge>
  </Link>
);
