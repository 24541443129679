import mixpanel from "mixpanel-browser";

import * as Sentry from "@sentry/browser";

import { MIXPANEL_ENABLED } from "@application/initializers/mixpanel";
import { SENTRY_ENABLED } from "@application/initializers/sentry";

export const unidentify = () => {
  if (MIXPANEL_ENABLED) {
    mixpanel.reset();
  }

  if (SENTRY_ENABLED) {
    Sentry.setUser(null);
  }
};
