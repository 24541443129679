import { type FC, type ReactNode } from "react";
import { Link } from "react-router-dom";

export const BasicLink: FC<{
  to: string;
  children: ReactNode;
}> = ({ to, children }) => (
  <Link className="text-indigo-600 hover:underline" to={to}>
    {children}
  </Link>
);
