import { type FC, useEffect, useState } from "react";

import { useSkillPromptMutation } from "@app_schema";

import { useActionCableStream } from "@application/hooks/use_action_cable_stream";
import { useUUID } from "@application/hooks/use_uuid";

import { ChatForm } from "@styled/chat_form";

const REGEX = new RegExp(
  "\\s*LANGUAGE:\\s*(?<language>node|python|ruby)\\s*\\n" +
    "(?:\\s*NAME:\\s*(?<name>.+?)\\s*\\n)?" +
    "(?:\\s*DESCRIPTION:\\s*(?<description>.+?)\\s*\\n)?" +
    "(?:\\s*PARAMETERS:\\s*```(?:json)\\s*(?<parameters>.+?)\\s*```\\s*)?" +
    "(?:\\s*CODE:\\s*```(?:node|python|ruby)\\s*(?<code>.+?)\\s*```\\s*)?" +
    "(?:\\s*TEST:\\s*```(?:node|python|ruby)\\s*(?<test>.+?)\\s*```\\s*)?" +
    "(?:\\s*DOCUMENTATION:\\s*(?:```)(?:markdown)(?<documentation>.*)(?:```)\\s*)?",
  "ims",
);

export const SkillPromptForm: FC<{
  language?: string;
  onLanguage(_: string): void;
  onName(_: string): void;
  onDescription(_: string): void;
  onParameters(_: object): void;
  onCode(_: string): void;
  onTest(_: string): void;
  onDocumentation(_: string): void;
}> = ({
  onLanguage,
  onName,
  onDescription,
  onParameters,
  onCode,
  onTest,
  onDocumentation,
  language,
}) => {
  const uuid = useUUID();

  const [channel] = useState(() => ({
    channel: "SkillPromptChannel",
    uuid,
  }));

  const { text } = useActionCableStream(channel);

  useEffect(() => {
    if (!text) return;

    const match = text.match(REGEX);

    if (match?.groups) {
      const {
        language,
        name,
        description,
        parameters,
        code,
        test,
        documentation,
      } = match.groups;

      if (language) onLanguage(language);
      if (name) onName(name.trim());
      if (description) onDescription(description.trim());
      if (parameters) onParameters(JSON.parse(parameters.trim()));
      if (code) onCode(code.trim());
      if (test) onTest(test.trim());
      if (documentation) onDocumentation(documentation.trim());
    }
  }, [text, onLanguage, onCode, onTest, onDocumentation]);

  const [execute, { loading }] = useSkillPromptMutation();

  return (
    <ChatForm
      chat={async ({ message }) =>
        await execute({
          variables: {
            input: {
              language,
              uuid,
              message,
            },
          },
        })
      }
      loading={loading}
      placeholder="What are you trying to accomplish?"
    />
  );
};
