import { type FC } from "react";

import { MeFragment } from "@app_schema";

import { Skeleton } from "@styled/skeleton";

export const MenuProfileName: FC<{ me?: MeFragment | null }> = ({ me }) => {
  if (!me) return <Skeleton className="h-4 w-full flex-1 rounded" />;

  return <div className="text-base font-semibold">{me?.name}</div>;
};
