import { type FC, type ReactNode } from "react";

import { faTrash } from "@fortawesome/pro-duotone-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useDialog } from "@application/hooks/use_dialog";

import { Button } from "@styled/button";
import { Dialog } from "@styled/dialog";

export const DestroyButton: FC<{
  confirmation: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  destroy(): void;
  children?: ReactNode;
}> = ({ confirmation, disabled, loading, destroy, children = "Delete" }) => {
  const { confirm, dialog } = useDialog();

  const onClick = async () => {
    try {
      await confirm();
    } catch {
      return;
    }

    destroy();
  };

  return (
    <>
      {dialog && (
        <Dialog {...dialog} title="Are you sure?">
          {confirmation}
        </Dialog>
      )}

      <Button
        type="button"
        color="rose"
        onClick={onClick}
        loading={loading}
        disabled={disabled}
      >
        <FontAwesomeIcon icon={faTrash} /> {children}
      </Button>
    </>
  );
};
