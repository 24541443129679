import { type FC, FormEvent, useState } from "react";

import { Step, useStepRunMutation } from "@app_schema";

import { Button } from "@styled/button";
import { Form } from "@styled/form";
import { HTML } from "@styled/html";
import { InputField } from "@styled/input_field";
import { Modal } from "@styled/modal";
import { ModalClose } from "@styled/modal_close";
import { ModalContent } from "@styled/modal_content";
import { ModalFooter } from "@styled/modal_footer";
import { ModalHeader } from "@styled/modal_header";
import { ModalTitle } from "@styled/modal_title";
import { Notification } from "@styled/notification";

export const StepsRunDialog: FC<{
  workflowID: string;
  stepID: string;
  step: Pick<Step, "id" | "name"> & {
    action: Pick<Step["action"], "variables">;
  };
  onClose(): void;
}> = ({ workflowID, stepID, step, onClose }) => {
  const [variables, setVariables] = useState<Map<string, string>>(
    () => new Map<string, string>(),
  );

  const [execute, { loading, data }] = useStepRunMutation();
  const execution = data?.workflow.steps.step.run.execution;

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    execute({
      variables: {
        workflowID,
        stepID,
        input: { variables: Object.fromEntries(variables) },
      },
    });
  };

  return (
    <Modal onClose={onClose}>
      <Form onSubmit={onSubmit}>
        <ModalHeader>
          <ModalTitle>Run "{step.name}"</ModalTitle>
          <ModalClose onClose={onClose} />
        </ModalHeader>

        <ModalContent>
          {step.action.variables.map((variable) => (
            <InputField
              key={variable}
              name={variable}
              id={variable}
              label={variable}
              value={variables.get(variable)}
              onChange={(event) => {
                setVariables((variables) =>
                  new Map(variables).set(variable, event.target.value),
                );
              }}
            />
          ))}

          {execution?.html && (
            <Notification color="slate">
              <pre className="overflow-scroll">
                <HTML html={execution.html} />
              </pre>
            </Notification>
          )}
        </ModalContent>
        <ModalFooter>
          <Button type="submit" loading={loading}>
            Run
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
