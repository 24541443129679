import { type FC } from "react";

import { type AttachmentFragment } from "@app_schema";

import { FileLink } from "./file_link";

export const FileList: FC<{
  attachments: AttachmentFragment[];
}> = ({ attachments }) => (
  <div className="flex flex-wrap gap-2">
    {attachments.map((attachment) => (
      <FileLink key={attachment.id} attachment={attachment} />
    ))}
  </div>
);
