import { type FC, type FormEvent, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { faEnvelope } from "@fortawesome/pro-duotone-svg-icons/faEnvelope";
import { faIdCardClip } from "@fortawesome/pro-duotone-svg-icons/faIdCardClip";
import { faKey } from "@fortawesome/pro-duotone-svg-icons/faKey";

import { useAuthenticated } from "@application/hooks/use_authenticated";
import { useSignup } from "@application/hooks/use_signup";
import { useTitle } from "@application/hooks/use_title";
import { useTrackPageView } from "@application/hooks/use_track_page_view";

import { track } from "@application/utilities/track";

import { AuthLayout } from "@layouts/auth_layout";

import { BasicLink } from "@styled/basic_link";
import { Button } from "@styled/button";
import { Card } from "@styled/card";
import { Form } from "@styled/form";
import { InputField } from "@styled/input_field";

export const LoginLink: FC = () => <BasicLink to="/login">Login</BasicLink>;

export const Signup: FC = () => {
  useTrackPageView({ page: "signup" });
  useTitle("Workflow", "Signup");

  const { signup, loading, errors } = useSignup();

  const authenticated = useAuthenticated();
  const location = useLocation();

  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (loading) return;

    track("signup", {
      name,
      email,
    });

    signup({
      name,
      email,
      password,
    });
  };

  if (authenticated) {
    const params = new URLSearchParams(location.search);
    return <Navigate to={params.get("back") ?? "/dashboard"} />;
  }

  return (
    <AuthLayout>
      <Card>
        <Form onSubmit={onSubmit}>
          <InputField
            id="name"
            name="name"
            type="name"
            label="Name:"
            placeholder="Ringo Star"
            icon={faIdCardClip}
            errors={errors}
            value={name}
            onChange={(event) => setName(event.target.value)}
          />

          <InputField
            id="email"
            name="email"
            type="email"
            label="Email:"
            placeholder="me@workflow.ing"
            icon={faEnvelope}
            errors={errors}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />

          <InputField
            id="password"
            name="password"
            type="password"
            label="Password:"
            placeholder="abc$123!"
            icon={faKey}
            errors={errors}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />

          <Button full type="submit" loading={loading}>
            Signup
          </Button>

          <p className="text-center text-slate-600">
            Have an account? <LoginLink />
          </p>
        </Form>
      </Card>
    </AuthLayout>
  );
};
