import { type FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";

import { faNote as faDescription } from "@fortawesome/pro-duotone-svg-icons/faNote";

import { type MailboxInput, useMailboxBuildMutation } from "@app_schema";

import { Button } from "@styled/button";
import { Form } from "@styled/form";
import { InputField } from "@styled/input_field";
import { Notification } from "@styled/notification";
import { Page } from "@styled/page";
import { Sentence } from "@styled/sentence";

import { WorkflowSelectField } from "./workflow_select_field";

export const DashboardMailboxesForm: FC = () => {
  const navigate = useNavigate();
  const form = useForm<MailboxInput>({});

  const [execute, { loading, data }] = useMailboxBuildMutation();
  const errors = data?.mailboxes.build?.errors;

  const onSubmit = async (input: MailboxInput) => {
    if (loading) return;
    const result = await execute({ variables: { input } });
    const mailbox = result.data?.mailboxes.build?.mailbox;
    if (mailbox) navigate(`/dashboard/triggers/mailboxes/${mailbox.id}`);
  };

  const workflowID = form.watch("workflowID");

  return (
    <Page>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          {errors?.base && (
            <Notification color="rose">
              <Sentence>{errors.base}</Sentence>
            </Notification>
          )}

          <InputField
            {...form.register("description", { required: "required" })}
            id="description"
            label="Description:"
            placeholder="Description"
            icon={faDescription}
            errors={errors}
          />

          <WorkflowSelectField
            {...form.register("workflowID", { required: "required" })}
            placeholder={!workflowID}
            id="workflow_id"
            label="Workflow:"
          />

          <Button type="submit" loading={loading}>
            Save
          </Button>
        </Form>
      </FormProvider>
    </Page>
  );
};
