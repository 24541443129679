import { type FC, type ReactNode } from "react";

import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Feature: FC<{
  icon: IconProp;
  name: ReactNode;
  summary: ReactNode;
}> = ({ name, summary, icon }) => (
  <div className="flex gap-4">
    <div className="shrink-0 grow-0">
      <div className="flex h-12 w-12 items-center justify-center rounded-md bg-indigo-500 text-center text-2xl text-white">
        <FontAwesomeIcon icon={icon} />
      </div>
    </div>
    <div className="flex shrink grow flex-col gap-2">
      <h3 className="text-2xl font-semibold text-slate-800">{name}</h3>
      <p className="font-normal leading-8 text-slate-600">{summary}</p>
    </div>
  </div>
);
