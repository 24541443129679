import { LoaderFunctionArgs } from "react-router";

import {
  DashboardConversationsDetailsDocument,
  type DashboardConversationsDetailsQuery,
  type DashboardConversationsDetailsQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_CONVERSATIONS_DETAILS_LOADER = async (
  props: LoaderFunctionArgs,
) => {
  const { data } = await client.query<
    DashboardConversationsDetailsQuery,
    DashboardConversationsDetailsQueryVariables
  >({
    query: DashboardConversationsDetailsDocument,
    variables: { id: props.params.id! },
  });

  return data;
};
