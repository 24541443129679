import mixpanel from "mixpanel-browser";

import * as Sentry from "@sentry/browser";

import { User } from "@app_schema";

import { MIXPANEL_ENABLED } from "@application/initializers/mixpanel";
import { SENTRY_ENABLED } from "@application/initializers/sentry";

export const identify = (user: Pick<User, "id" | "name" | "email">) => {
  if (MIXPANEL_ENABLED) {
    mixpanel.identify(user.id);
    mixpanel.people.set({
      $name: user.name,
      $email: user.email,
    });
  }

  if (SENTRY_ENABLED) {
    Sentry.setUser({
      id: user.id,
      username: user.name,
      email: user.email,
    });
  }
};
