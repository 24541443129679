import { useContext } from "react";

import { identify } from "@application/utilities/identify";

import { World } from "@application/contexts/world";
import { type SignupInput, useSignupMutation } from "app_schema";

export const useSignup = () => {
  const [submit, { loading, data }] = useSignupMutation();
  const errors = data?.signup.errors;

  const { login } = useContext(World);

  return {
    signup: async (input: SignupInput) => {
      if (loading) return;
      const result = await submit({ variables: { input } });
      const token = result.data?.signup.token;
      const user = result.data?.signup.user;
      if (token) login(token);
      if (user) identify(user);
    },
    loading,
    errors,
  };
};
