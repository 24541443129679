import { type FC, useState } from "react";

import { faConveyorBelt } from "@fortawesome/pro-duotone-svg-icons/faConveyorBelt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { type SkillFragment } from "@app_schema";

import { Button } from "@styled/button";

import { SkillPackageDialog } from "./skill_package_dialog";

export const SkillPackageButton: FC<{
  skill: SkillFragment;
}> = ({ skill }) => {
  const [packaging, setPackaging] = useState<boolean>(false);
  const onToggle = () => setPackaging(!packaging);

  return (
    <>
      <Button type="button" onClick={onToggle}>
        <FontAwesomeIcon icon={faConveyorBelt} /> Package
      </Button>
      {packaging && <SkillPackageDialog skill={skill} onClose={onToggle} />}
    </>
  );
};
