import { type InputHTMLAttributes, forwardRef } from "react";

import { clsx } from "clsx";

import { FIELD_STATE_STYLE, FIELD_STYLE } from "./config";
import { FormState } from "./types";

type T = HTMLTextAreaElement;
type P = InputHTMLAttributes<T> & {
  state?: FormState;
  mono?: boolean;
};

export const TextArea = forwardRef<T, P>(
  ({ state = FormState.Default, mono, className, ...props }, ref) => (
    <textarea
      ref={ref}
      className={clsx(
        FIELD_STYLE,
        FIELD_STATE_STYLE[state],
        "max-h-96 min-h-32",
        mono && "font-mono",
        className,
      )}
      {...props}
    />
  ),
);

TextArea.displayName = "TextArea";
