import { type FC } from "react";

import { faCheckCircle } from "@fortawesome/pro-duotone-svg-icons/faCheckCircle";
import { faCircleExclamation } from "@fortawesome/pro-duotone-svg-icons/faCircleExclamation";
import { faPauseCircle } from "@fortawesome/pro-duotone-svg-icons/faPauseCircle";
import { faSpinner } from "@fortawesome/pro-duotone-svg-icons/faSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { type Run, RunStatusEnum } from "@app_schema";

import { Pill } from "@styled/pill";
import { Tooltip } from "@styled/tooltip";

import { RunInfo } from "./run_info";

const RunStatusRunning: FC = () => (
  <Pill color="slate">
    <FontAwesomeIcon spin icon={faSpinner} /> <div>Running</div>
  </Pill>
);
const RunStatusPaused: FC = () => (
  <Pill color="indigo">
    <FontAwesomeIcon icon={faPauseCircle} /> <div>Paused</div>
  </Pill>
);

const RunStatusSucceeded: FC = () => (
  <Pill color="emerald">
    <FontAwesomeIcon icon={faCheckCircle} /> <div>Succeeded</div>
  </Pill>
);

const RunStatusFailed: FC = () => (
  <Pill color="rose">
    <FontAwesomeIcon icon={faCircleExclamation} /> <div>Failed</div>
  </Pill>
);

export const RunStatus: FC<{
  run: Pick<Run, "id" | "status" | "started" | "finished" | "error">;
}> = ({ run }) => (
  <Tooltip tip={<RunInfo run={run} />}>
    {(() => {
      switch (run.status) {
        case RunStatusEnum.Running:
          return <RunStatusRunning />;
        case RunStatusEnum.Paused:
          return <RunStatusPaused />;
        case RunStatusEnum.Succeeded:
          return <RunStatusSucceeded />;
        case RunStatusEnum.Failed:
          return <RunStatusFailed />;
      }
    })()}
  </Tooltip>
);
