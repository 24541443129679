import { type FC, type FormEvent, useState } from "react";

import { faKey as faPassword } from "@fortawesome/pro-duotone-svg-icons/faKey";

import { usePasswordChangeMutation } from "@app_schema";

import { Button } from "@styled/button";
import { Form } from "@styled/form";
import { InputField } from "@styled/input_field";
import { Notification } from "@styled/notification";
import { Sentence } from "@styled/sentence";

export const SettingsPasswordChangeForm: FC = () => {
  const [execute, { loading, data }] = usePasswordChangeMutation();
  const errors = data?.user.password.change.errors;

  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (loading) return;

    execute({
      variables: {
        input: {
          oldPassword,
          newPassword,
        },
      },
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      {errors?.base && (
        <Notification color="rose">
          <Sentence>{errors.base}</Sentence>
        </Notification>
      )}

      <InputField
        id="oldPassword"
        name="oldPassword"
        type="password"
        label="Old Password:"
        placeholder="abc$123!"
        icon={faPassword}
        errors={errors}
        value={oldPassword}
        onChange={(event) => setOldPassword(event.target.value)}
      />

      <InputField
        id="newPassword"
        name="newPassword"
        type="password"
        label="New Password:"
        placeholder="abc$123!"
        icon={faPassword}
        errors={errors}
        value={newPassword}
        onChange={(event) => setNewPassword(event.target.value)}
      />

      <Button type="submit" loading={loading}>
        Change Password
      </Button>
    </Form>
  );
};
