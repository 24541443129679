import { type FC, type ReactNode } from "react";

export const Section: FC<{
  title: string;
  subtitle: string;
  children: ReactNode;
}> = ({ title, subtitle, children }) => (
  <div className="flex flex-col gap-8">
    <div>
      <h2 className="mb-5 text-center text-2xl font-black text-slate-800 md:text-4xl">
        {title}
      </h2>
      <h3 className="mb-5 text-center text-2xl font-semibold text-slate-600 md:text-2xl">
        {subtitle}
      </h3>
    </div>

    <div>{children}</div>
  </div>
);
