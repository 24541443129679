import { type FC } from "react";

import { type MailboxFragment, useMailboxDestroyMutation } from "@app_schema";

import { DestroyButton } from "@styled/destroy_button";

export const MailboxDestroyButton: FC<{
  mailbox: MailboxFragment;
}> = ({ mailbox }) => {
  const [execute, { loading }] = useMailboxDestroyMutation({
    variables: { id: mailbox.id },
  });

  const workflow = mailbox.workflow;

  return (
    <DestroyButton
      disabled={mailbox.deleted}
      destroy={execute}
      loading={loading}
      confirmation={
        <>
          Are you sure you want to delete the mailbox "{mailbox.description}" on
          the workflow "{workflow.name}"? Once deleted any future requests to
          the mailbox will result in an error.
        </>
      }
    />
  );
};
