import { type FC, useEffect, useState } from "react";

import { DateTime } from "luxon";

const INTERVAL = 500; // ms
const FORMAT = "m'm' s's'";

export const Timer: FC<{
  from?: string | null;
  till?: string | null;
  paused?: boolean;
}> = ({ from, till, paused }) => {
  const [initial] = useState(() => DateTime.local());
  const [current, setCurrent] = useState(() => DateTime.local());

  useEffect(() => {
    if (paused) return;
    if (from && till) return;
    if (!from && !till) return;

    const interval = setInterval(() => setCurrent(DateTime.local()), INTERVAL);
    return () => clearInterval(interval);
  }, [from, till, paused]);

  if (!from && !till) return null;

  const a = till ? DateTime.fromISO(till) : current;
  const b = from ? DateTime.fromISO(from) : initial;
  const duration = a.diff(b).normalize();

  return <span className="text-slate-500">{duration.toFormat(FORMAT)}</span>;
};
