import { type FC } from "react";
import { useNavigate } from "react-router";

import { Step, useStepDestroyMutation } from "@app_schema";

import { DestroyButton } from "@styled/destroy_button";

export const StepsDestroyButton: FC<{
  step: Pick<Step, "name">;
  workflowID: string;
  stepID: string;
}> = ({ workflowID, stepID, step }) => {
  const navigate = useNavigate();
  const [execute, { loading }] = useStepDestroyMutation({
    variables: {
      workflowID,
      stepID,
    },
  });

  return (
    <DestroyButton
      destroy={() => {
        execute();
        navigate(`/dashboard/workflows/${workflowID}`);
      }}
      loading={loading}
      confirmation={<>Are you sure you want to delete "{step.name}"?</>}
    />
  );
};
