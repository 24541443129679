import { type FC } from "react";
import { type Location, useLocation } from "react-router";

import { Clipboard } from "@styled/clipboard";
import { Notification } from "@styled/notification";
import { Page } from "@styled/page";
import { Well } from "@styled/well";

export const DashboardWebhooksDetails: FC = () => {
  const location: Location<{ url: string }> = useLocation();
  const url = location.state.url;

  return (
    <Page>
      <Notification color="indigo">
        Please copy / paste the code associated with your webhook. The
        credentials associated with this webhook will not be available in the
        future.
      </Notification>

      <Well actions={<Clipboard text={`curl -X POST "${url}"`} />}>
        <code>curl -X POST {url}</code>
      </Well>
    </Page>
  );
};
