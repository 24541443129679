import type { FC } from "react";

import { type Agent, useAgentDestroyMutation } from "@app_schema";

import { DestroyButton } from "@styled/destroy_button";

export const AgentDestroyButton: FC<{
  agent: Pick<Agent, "id" | "name">;
  onDestroy?(): void;
}> = ({ agent, onDestroy }) => {
  const [execute, { loading }] = useAgentDestroyMutation();
  const destroy = async () => {
    await execute({ variables: { id: agent.id } });
    if (onDestroy) onDestroy();
  };

  return (
    <DestroyButton
      destroy={destroy}
      loading={loading}
      confirmation={<>Are you you sure you want to delete “{agent.name}”?</>}
    >
      Delete
    </DestroyButton>
  );
};
