import { type HTMLAttributes, forwardRef } from "react";

import { clsx } from "clsx";

type T = HTMLDivElement;
type P = HTMLAttributes<T>;

const PROSE_CODE_STYLE =
  "prose-pre:border prose-pre:border-slate-200 prose-pre:bg-slate-100 prose-pre:text-base prose-pre:text-slate-800";

const PROSE_LINK_STYLE = "prose-a:text-indigo-600";

export const Prose = forwardRef<T, P>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={clsx(
      "prose prose-slate max-w-none",
      PROSE_LINK_STYLE,
      PROSE_CODE_STYLE,
      className,
    )}
    {...props}
  />
));
