import {
  DashboardWebhooksListDocument,
  type DashboardWebhooksListQuery,
  type DashboardWebhooksListQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_WEBHOOKS_LIST_LOADER = async () => {
  const { data } = await client.query<
    DashboardWebhooksListQuery,
    DashboardWebhooksListQueryVariables
  >({
    query: DashboardWebhooksListDocument,
    fetchPolicy: "network-only",
  });

  return data;
};
