import { type FC, type ReactNode } from "react";

import { Menu } from "./dashboard/menu";

export const Dashboard: FC<{
  children: ReactNode;
}> = ({ children }) => (
  <div className="flex h-screen">
    <div className="flex-0 h-full w-64 overflow-y-auto border-r border-slate-200 bg-slate-100">
      <div className="m-4">
        <Menu />
      </div>
    </div>
    <div className="h-full flex-1 overflow-y-auto">{children}</div>
  </div>
);
