import { type FC } from "react";

import { ModifyLink } from "@styled/modify_link";

export const StepsModifyLink: FC<{
  workflowID: string;
  stepID: string;
}> = ({ workflowID, stepID }) => (
  <ModifyLink to={`/dashboard/workflows/${workflowID}/steps/${stepID}/edit`} />
);
