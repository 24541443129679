import { type FC, type ReactNode } from "react";
import { useMatches } from "react-router";

import { type ApolloError } from "@apollo/client";

import { type Breadcrumb } from "@application/types/breadcrumb";

import { useTitle } from "@application/hooks/use_title";

import { Breadcrumbs } from "@styled/breadcrumbs";
import { Content } from "@styled/content";
import { Loader } from "@styled/loader";
import { Retry } from "@styled/retry";

export const Page: FC<{
  error?: ApolloError;
  loading?: boolean;
  retry?(): void;
  children: ReactNode;
}> = ({ error, loading, retry, children }) => {
  const matches = useMatches();

  const breadcrumbs: Breadcrumb[] = matches
    .map((match) =>
      typeof match.handle === "function"
        ? match.handle(match.data)
        : match.handle,
    )
    .filter((breadcrumb): breadcrumb is Breadcrumb => breadcrumb !== undefined);

  useTitle("Workflow", ...breadcrumbs.map(({ name }) => name));

  return (
    <Content>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      {error && <Retry error={error} retry={retry} />}
      {children}
      {loading && <Loader />}
    </Content>
  );
};
