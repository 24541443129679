import { type FC } from "react";
import { Link } from "react-router-dom";

import { faGears } from "@fortawesome/pro-duotone-svg-icons/faGears";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Logo: FC = () => (
  <Link to="/" className="flex select-none gap-2">
    <span className="text-4xl text-rose-500 md:text-6xl">
      <FontAwesomeIcon icon={faGears} />
    </span>

    <span className="bg-gradient-to-r from-orange-500 via-pink-500 to-blue-500 bg-clip-text text-transparent">
      <span className="text-4xl font-black md:text-6xl">workflow</span>
      <span className="text-2xl font-light md:text-2xl">labs</span>
    </span>
  </Link>
);
