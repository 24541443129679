import { type FC, type ReactNode, useContext } from "react";

import { AttachmentContext } from "./attachment_context";
import { AttachmentsContext } from "./attachments_context";

export const AttachmentProvider: FC<{
  index: number;
  children: ReactNode;
}> = ({ index, children }) => {
  const { name, onDelete } = useContext(AttachmentsContext);

  return (
    <AttachmentContext.Provider
      value={{
        name: `${name}.${index}`,
        onDelete: () => {
          onDelete(index);
        },
      }}
      children={children}
    />
  );
};
