import { type HTMLAttributes, forwardRef } from "react";

import { clsx } from "clsx";

import { Aspect, STYLE_FOR_ASPECT } from "./aspect";

type T = HTMLImageElement;
type P = HTMLAttributes<T> & {
  rounded?: boolean;
  bordered?: boolean;
  alt?: string;
  avif: string;
  jpeg: string;
  webp: string;
  aspect: Aspect;
};

export const Picture = forwardRef<T, P>(
  (
    { avif, jpeg, webp, aspect, bordered, rounded, className, ...props },
    ref,
  ) => (
    <picture>
      <source type="image/avif" srcSet={avif} />
      <source type="image/webp" srcSet={webp} />
      <source type="image/jpeg" srcSet={jpeg} />
      <img
        {...props}
        src={jpeg ?? avif ?? webp}
        ref={ref}
        className={clsx(
          "w-full bg-slate-50 object-cover",
          bordered && "border border-slate-200",
          rounded && "rounded-md",
          STYLE_FOR_ASPECT[aspect],
          className,
        )}
      />
    </picture>
  ),
);
