import { useMeQuery } from "@app_schema";

import { MenuProfileAvatar } from "./menu_profile_avatar";
import { MenuProfileContainer } from "./menu_profile_container";
import { MenuProfileName } from "./menu_profile_name";

export const MenuProfile = () => {
  const { data } = useMeQuery();

  return (
    <MenuProfileContainer>
      <MenuProfileAvatar me={data?.me} />
      <MenuProfileName me={data?.me} />
    </MenuProfileContainer>
  );
};
