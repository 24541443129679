import { type FC } from "react";
import { useNavigate, useParams } from "react-router";

import {
  type AgentInput,
  useAgentChangeMutation,
  useDashboardAgentsDetailsQuery,
} from "@app_schema";

import { DashboardAgentsForm } from "./agents_form";

export const DashboardAgentsEdit: FC = () => {
  const { id } = useParams<{ id: string }>();
  if (!id) throw new Error("undefined ID");

  const navigate = useNavigate();
  const [execute, { loading, data }] = useAgentChangeMutation();
  const { data: defaults } = useDashboardAgentsDetailsQuery({
    variables: { id },
  });

  if (!defaults) return;

  const errors = data?.agent.change.errors;

  const save = async (input: AgentInput) => {
    const result = await execute({ variables: { id, input } });
    const agent = result?.data?.agent.change.agent;
    if (agent) navigate(`/dashboard/agents/${agent.id}`);
  };

  return (
    <DashboardAgentsForm
      save={save}
      errors={errors}
      loading={loading}
      agent={defaults?.agent}
    />
  );
};
