import { useState } from "react";

import { ChannelNameWithParams } from "@rails/actioncable";

import { useActionCableSubscription } from "./use_action_cable_subscription";

export enum Status {
  Connected = "connected",
  Disconnected = "disconnected",
}

export const useActionCableStream = (
  channel: string | ChannelNameWithParams,
): { status: Status | undefined; text: string } => {
  const [text, setText] = useState<string>("");

  const status = useActionCableSubscription(channel, (chunk: string | null) => {
    if (chunk === null) setText("");
    else setText((prev) => prev + chunk);
  });

  return { status, text };
};
