import { type FC } from "react";

import { Price } from "./price";
import { Section } from "./section";

const PRICES: Array<{
  name: string;
  amount: number;
  term: "month" | "year";
  summary: string;
  features: string[];
}> = [
  {
    name: "Starter",
    amount: 0,
    term: "month",
    summary: "A free way to build your first workflow while getting started.",
    features: [
      "Limited workflows / skills",
      "1,000 free run minutes per month",
      "1 parallel run at a time",
      "1 active user",
      "Limited support",
    ],
  },
  {
    name: "Individual",
    amount: 19,
    term: "month",
    summary:
      "The cheapest option for an individual looking to use without a team.",
    features: [
      "Unlimited workflows / skills",
      "3,000 free run minutes per month",
      "2 parallel runs at a time",
      "1 active user",
      "24-hour SLA support",
    ],
  },
  {
    name: "Team",
    amount: 29,
    term: "month",
    summary: "The best option for teams looking to collaborate.",
    features: [
      "Unlimited workflows / skills",
      "6,000 free run minutes per month",
      "4 parallel runs at a time",
      "5 active users",
      "24-hour SLA support",
    ],
  },
  {
    name: "Organization",
    amount: 99,
    term: "month",
    summary: "We recommend getting in touch to understand your needs.",
    features: [
      "Unlimited workflows / skills",
      "9,000 free run minutes per month",
      "8 parallel runs at a time",
      "Unlimited users",
      "24-hour SLA support",
    ],
  },
];

export const Prices: FC = () => (
  <Section
    title="Pricing Options for Any Team Size"
    subtitle="Pricing for individuals, teams, or organizations. Save by switching to annual billing."
    children={
      <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
        {PRICES.map((price, key) => (
          <Price key={key} {...price} />
        ))}
      </div>
    }
  />
);
