import { useContext, useEffect, useRef, useState } from "react";

import { ChannelNameWithParams } from "@rails/actioncable";
import * as ActionCable from "@rails/actioncable";

import { World } from "../contexts/world";

export enum Status {
  Connected = "connected",
  Disconnected = "disconnected",
}

export const useActionCableSubscription = <T>(
  channel: string | ChannelNameWithParams,
  callback?: (data: T) => void,
): Status | undefined => {
  const { token } = useContext(World);

  const [status, setStatus] = useState<Status | undefined>(undefined);
  const ref = useRef(callback);

  useEffect(() => {
    const client = new ActionCable.Consumer(
      token ? `/cable?token=${token}` : "/cable",
    );

    const subscription = client.subscriptions.create(channel, {
      connected: () => setStatus(Status.Connected),
      disconnected: () => setStatus(Status.Disconnected),
      received: (data: T) => {
        if (ref.current) {
          ref.current(data);
        }
      },
    });
    return (): void => {
      subscription.unsubscribe();
    };
  }, [channel, token]);

  return status;
};
