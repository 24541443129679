import { redirect } from "react-router";

import {
  DashboardBillingDocument,
  type DashboardBillingQuery,
  type DashboardBillingQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_BILLING_LOADER = async () => {
  const returnURL = `${location.protocol}//${location.host}/dashboard`;

  const { data } = await client.query<
    DashboardBillingQuery,
    DashboardBillingQueryVariables
  >({
    query: DashboardBillingDocument,
    variables: { returnURL },
  });

  return redirect(data.result.url);
};
