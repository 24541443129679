import { type HTMLAttributes, forwardRef } from "react";

import { clsx } from "clsx";

type T = HTMLFieldSetElement;
type P = HTMLAttributes<T>;

export const Fieldset = forwardRef<T, P>(({ className, ...props }, ref) => (
  <fieldset ref={ref} className={clsx("space-y-4", className)} {...props} />
));

Fieldset.displayName = "Fieldset";
