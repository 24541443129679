import { type FC, useState } from "react";

import { faClone } from "@fortawesome/pro-duotone-svg-icons/faClone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { type Workflow } from "@app_schema";

import { Button } from "@styled/button";

import { WorkflowCloneModal } from "./workflow_clone_modal";

export const WorkflowCloneButton: FC<{
  workflow: Pick<Workflow, "id" | "name">;
}> = ({ workflow }) => {
  const [cloning, setCloning] = useState<boolean>(false);

  const onToggle = () => setCloning(!cloning);

  return (
    <>
      <Button type="button" onClick={onToggle}>
        <FontAwesomeIcon icon={faClone} /> Clone
      </Button>

      {cloning && <WorkflowCloneModal workflow={workflow} onClose={onToggle} />}
    </>
  );
};
