import { type FC, type ReactNode } from "react";

import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCalendar } from "@fortawesome/pro-duotone-svg-icons/faCalendar";
import { faLanguage } from "@fortawesome/pro-duotone-svg-icons/faLanguage";
import { faRobot } from "@fortawesome/pro-duotone-svg-icons/faRobot";
import { faStopwatch } from "@fortawesome/pro-duotone-svg-icons/faStopwatch";

import { Feature } from "./feature";
import { Section } from "./section";

const FEATURES: Array<{
  icon: IconProp;
  name: ReactNode;
  summary: ReactNode;
}> = [
  {
    icon: faRobot,
    name: <>Interact with an AI agent to build a workflow</>,
    summary: (
      <>
        Our AI agent guides you through the process of building and deploying
        workflows. Agents grow their skills to complete tasks, then learn and
        save those skills to perform later.
      </>
    ),
  },
  {
    icon: faLanguage,
    name: <>Interact with over a dozen different AI models</>,
    summary: (
      <>
        Take advantage of OpenAI’s ChatGPT, Google’s Gemini, Anthropic’s Claude,
        or Mistral’s Chat to seamlessly run prompts. Use formats to convert
        anything into structured data.
      </>
    ),
  },
  {
    icon: faCalendar,
    name: <>Schedule your workflows to run automatically</>,
    summary: (
      <>
        Scheduling workflows to run at the right cadence for your business is
        simple. Schedules can be defined at any time you need. Watch your
        scheduled workflows run and get notified if your workflow does not
        complete as expected.
      </>
    ),
  },
  {
    icon: faStopwatch,
    name: <>Manually run your workflows at any time</>,
    summary: (
      <>
        If at any point you need to run a workflow, you can trigger a run and
        watch it execute in real-time. If you need to schedule a workflow
        programmatically, you can use our webhook API to trigger a run and get
        notified of the status.
      </>
    ),
  },
];

export const Features: FC = () => (
  <Section
    title="Focus on Your Process and Let Us Handle the Automation"
    subtitle="An effortless cloud environment to build and run your workflows in."
    children={
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
        {FEATURES.map((feature, key) => (
          <Feature key={key} {...feature} />
        ))}
      </div>
    }
  />
);
