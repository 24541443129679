import { type FC } from "react";

import { faSquareMinus } from "@fortawesome/pro-duotone-svg-icons/faSquareMinus";
import { faSquarePlus } from "@fortawesome/pro-duotone-svg-icons/faSquarePlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { NodeButton } from "./node_button";

export const NodeButtonToggle: FC<{
  expanded?: boolean;
  onToggle(expanded: boolean): void;
}> = ({ expanded, onToggle }) => (
  <NodeButton onClick={() => onToggle(!expanded)}>
    <FontAwesomeIcon icon={expanded ? faSquareMinus : faSquarePlus} />
  </NodeButton>
);
