import { type FC } from "react";
import { Link } from "react-router-dom";

import { Secret } from "@app_schema";

import { Badge } from "@styled/badge";

export const SecretBadgeLink: FC<{
  secret: Pick<Secret, "id" | "key">;
}> = ({ secret }) => (
  <Link to={`/dashboard/secrets/${secret.id}`}>
    <Badge>{secret.key}</Badge>
  </Link>
);
