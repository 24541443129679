import { type FC } from "react";

import { clsx } from "clsx";

import { Aspect } from "./aspect";
import { Picture } from "./picture";

export const Thumbnail: FC<{
  onSelect(): void;
  rounded?: boolean;
  bordered?: boolean;
  alt: string;
  avif: string;
  jpeg: string;
  webp: string;
  aspect: Aspect;
}> = ({ bordered, rounded, onSelect, ...props }) => (
  <button
    type="button"
    className={clsx(
      "border border-slate-200 bg-white p-2 transition hover:border-indigo-200",
      bordered && "border border-slate-200",
      rounded && "rounded-xl",
    )}
    onClick={onSelect}
  >
    <Picture rounded={rounded} bordered={bordered} {...props} />
  </button>
);
