import { type FC } from "react";

import { useDashboardSettingsQuery } from "@app_schema";

import { Page } from "@styled/page";
import { Title } from "@styled/title";

import { SettingsAvatarAttachDetachForm } from "./settings_avatar_attach_detach_form";
import { SettingsPasswordChangeForm } from "./settings_password_change_form";
import { SettingsSecretsForm } from "./settings_secrets_form";
import { SettingsUserChangeForm } from "./settings_user_change_form";

export const DashboardSettings: FC = () => {
  const { data } = useDashboardSettingsQuery();
  const me = data?.me;

  return (
    <Page>
      <Title>Avatar</Title>
      {me && <SettingsAvatarAttachDetachForm me={me} />}

      <Title>Profile</Title>
      {me && <SettingsUserChangeForm me={me} />}

      <Title>Password</Title>
      {me && <SettingsPasswordChangeForm />}

      <Title>Secrets</Title>
      <SettingsSecretsForm />
    </Page>
  );
};
