import { type HTMLAttributes, forwardRef } from "react";

import { clsx } from "clsx";

type T = HTMLDivElement;
type P = HTMLAttributes<T>;

export const Card = forwardRef<T, P>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={clsx(
      "flex flex-col gap-4 rounded bg-white p-8 shadow",
      className,
    )}
    {...props}
  />
));
