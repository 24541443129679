import { type FC, type ReactNode, useState } from "react";

import { clsx } from "clsx";

import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons/faInfoCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TransitionFade } from "./transition_fade";
import { type Placement } from "@floating-ui/react-dom";
import { useFloating } from "@floating-ui/react-dom";
import { offset } from "@floating-ui/react-dom";

const TOOLTIP_ARROW_PLACEMENT_STYLE: Record<Placement, string> = {
  top: "bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2",
  bottom: "top-0 left-1/2 -translate-x-1/2 -translate-y-1/2",
  left: "right-0 top-1/2 translate-x-1/2 -translate-y-1/2",
  right: "left-0 top-1/2 -translate-x-1/2 -translate-y-1/2",
  "top-start": "bottom-0 left-4 -translate-x-1/2 translate-y-1/2",
  "top-end": "bottom-0 right-4 translate-x-1/2 translate-y-1/2",
  "bottom-start": "top-0 left-4 -translate-x-1/2 -translate-y-1/2",
  "bottom-end": "top-0 right-4 translate-x-1/2 -translate-y-1/2",
  "left-start": "right-0 top-4 translate-x-1/2 -translate-y-1/2",
  "left-end": "right-0 bottom-4 translate-x-1/2 -translate-y-1/2",
  "right-start": "left-0 top-4 -translate-x-1/2 -translate-y-1/2",
  "right-end": "left-0 bottom-4 -translate-x-1/2 -translate-y-1/2",
};

const TooltipArrow: FC<{
  placement: Placement;
}> = ({ placement }) => (
  <div
    className={clsx(
      "absolute z-10 h-2 w-2 rotate-45 bg-slate-800",
      TOOLTIP_ARROW_PLACEMENT_STYLE[placement],
    )}
  />
);

const TooltipBubble: FC<{
  children: ReactNode;
}> = (props) => (
  <div
    {...props}
    className="relative rounded-md bg-slate-800 px-3 py-2 font-medium text-white shadow"
  />
);

export const Tooltip: FC<{
  children?: ReactNode;
  tip: ReactNode;
  placement?: Placement;
}> = ({ children, tip, placement = "top" }) => {
  const [hovering, setHovering] = useState(false);

  const { refs, floatingStyles } = useFloating({
    placement,
    middleware: [offset(8)],
  });

  return (
    <span
      ref={refs.setReference}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      {children ? children : <FontAwesomeIcon icon={faInfoCircle} />}

      <TransitionFade show={hovering}>
        <div ref={refs.setFloating} style={floatingStyles}>
          <TooltipBubble>
            {tip}
            <TooltipArrow placement={placement} />
          </TooltipBubble>
        </div>
      </TransitionFade>
    </span>
  );
};
