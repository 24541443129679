import { type FC, useState } from "react";

import { Lightbox } from "@styled/lightbox";
import { Thumbnail } from "@styled/thumbnail";

export const Screenshot: FC<{
  src: {
    alt: string;
    avif: string;
    jpeg: string;
    webp: string;
  };
  text: string;
}> = ({ src, text }) => {
  const [active, setActive] = useState(false);
  const onToggle = () => setActive((active) => !active);

  return (
    <>
      <div className="flex flex-col gap-2">
        <Thumbnail aspect="8/5" rounded bordered onSelect={onToggle} {...src} />
        <div className="text-center text-lg font-semibold text-slate-800">
          {text}
        </div>
      </div>
      {active && (
        <Lightbox aspect="8/5" src={src} onClose={onToggle} title={text} />
      )}
    </>
  );
};
