import { type FC } from "react";

import { type Workflow } from "@app_schema";

import { ModifyLink } from "@styled/modify_link";

export const WorkflowModifyLink: FC<{
  workflow: Pick<Workflow, "id" | "name">;
}> = ({ workflow }) => (
  <ModifyLink to={`/dashboard/workflows/${workflow.id}/edit`} />
);
