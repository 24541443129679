import { useEffect, useState } from "react";

const TIMEOUT = 2000; // 2 seconds

export const useClipboard = (text: string) => {
  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    if (!copied) return;
    const timeout = setTimeout(() => setCopied(false), TIMEOUT);
    return () => clearTimeout(timeout);
  }, [copied]);

  const copy = async () => {
    await navigator.clipboard.writeText(text);
    setCopied(true);
  };

  return { copied, copy };
};
