import { type FC } from "react";

import { Formatter } from "@app_schema";

import { JSONFormatter } from "@styled/json_formatter";

export const FormatterSummary: FC<{
  formatter: Pick<Formatter, "kind" | "options">;
}> = ({ formatter }) => {
  return (
    <div className="block overflow-scroll">
      {formatter.kind}:<br />
      <JSONFormatter json={formatter.options} />
    </div>
  );
};
