import { type FC, type ReactNode } from "react";

import { faPencil } from "@fortawesome/pro-duotone-svg-icons/faPencil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ButtonLink } from "@styled/button_link";

export const ModifyLink: FC<{
  to: string;
  children?: ReactNode;
}> = ({ to, children = "Modify" }) => (
  <ButtonLink to={to}>
    <FontAwesomeIcon icon={faPencil} /> {children}
  </ButtonLink>
);
