import {
  DashboardUsersListDocument,
  type DashboardUsersListQuery,
  type DashboardUsersListQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_USERS_LIST_LOADER = async () => {
  const { data } = await client.query<
    DashboardUsersListQuery,
    DashboardUsersListQueryVariables
  >({
    query: DashboardUsersListDocument,
    fetchPolicy: "network-only",
  });

  return data;
};
