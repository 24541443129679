import { type FC, type ReactNode } from "react";

const HeadlineTitle: FC<{
  children: ReactNode;
}> = ({ children }) => (
  <div className="text-lg font-semibold text-slate-800">{children}</div>
);

const HeadlineSubtitle: FC<{
  children: ReactNode;
}> = ({ children }) => (
  <div className="text-sm font-medium text-slate-600">{children}</div>
);

const HeadlineContent: FC<{
  children: ReactNode;
}> = ({ children }) => <div className="flex gap-4">{children}</div>;

const HeadlineContainer: FC<{
  children: ReactNode;
}> = ({ children }) => (
  <div className="flex items-center justify-between border-b border-slate-200 pb-4">
    {children}
  </div>
);

export const Headline: FC<{
  title?: ReactNode;
  subtitle?: ReactNode;
  children?: ReactNode;
}> = ({ title, subtitle, children }) => (
  <HeadlineContainer>
    {(title || subtitle) && (
      <div>
        {title && <HeadlineTitle>{title}</HeadlineTitle>}
        {subtitle && <HeadlineSubtitle>{subtitle}</HeadlineSubtitle>}
      </div>
    )}
    {children && <HeadlineContent>{children}</HeadlineContent>}
  </HeadlineContainer>
);
