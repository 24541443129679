import { type FC } from "react";
import { useNavigate } from "react-router";

import { type AgentInput, useAgentBuildMutation } from "@app_schema";

import { DashboardAgentsForm } from "./agents_form";

export const DashboardAgentsNew: FC = () => {
  const navigate = useNavigate();
  const [execute, { loading, data }] = useAgentBuildMutation();
  const errors = data?.agents.build.errors;

  const save = async (input: AgentInput) => {
    const result = await execute({ variables: { input } });
    const agent = result?.data?.agents.build.agent;
    if (agent) navigate(`/dashboard/agents/${agent.id}`);
  };

  return <DashboardAgentsForm save={save} errors={errors} loading={loading} />;
};
