import { type FC } from "react";

const TOKEN_REGEX = /(\{\{\{?[^}{]+\}?\}\}|<[/]?[\w-]+>)/g;

const TAG_REGEX = /(?<lbracket><)(?<slash>[/]?)(?<name>[\w-]+)(?<rbracket>>)/;

const MUSTACHE_REGEX =
  /(?<lbraces>\{\{\{?)(?<operation>[#/^&]?)(?<value>[^}^{]+)(?<rbraces>\}?\}\})/;

const MustacheOperation: FC<{
  text: string;
}> = ({ text }) => <span className="text-violet-500">{text}</span>;

const MustacheBraces: FC<{
  text: string;
}> = ({ text }) => <span className="text-indigo-500">{text}</span>;

const MustacheValue: FC<{
  text: string;
}> = ({ text }) => <span className="text-rose-500">{text}</span>;

const Mustache: FC<{
  lbraces?: string;
  operation?: string;
  value?: string;
  rbraces?: string;
}> = ({ lbraces, rbraces, operation, value }) => (
  <span>
    {lbraces && <MustacheBraces text={lbraces} />}
    {operation && <MustacheOperation text={operation} />}
    {value && <MustacheValue text={value} />}
    {rbraces && <MustacheBraces text={rbraces} />}
  </span>
);

const TagBracket: FC<{
  text: string;
}> = ({ text }) => <span className="text-sky-500">{text}</span>;

const TagSlash: FC<{
  text: string;
}> = ({ text }) => <span className="text-cyan-500">{text}</span>;

const TagName: FC<{
  text: string;
}> = ({ text }) => <span className="text-teal-500">{text}</span>;

const Tag: FC<{
  lbracket?: string;
  rbracket?: string;
  slash?: string;
  name?: string;
}> = ({ lbracket, rbracket, slash, name }) => (
  <span>
    {lbracket && <TagBracket text={lbracket} />}
    {slash && <TagSlash text={slash} />}
    {name && <TagName text={name} />}
    {rbracket && <TagBracket text={rbracket} />}
  </span>
);

export const Highlighter: FC<{
  template: string;
}> = ({ template }) => {
  const parts = template.split(TOKEN_REGEX);

  return (
    <pre>
      {parts.map((part, index) => {
        const mustache = part.match(MUSTACHE_REGEX);
        if (mustache) return <Mustache key={index} {...mustache.groups} />;

        const tag = part.match(TAG_REGEX);
        if (tag) return <Tag key={index} {...tag.groups} />;

        return (
          <span key={index} className="text-slate-500">
            {part}
          </span>
        );
      })}
    </pre>
  );
};
