import * as Sentry from "@sentry/browser";

import {
  SENTRY_DSN,
  SENTRY_TRACES_SAMPLE_RATE,
} from "@application/config/sentry";

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE ?? undefined,
  });
}

export const SENTRY_ENABLED = !!SENTRY_DSN;
