import { type FC, type ReactNode } from "react";

import { Color } from "./types";

const COLOR_STYLE: Record<Color, string> = {
  rose: "text-rose-500",
  indigo: "text-indigo-500",
  slate: "text-slate-500",
  white: "text-white",
};

export const Hint: FC<{
  color?: Color;
  children: ReactNode;
}> = ({ color = "rose", children }) => (
  <p className={COLOR_STYLE[color]}>{children}</p>
);

Hint.displayName = "Hint";
