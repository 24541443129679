import { type FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { type StepInput, StepKindEnum } from "@app_schema";

import { Field } from "@styled/field";
import { Label } from "@styled/label";
import { RadioCard } from "@styled/radio_card";
import { RadioCardSubtitle } from "@styled/radio_card_subtitle";
import { RadioCardTitle } from "@styled/radio_card_title";
import { RadioGrid } from "@styled/radio_grid";

import { STEP_KIND_ICON } from "./step_kind_icon";

type Summary = {
  title: string;
  subtitle: string;
};

const METADATA: Record<StepKindEnum, Summary> = {
  [StepKindEnum.Branch]: {
    title: "Branch",
    subtitle: "Run another workflow",
  },
  [StepKindEnum.Prompt]: {
    title: "Prompt",
    subtitle: "Interact with AI agents",
  },
  [StepKindEnum.Code]: {
    title: "Code",
    subtitle: "Run code remotely",
  },
  [StepKindEnum.Formatter]: {
    title: "Formatter",
    subtitle: "Transform data into text",
  },
  [StepKindEnum.Iterator]: {
    title: "Iterator",
    subtitle: "Loop over data",
  },
  [StepKindEnum.Input]: {
    title: "Input",
    subtitle: "Capture user input",
  },
  [StepKindEnum.Notify]: {
    title: "Notify",
    subtitle: "Sends an email",
  },
  [StepKindEnum.Output]: {
    title: "Output",
    subtitle: "Provide user output",
  },
  [StepKindEnum.Learn]: {
    title: "Learn",
    subtitle: "Gain skills from data",
  },
  [StepKindEnum.Speech]: {
    title: "Speech",
    subtitle: "Convert text to speech",
  },
  [StepKindEnum.Transcription]: {
    title: "Transcription",
    subtitle: "Convert speech to text",
  },
};

const KINDS = [
  StepKindEnum.Branch,
  StepKindEnum.Code,
  StepKindEnum.Formatter,
  StepKindEnum.Input,
  StepKindEnum.Iterator,
  StepKindEnum.Learn,
  StepKindEnum.Notify,
  StepKindEnum.Output,
  StepKindEnum.Prompt,
  StepKindEnum.Speech,
  StepKindEnum.Transcription,
];

export const StepKindField: FC = () => {
  const { control } = useFormContext<StepInput>();

  return (
    <Controller
      control={control}
      name="kind"
      render={({ field: { onChange, value } }) => (
        <Field>
          <Label>Kind:</Label>
          <RadioGrid>
            {KINDS.map((kind) => (
              <RadioCard
                key={kind}
                onSelect={() => onChange(kind)}
                checked={value === kind}
              >
                <RadioCardTitle>
                  <FontAwesomeIcon icon={STEP_KIND_ICON[kind]} />
                  <span>{METADATA[kind].title}</span>
                </RadioCardTitle>
                <RadioCardSubtitle>{METADATA[kind].subtitle}</RadioCardSubtitle>
              </RadioCard>
            ))}
          </RadioGrid>
        </Field>
      )}
    />
  );
};
