import { redirect } from "react-router";

import { MeDocument, type MeQuery, type MeQueryVariables } from "@app_schema";

import { client } from "@application/config/apollo";

import { reset } from "@application/utilities/reset";

const unauthorized = (request: Request) => {
  const url = new URL(request.url);
  return redirect(`/login?back=${url.pathname}`);
};

export const ME_LOADER = async ({ request }: { request: Request }) => {
  const token =
    localStorage.getItem("token") ?? sessionStorage.getItem("token");

  if (!token) return unauthorized(request);

  try {
    const { data } = await client.query<MeQuery, MeQueryVariables>({
      query: MeDocument,
    });
    return data;
  } catch {
    reset();
    return unauthorized(request);
  }
};
