import { type FC, type ReactNode } from "react";

import { clsx } from "clsx";

const CHECKED_STYLE = "ring-indigo-500 ring-2";
const UNCHECKED_STYLE = "ring-slate-200 ring-2";

const RadioCardCheck: FC<{
  checked?: boolean;
}> = ({ checked }) => (
  <div
    className={clsx(
      "flex h-3 w-3 items-center justify-center rounded-full ring-2",
      checked ? CHECKED_STYLE : UNCHECKED_STYLE,
    )}
  >
    {checked && <div className="h-2 w-2 rounded-full bg-indigo-500" />}
  </div>
);

export const RadioCard: FC<{
  children: ReactNode;
  checked?: boolean;
  onSelect(checked: boolean): void;
}> = ({ children, checked, onSelect }) => (
  <div
    role="radio"
    aria-checked={checked}
    onClick={() => onSelect(!checked)}
    className={clsx(
      "flex cursor-pointer items-center gap-4 rounded-md p-4 shadow-md",
      checked ? CHECKED_STYLE : UNCHECKED_STYLE,
    )}
  >
    <RadioCardCheck checked={checked} />
    <div className="flex flex-col">{children}</div>
  </div>
);
