import { type AnchorHTMLAttributes, forwardRef } from "react";

import { faSpinner } from "@fortawesome/pro-duotone-svg-icons/faSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { style } from "./button_style";
import { type Color } from "./types";

type T = HTMLAnchorElement;
type P = AnchorHTMLAttributes<T> & {
  color?: Color;
  loading?: boolean;
  full?: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
};

export const ButtonAnchor = forwardRef<T, P>(
  ({ color, full, size, loading, children, ...props }, ref) => (
    <a ref={ref} className={style({ color, full, size })} {...props}>
      {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : children}
    </a>
  ),
);
