import { type FC, useContext, useState } from "react";
import { useFormContext } from "react-hook-form";

import { faFile } from "@fortawesome/pro-duotone-svg-icons/faFile";
import { faFileCirclePlus } from "@fortawesome/pro-duotone-svg-icons/faFileCirclePlus";
import { faSquareDashedCirclePlus } from "@fortawesome/pro-duotone-svg-icons/faSquareDashedCirclePlus";
import { faTimes } from "@fortawesome/pro-duotone-svg-icons/faTimes";
import { faTrash } from "@fortawesome/pro-duotone-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Action__Prompt__AttachmentInput,
  Action__Prompt__AttachmentKind,
  Action__Prompt__MessageRole,
  type StepInput,
} from "@app_schema";

import { Badge } from "@styled/badge";
import { Button } from "@styled/button";
import { Field } from "@styled/field";
import { Fields } from "@styled/fields";
import { Group } from "@styled/group";
import { IconButton } from "@styled/icon_button";
import { Input } from "@styled/input";
import { Label } from "@styled/label";
import { RichTextAreaContainer } from "@styled/rich_text_area_container";
import { RichTextAreaFooter } from "@styled/rich_text_area_footer";
import { RichTextAreaHeader } from "@styled/rich_text_area_header";
import { Select } from "@styled/select";
import { TextArea } from "@styled/text_area";

import { AttachmentContext } from "./steps/action/prompt/attachment_context";
import { AttachmentProvider } from "./steps/action/prompt/attachment_provider";
import { AttachmentsContext } from "./steps/action/prompt/attachments_context";
import { AttachmentsProvider } from "./steps/action/prompt/attachments_provider";
import { MessageContext } from "./steps/action/prompt/message_context";
import { MessageProvider } from "./steps/action/prompt/message_provider";
import { MessagesContext } from "./steps/action/prompt/messages_context";
import { MessagesProvider } from "./steps/action/prompt/messages_provider";

const AttachmentBuildFields: FC<{
  onSave(_: Action__Prompt__AttachmentInput): void;
}> = ({ onSave }) => {
  const [path, setPath] = useState("");

  const onClick = () => {
    if (!path) return;
    onSave({
      path,
      kind: Action__Prompt__AttachmentKind.Path,
    });
  };

  return (
    <form>
      <Fields>
        <Input
          required
          placeholder="Path (e.g. step.files)"
          value={path}
          onChange={(event) => setPath(event.target.value)}
        />
        <Button onClick={onClick} disabled={!path} type="button">
          Attach
        </Button>
      </Fields>
    </form>
  );
};

const AttachmentBuildButton: FC = () => {
  const [open, setOpen] = useState(false);
  const { onBuild } = useContext(AttachmentsContext);

  const onToggle = () => setOpen((open) => !open);

  if (!open) return <IconButton icon={faFileCirclePlus} onClick={onToggle} />;

  return (
    <AttachmentBuildFields
      onSave={(input) => {
        onBuild(input);
        onToggle();
      }}
    />
  );
};

const AttachmentDeleteButton: FC = () => {
  const { onDelete } = useContext(AttachmentContext);
  return (
    <button type="button" onClick={onDelete}>
      <FontAwesomeIcon icon={faTimes} />
    </button>
  );
};

const MessageDeleteButton: FC = () => {
  const { onDelete } = useContext(MessageContext);
  return <IconButton icon={faTrash} onClick={onDelete} />;
};

const MessageBuildButton: FC = () => {
  const { onBuild } = useContext(MessagesContext);
  return (
    <Button type="button" onClick={() => onBuild()}>
      <FontAwesomeIcon icon={faSquareDashedCirclePlus} /> Message
    </Button>
  );
};

const AttachmentField: FC = () => {
  const { name } = useContext(AttachmentContext);

  const { watch } = useFormContext<StepInput>();
  const destroy = watch(`${name}._destroy`);
  const path = watch(`${name}.path`);

  if (destroy) return null;

  return (
    <Badge>
      <FontAwesomeIcon icon={faFile} /> {path}
      <AttachmentDeleteButton />
    </Badge>
  );
};

const AttachmentsFieldset: FC = () => {
  const { name } = useContext(MessageContext);

  return (
    <AttachmentsProvider name={`${name}.attachments`}>
      {(fields) => (
        <div className="flex gap-2">
          {fields.map(({ key }, index) => (
            <AttachmentProvider key={key} index={index}>
              <AttachmentField />
            </AttachmentProvider>
          ))}
          <AttachmentBuildButton />
        </div>
      )}
    </AttachmentsProvider>
  );
};

const MessageField: FC = () => {
  const { name } = useContext(MessageContext);

  const { register, watch } = useFormContext<StepInput>();
  const role = watch(`${name}.role`);
  const destroy = watch(`${name}._destroy`);
  const template = watch(`${name}.template`);

  if (destroy) return null;

  return (
    <RichTextAreaContainer>
      <RichTextAreaHeader className="flex items-center justify-between">
        <Select
          plain
          id={`${name.replace(/\./g, "_")}_role`}
          {...register(`${name}.role`, { required: "required" })}
        >
          <option value="" disabled={!!role}>
            - Role -
          </option>
          <option value={Action__Prompt__MessageRole.User}>Human</option>
          <option value={Action__Prompt__MessageRole.System}>System</option>
        </Select>

        <MessageDeleteButton />
      </RichTextAreaHeader>

      <TextArea
        className="rounded-none"
        id={`${name.replace(/\./g, "_")}_template`}
        {...register(`${name}.template`, { required: "required" })}
        placeholder="Template"
      />

      <RichTextAreaFooter className="flex items-center justify-between">
        <div className="text text-slate-400">
          {template?.length ?? 0} characters
        </div>
        {role === Action__Prompt__MessageRole.User && <AttachmentsFieldset />}
      </RichTextAreaFooter>
    </RichTextAreaContainer>
  );
};

export const DashboardStepsFormActionPromptMessagesFieldset: FC<{
  name?: `action.prompt.messages`;
}> = ({ name = "action.prompt.messages" }) => (
  <MessagesProvider name={name}>
    {(fields) => (
      <Field>
        <Label>Messages:</Label>

        <Group>
          {fields.map(({ key }, index) => (
            <MessageProvider key={key} index={index}>
              <MessageField />
            </MessageProvider>
          ))}

          <div>
            <MessageBuildButton />
          </div>
        </Group>
      </Field>
    )}
  </MessagesProvider>
);
