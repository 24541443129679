import { createContext } from "react";

import { Flash } from "@application/types/flash";

export const World = createContext<{
  flash?: Flash;
  token?: string;
  login(token?: string): void;
  logout(): void;
  notify(flash?: Flash): void;
}>({
  login: (): void => {
    /* noop */
  },
  logout: () => {
    /* noop */
  },
  notify: (): void => {
    /* noop */
  },
});
