import { type FC } from "react";

import { clsx } from "clsx";

import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle } from "@fortawesome/pro-duotone-svg-icons/faCheckCircle";
import { faExclamationCircle } from "@fortawesome/pro-duotone-svg-icons/faExclamationCircle";
import { faForward } from "@fortawesome/pro-duotone-svg-icons/faForward";
import { faPauseCircle } from "@fortawesome/pro-duotone-svg-icons/faPauseCircle";
import { faSpinner } from "@fortawesome/pro-duotone-svg-icons/faSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { type Execution, ExecutionStatusEnum } from "@app_schema";

const STYLES: Record<ExecutionStatusEnum, string> = {
  [ExecutionStatusEnum.Initialized]: "text-slate-500",
  [ExecutionStatusEnum.Skipped]: "text-slate-500",
  [ExecutionStatusEnum.Executing]: "text-indgo-500",
  [ExecutionStatusEnum.Paused]: "text-indgo-500",
  [ExecutionStatusEnum.Failed]: "text-rose-500",
  [ExecutionStatusEnum.Succeeded]: "text-emerald-500",
};

const ICONS: Record<ExecutionStatusEnum, IconProp> = {
  [ExecutionStatusEnum.Initialized]: faSpinner,
  [ExecutionStatusEnum.Skipped]: faForward,
  [ExecutionStatusEnum.Executing]: faSpinner,
  [ExecutionStatusEnum.Succeeded]: faCheckCircle,
  [ExecutionStatusEnum.Failed]: faExclamationCircle,
  [ExecutionStatusEnum.Paused]: faPauseCircle,
};

export const ExecutionStatus: FC<{
  execution: Pick<Execution, "status">;
}> = ({ execution }) => (
  <div className={clsx("flex items-center gap-2", STYLES[execution.status])}>
    <FontAwesomeIcon
      icon={ICONS[execution.status]}
      spin={execution.status === ExecutionStatusEnum.Executing}
    />
  </div>
);
