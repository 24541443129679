import { type FC } from "react";

import { faPlayCircle } from "@fortawesome/pro-duotone-svg-icons/faPlayCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { RunFragment, RunStatusEnum, useRunResumeMutation } from "@app_schema";

import { Button } from "@styled/button";

export const RunResumeButton: FC<{
  run: RunFragment;
}> = ({ run }) => {
  const [execute, { loading }] = useRunResumeMutation({
    variables: { runID: run.id },
  });

  return (
    <>
      <Button
        type="button"
        loading={loading}
        onClick={() => execute()}
        disabled={run.status !== RunStatusEnum.Paused}
      >
        <FontAwesomeIcon icon={faPlayCircle} /> Resume
      </Button>
    </>
  );
};
