import { type FC } from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

import { useStepBuildMutation } from "@app_schema";

import { DashboardStepsForm } from "./steps_form";

export const DashboardStepsNew: FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const parentID = params.get("parent_id");
  const { workflowID } = useParams<{
    workflowID: string;
  }>();
  const [execute, { loading, data }] = useStepBuildMutation();
  const errors = data?.workflow.steps.build?.errors;

  return (
    <DashboardStepsForm
      loading={loading}
      save={async (input) => {
        const result = await execute({
          variables: { workflowID: workflowID!, input: { parentID, ...input } },
        });
        const step = result.data?.workflow.steps.build.step;

        if (step)
          navigate(`/dashboard/workflows/${workflowID}/steps/${step.id}`);
      }}
      errors={errors}
    />
  );
};
