import { type FC } from "react";

import { faCheckCircle } from "@fortawesome/pro-duotone-svg-icons/faCheckCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ButtonLink } from "@styled/button_link";

export const Price: FC<{
  name: string;
  amount: number;
  term: "month" | "year";
  summary: string;
  features: string[];
}> = ({ name, amount, term, summary, features }) => (
  <div className="flex flex-col gap-4 rounded-2xl border border-slate-300 p-8">
    <h3 className="text-center text-2xl font-semibold text-slate-800">
      {name}
    </h3>
    <p className="text-mdtext-slate-600 text-center">{summary}</p>

    <div className="flex items-center justify-center gap-2">
      <span className="text-2xl font-semibold text-slate-800">${amount}</span>
      <span className="font-light text-slate-600">/{term}</span>
    </div>

    <ButtonLink full to={"/signup"}>
      Explore
    </ButtonLink>

    <ul className="flex flex-col gap-4">
      {features.map((feature, key) => (
        <li key={key}>
          <div className="flex gap-2">
            <span className="text-indigo-500">
              <FontAwesomeIcon icon={faCheckCircle} />
            </span>
            <span className="text-slate-600">{feature}</span>
          </div>
        </li>
      ))}
    </ul>
  </div>
);
