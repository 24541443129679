import { type FC } from "react";
import { Link } from "react-router-dom";

import { type Vault } from "@app_schema";

import { Badge } from "@styled/badge";

export const VaultBadgeLink: FC<{
  vault: Pick<Vault, "id" | "name">;
}> = ({ vault }) => (
  <Link to={`/dashboard/vaults/${vault.id}`}>
    <Badge>{vault.name}</Badge>
  </Link>
);
