import { type FC } from "react";

import { Well } from "./well";

export const Code: FC<{
  children: TrustedHTML;
}> = ({ children }) => (
  <Well>
    <code className="block">
      <pre className="rouge" dangerouslySetInnerHTML={{ __html: children }} />
    </code>
  </Well>
);
