import { type FC, type ReactNode } from "react";

import { FetchResult } from "@apollo/client";

import { ChatForm } from "./chat_form";

export const Chat: FC<{
  children: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  chat(_: { message: string; fileIDs?: string[] }): Promise<FetchResult>;
}> = ({ children, disabled, loading, chat }) => (
  <div className="flex h-full flex-col justify-between">
    <div className="flex-auto overflow-y-auto">{children}</div>
    <div className="flex-none p-4">
      <ChatForm chat={chat} disabled={disabled} loading={loading} />
    </div>
  </div>
);
