import { type FC } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

import {
  WorkflowInput,
  useDashboardWorkflowsDetailsQuery,
  useWorkflowChangeMutation,
} from "@app_schema";

import { DashboardWorkflowsForm } from "./workflows_form";

export const DashboardWorkflowsEdit: FC = () => {
  const navigate = useNavigate();

  const { workflowID } = useParams<{ workflowID: string }>();
  const [execute, { loading, data }] = useWorkflowChangeMutation();
  const { data: defaults } = useDashboardWorkflowsDetailsQuery({
    variables: { workflowID: workflowID! },
  });
  const errors = data?.workflow.change.errors;

  const save = async (input: WorkflowInput) => {
    const result = await execute({ variables: { id: workflowID!, input } });
    const workflow = result.data?.workflow.change.workflow;
    if (workflow) navigate(`/dashboard/workflows/${workflow.id}`);
  };

  return (
    <DashboardWorkflowsForm
      save={save}
      errors={errors}
      loading={loading}
      workflow={defaults?.workflow}
    />
  );
};
