import { type FC } from "react";

import { DateTime } from "luxon";

import { type Blog__Article } from "@app_schema";

export const BlogArticleDate: FC<{
  article: Pick<Blog__Article, "date">;
}> = ({ article }) => (
  <p className="text-2xl font-semibold text-slate-400">
    {DateTime.fromISO(article.date).toLocaleString(DateTime.DATE_FULL)}
  </p>
);
