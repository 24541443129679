import { type FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { type StepErrors, type StepInput } from "@app_schema";

import { Fieldset } from "@styled/fieldset";
import { InputField } from "@styled/input_field";
import { TextAreaField } from "@styled/text_area_field";

import { TemplateLanguageSelectField } from "./steps/template_language_select_field";

export const DashboardStepsFormActionNotifyFieldset: FC<{
  errors?: StepErrors | null;
}> = ({ errors }) => {
  const { register, unregister, watch } = useFormContext<StepInput>();

  useEffect(() => {
    register("action.notify");
    return () => unregister("action.notify");
  }, [register, unregister]);

  return (
    <Fieldset>
      <TemplateLanguageSelectField
        {...register("action.notify.language")}
        errors={errors ? (errors.messages["action.language"] ?? []) : null}
        id="step_action_code_language"
        placeholder={!watch("action.notify.language")}
      />

      <InputField
        {...register("action.notify.to", { required: "required " })}
        errors={errors ? (errors.messages["action.to"] ?? []) : null}
        id="step_action_notify_to"
        label="To:"
        placeholder="To"
      />

      <InputField
        {...register("action.notify.subject", { required: "required " })}
        errors={errors ? (errors.messages["action.subject"] ?? []) : null}
        id="step_action_notify_subject"
        label="Subject:"
        placeholder="Subject"
      />

      <TextAreaField
        {...register("action.notify.body", { required: "required" })}
        errors={errors ? (errors.messages["action.body"] ?? []) : null}
        id="step_action_execution_template"
        label="Body:"
        placeholder="Body"
        rows={8}
      />
    </Fieldset>
  );
};
