import { type FC } from "react";

import { type Agent, type User } from "@app_schema";

export const AgentName: FC<{
  agent: Pick<Agent, "name"> & {
    user: Pick<User, "name" | "me">;
  };
}> = ({ agent }) => (
  <>
    {" "}
    {agent.name} {!agent.user.me && <> by {agent.user.name}</>}
  </>
);
