import { type LoaderFunctionArgs } from "react-router";
import { redirect } from "react-router-dom";

export const TOKEN_LOADER = async (props: LoaderFunctionArgs) => {
  const token = props.params.token!;

  localStorage.setItem("token", token);
  sessionStorage.setItem("token", token);

  return redirect("/dashboard");
};
