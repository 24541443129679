import { LoaderFunctionArgs } from "react-router";

import {
  DashboardAgentsDetailsDocument,
  type DashboardAgentsDetailsQuery,
  type DashboardAgentsDetailsQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_AGENTS_DETAILS_LOADER = async (
  props: LoaderFunctionArgs,
) => {
  const { data } = await client.query<
    DashboardAgentsDetailsQuery,
    DashboardAgentsDetailsQueryVariables
  >({
    query: DashboardAgentsDetailsDocument,
    variables: { id: props.params.id! },
  });

  return data;
};
