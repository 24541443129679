import { type FC } from "react";

import { Workflow, useWorkflowDestoryMutation } from "@app_schema";

import { DestroyButton } from "@styled/destroy_button";

export const WorkflowsDestroyButton: FC<{
  workflow: Pick<Workflow, "id" | "name">;
  onDestroy?(): void;
}> = ({ workflow, onDestroy }) => {
  const [execute, { loading }] = useWorkflowDestoryMutation();

  const destroy = async () => {
    await execute({ variables: { id: workflow.id } });
    if (onDestroy) onDestroy();
  };

  return (
    <DestroyButton
      destroy={destroy}
      loading={loading}
      confirmation={
        <>
          Are you sure you want to delete "{workflow.name}"? If deleted any
          linked webhooks, schedules, and runs are also deleted.
        </>
      }
    />
  );
};
