import { type AnchorHTMLAttributes, forwardRef } from "react";

import { clsx } from "clsx";

type T = HTMLAnchorElement;
type P = AnchorHTMLAttributes<T>;

export const Link = forwardRef<T, P>(({ className, ...props }, ref) => (
  <a
    ref={ref}
    className={clsx("text-indigo-500 hover:text-indigo-700", className)}
    {...props}
  />
));

Link.displayName = "Link";
