import { useState } from "react";

export const useDialog = () => {
  const [dialog, setDialog] = useState<
    | {
        onConfirm(): void;
        onCancel(): void;
      }
    | undefined
  >();

  const confirm = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      setDialog({
        onConfirm: () => {
          setDialog(undefined);
          resolve();
        },
        onCancel: () => {
          setDialog(undefined);
          reject();
        },
      });
    });
  };

  return {
    dialog,
    confirm,
  };
};
