import { type FC } from "react";
import { useNavigate, useParams } from "react-router";

import {
  useDashboardSkillsDetailsQuery,
  useSkillChangeMutation,
} from "@app_schema";

import { DashboardSkillsForm } from "./skills_form";

export const DashboardSkillsEdit: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { data: defaults } = useDashboardSkillsDetailsQuery({
    variables: { id: id! },
  });
  const skill = defaults?.skill;

  const [execute, { loading, data }] = useSkillChangeMutation();
  const errors = data?.skill.change?.errors;

  if (!skill) return;

  return (
    <DashboardSkillsForm
      loading={loading}
      save={async (input) => {
        const result = await execute({
          variables: { id: id!, input },
        });
        const skill = result.data?.skill.change.skill;

        if (skill) navigate(`/dashboard/skills/${skill.id}`);
      }}
      errors={errors}
      skill={skill}
    />
  );
};
