import { type HTMLAttributes, forwardRef } from "react";

import { clsx } from "clsx";

import { type Color } from "./types";

type T = HTMLDivElement;
type P = HTMLAttributes<T> & { color?: Color };

const COLOR_STYLE: Record<Color, string> = {
  indigo: "bg-indigo-200 text-indigo-800",
  rose: "bg-rose-200 text-rose-800",
  slate: "bg-slate-200 text-slate-800",
  white: "bg-white text-slate-800",
};

export const Notification = forwardRef<T, P>(
  ({ color = "rose", className, ...props }, ref) => (
    <div
      ref={ref}
      className={clsx(
        "flex items-center justify-between gap-2 rounded-md px-3 py-2 font-medium",
        COLOR_STYLE[color],
        className,
      )}
      {...props}
    />
  ),
);
