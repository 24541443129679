import { type FC } from "react";

import { type Blog__Article } from "@app_schema";

import { Prose } from "@styled/prose";

export const BlogArticleContent: FC<{
  article: Pick<Blog__Article, "html">;
}> = ({ article }) => (
  <Prose dangerouslySetInnerHTML={{ __html: article.html }} />
);
