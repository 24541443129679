import { type FC } from "react";
import { Outlet } from "react-router";

import { PageLayout } from "@layouts/page_layout";

import { ButtonLinkLogin } from "@styled/button_link_login";
import { ButtonLinkSignup } from "@styled/button_link_signup";
import { Organization } from "@styled/organization";

export const BlogLayout: FC = () => (
  <PageLayout>
    <p className="text-center text-2xl text-slate-600">
      Brought to you by the people that make <Organization />.
    </p>

    <div className="flex justify-center gap-4">
      <ButtonLinkLogin />
      <ButtonLinkSignup />
    </div>

    <hr />

    <Outlet />
  </PageLayout>
);
