import { type SelectHTMLAttributes, forwardRef } from "react";

import { useDashboardAgentsListQuery } from "@app_schema";

import { type BaseFieldProps } from "@styled/base_field";
import { SelectField } from "@styled/select_field";

type T = HTMLSelectElement;
type P = BaseFieldProps &
  SelectHTMLAttributes<T> & {
    placeholder?: boolean;
  };

export const AgentSelectField = forwardRef<T, P>(
  ({ placeholder, disabled, ...props }, ref) => {
    const { data, loading } = useDashboardAgentsListQuery();
    const agents = data?.agents.filter(({ deleted }) => !deleted);

    return (
      <SelectField {...props} ref={ref} disabled={disabled ?? loading}>
        <option value="" disabled={!placeholder}>
          - Agent -
        </option>

        {agents?.map((agent) => (
          <option key={agent.id} value={agent.id}>
            {agent.name}
          </option>
        ))}
      </SelectField>
    );
  },
);
