import {
  DashboardAgentsListDocument,
  type DashboardAgentsListQuery,
  type DashboardAgentsListQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_AGENTS_LIST_LOADER = async () => {
  const { data } = await client.query<
    DashboardAgentsListQuery,
    DashboardAgentsListQueryVariables
  >({
    query: DashboardAgentsListDocument,
    fetchPolicy: "network-only",
  });

  return data;
};
