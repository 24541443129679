import { type FC } from "react";

import { AvatarFragment, MeFragment } from "@app_schema";

import { Initials } from "./initials";
import { Skeleton } from "./skeleton";

const avifSrcSet = (avatar: AvatarFragment) =>
  [`${avatar.url_HD_AVIF} 80w`, `${avatar.url_SD_AVIF} 40w`].join(",");

const webpSrcSet = (avatar: AvatarFragment) =>
  [`${avatar.url_HD_WEBP} 80w`, `${avatar.url_SD_WEBP} 40w`].join(",");

export const Avatar: FC<{ user?: MeFragment }> = ({ user }) => {
  if (!user) return <Skeleton className={"size-10 rounded-md bg-slate-200"} />;
  const avatar = user.avatar;

  if (avatar) {
    return (
      <figure>
        <source srcSet={avifSrcSet(avatar)} type="image/avif" />
        <source srcSet={webpSrcSet(avatar)} type="image/webp" />

        <img
          className="size-10 rounded-md bg-slate-200"
          alt={user.name}
          width={40}
          height={40}
          src={avatar.url}
        />
      </figure>
    );
  } else {
    return (
      <div className="flex size-10 items-center justify-center rounded-md bg-slate-200 font-semibold text-slate-800">
        <span>
          <Initials name={user.name} />
        </span>
      </div>
    );
  }
};
