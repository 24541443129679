import { useContext } from "react";

import { identify } from "@application/utilities/identify";

import { World } from "@application/contexts/world";
import { type LoginInput, useLoginMutation } from "app_schema";

export const useLogin = () => {
  const [submit, { loading, data }] = useLoginMutation();
  const errors = data?.login.errors;

  const { login } = useContext(World);

  return {
    login: async (input: LoginInput) => {
      if (loading) return;
      const result = await submit({ variables: { input } });
      const token = result.data?.login.token;
      const user = result.data?.login.user;
      if (token) login(token);
      if (user) identify(user);
    },
    loading,
    errors,
  };
};
