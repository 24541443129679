import { type FC, useRef, useState } from "react";
import { useActiveStorage } from "react-activestorage";

import {
  MeFragment,
  useAvatarAttachMutation,
  useAvatarDetachMutation,
} from "@app_schema";

import { Button } from "@styled/button";
import { Form } from "@styled/form";
import { Input } from "@styled/input";
import { Notification } from "@styled/notification";
import { Progress } from "@styled/progress";

export const SettingsAvatarAttachDetachForm: FC<{
  me: MeFragment;
}> = ({ me }) => {
  const form = useRef<HTMLFormElement>(null);
  const [error, setError] = useState<Error | undefined>();
  const [file, setFile] = useState<File | undefined>();

  const [attach, { loading: attaching }] = useAvatarAttachMutation();
  const [detach, { loading: detaching }] = useAvatarDetachMutation();

  const { uploading, progress } = useActiveStorage(
    file,
    async ({ blob, error }) => {
      setError(error);
      if (blob) await attach({ variables: { id: blob.signed_id } });
      form.current?.reset();
      setFile(undefined);
    },
  );

  return (
    <Form ref={form}>
      {error && <Notification color="rose">{error.message}</Notification>}
      <Input
        type="file"
        disabled={!!file || uploading || attaching}
        onChange={(event) => {
          if (!event.target.files) return;
          setFile(event.target.files.item(0) ?? undefined);
        }}
      />
      {progress && <Progress value={progress.loaded / progress.total} />}

      <Button
        type="button"
        loading={detaching}
        disabled={!me.avatar}
        color="rose"
        onClick={() => detach()}
      >
        Delete Avatar
      </Button>
    </Form>
  );
};
