import { type FC } from "react";
import { useParams } from "react-router-dom";

import { useBlogAuthorDetailsQuery } from "@app_schema";

import { useTitle } from "@application/hooks/use_title";
import { useTrackPageView } from "@application/hooks/use_track_page_view";

import { Breadcrumbs } from "@styled/breadcrumbs";

import { BlogAuthorBio } from "./blog_author_bio";
import { BlogHeading } from "./blog_heading";
import { BlogPage } from "./blog_page";
import { BlogSubtitle } from "./blog_subtitle";
import { BlogTitle } from "./blog_title";

export const BlogAuthorDetails: FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const { data } = useBlogAuthorDetailsQuery({ variables: { slug: slug! } });
  const author = data?.blog?.author;

  useTitle("Workflow", "Blog", author?.name);
  useTrackPageView({ page: `blog/author/${slug}` });

  if (!author) return null;

  const breadcrumbs = [
    { name: "Blog", to: "/blog" },
    { name: author.name, to: `/blog/authors/${author.slug}` },
  ];

  return (
    <BlogPage>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <BlogHeading>
        <BlogTitle>{author.name}</BlogTitle>
        <BlogSubtitle>{author.role}</BlogSubtitle>
      </BlogHeading>

      <BlogAuthorBio author={author} />
    </BlogPage>
  );
};
