import type { FC } from "react";

import {
  type Agent,
  type Conversation,
  useConversationDestroyMutation,
} from "@app_schema";

import { DestroyButton } from "@styled/destroy_button";

import { ConversationName } from "./conversation_name";

export const ConversationDestroyButton: FC<{
  conversation: Pick<Conversation, "id"> & {
    agent: Pick<Agent, "id" | "name">;
  };
  onDestroy?(): void;
}> = ({ conversation, onDestroy }) => {
  const [execute, { loading }] = useConversationDestroyMutation();
  const destroy = async () => {
    await execute({ variables: { id: conversation.id } });
    if (onDestroy) onDestroy();
  };

  return (
    <DestroyButton
      destroy={destroy}
      loading={loading}
      confirmation={
        <>
          Are you you sure you want to delete “
          <ConversationName conversation={conversation} />
          ”?
        </>
      }
    >
      Delete
    </DestroyButton>
  );
};
