import { type FC } from "react";

import { faSearch } from "@fortawesome/pro-duotone-svg-icons/faSearch";

import { InputField } from "./input_field";

export const SearchInputField: FC<{
  query?: string;
  onSearch(query?: string): void;
}> = ({ query, onSearch }) => (
  <InputField
    value={query ?? ""}
    onChange={(event) => onSearch(event.target.value || undefined)}
    name="search"
    icon={faSearch}
    type="search"
    placeholder="Search"
  />
);
