import { type HTMLAttributes, forwardRef } from "react";

import { clsx } from "clsx";

type T = HTMLFormElement;
type P = HTMLAttributes<T>;

export const Form = forwardRef<T, P>(({ className, ...props }, ref) => (
  <form ref={ref} className={clsx("space-y-4", className)} {...props} />
));

Form.displayName = "Form";
