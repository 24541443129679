import { type FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  MessageFragment,
  MessageRoleEnum,
  useConversationMessageBuildMutation,
  useDashboardConversationsDetailsQuery,
} from "@app_schema";

import { useActionCableSubscription } from "@application/hooks/use_action_cable_subscription";

import { Badge } from "@styled/badge";
import { Badges } from "@styled/badges";
import { Chat } from "@styled/chat";
import { ChatBubble } from "@styled/chat_bubble";
import { Headline } from "@styled/headline";
import { Page } from "@styled/page";

import { ConversationDestroyButton } from "./conversation_destroy_button";
import { ConversationName } from "./conversation_name";

const ROLES: Record<MessageRoleEnum, "assistant" | "user"> = {
  [MessageRoleEnum.Assistant]: "assistant",
  [MessageRoleEnum.User]: "user",
};

const MessageChatBubble: FC<{ message: MessageFragment }> = ({ message }) => (
  <ChatBubble html={message.html} role={ROLES[message.role]} />
);

export const DashboardConversationsDetails: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  if (!id) throw new Error("undefined ID");

  const { data, loading, refetch } = useDashboardConversationsDetailsQuery({
    variables: { id },
  });

  const [execute, { loading: saving }] = useConversationMessageBuildMutation();

  const chat = async (input: { message: string; fileIDs?: string[] }) => {
    return await execute({
      variables: {
        id,
        input: {
          text: input.message,
          fileIDs: input.fileIDs,
        },
      },
    });
  };

  const [channel] = useState(() => ({
    channel: "ConversationChannel",
    id,
  }));

  const status = useActionCableSubscription(channel, () => {
    refetch({ id });
  });

  useEffect(() => {
    if (status === "connected") refetch({ id });
  }, [status]);

  const conversation = data?.conversation;
  if (!conversation) return;

  const messages = conversation.messages;

  return (
    <Chat loading={saving} chat={chat}>
      <Page loading={loading}>
        <Headline title={<ConversationName conversation={conversation} />}>
          <ConversationDestroyButton
            conversation={conversation}
            onDestroy={() => navigate("/dashboard/conversations")}
          />
        </Headline>

        <Badges>
          {conversation.artifacts.map((artifact) => (
            <Badge key={artifact.id}>{artifact.name}</Badge>
          ))}
        </Badges>

        {messages.map((message) => (
          <MessageChatBubble key={message.id} message={message} />
        ))}
      </Page>
    </Chat>
  );
};
