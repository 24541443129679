import { type FC } from "react";

import { DateTime } from "luxon";

export const DateTimeFormatter: FC<{
  iso?: string | null;
}> = ({ iso }) => {
  if (!iso) return null;
  return DateTime.fromISO(iso).toLocaleString(DateTime.DATETIME_MED);
};
