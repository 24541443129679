import { type FC } from "react";

import { type Blog__Author } from "@app_schema";

export const BlogAuthorBio: FC<{
  author: Pick<Blog__Author, "html">;
}> = ({ author }) => (
  <div
    className="prose prose-slate max-w-none"
    dangerouslySetInnerHTML={{ __html: author.html }}
  />
);
