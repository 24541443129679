import { useEffect } from "react";

export const useTitle = (...components: Array<string | undefined>) => {
  const title = components
    .filter((component): component is string => component !== undefined)
    .reverse()
    .join(" | ");

  useEffect(() => {
    const prior = document.title;
    document.title = title;
    return () => {
      document.title = prior;
    };
  }, [title]);
};
