import type { FC } from "react";

import type { Agent, Conversation } from "@app_schema";

export const ConversationName: FC<{
  conversation: Pick<Conversation, "id"> & {
    agent: Pick<Agent, "id" | "name">;
  };
}> = ({ conversation }) => (
  <>
    #{conversation.id} - {conversation.agent.name}
  </>
);
