import { createContext } from "react";

import { Action__Prompt__AttachmentInput } from "@app_schema";

export const AttachmentsContext = createContext<{
  name: `action.prompt.messages.${number}.attachments`;
  onBuild(attachment?: Action__Prompt__AttachmentInput): void;
  onDelete(index: number): void;
}>({
  name: "action.prompt.messages.0.attachments",
  onBuild: () => {},
  onDelete: () => {},
});
