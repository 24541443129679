import { type FC } from "react";
import { useNavigate } from "react-router";

import { useSkillBuildMutation } from "@app_schema";

import { DashboardSkillsForm } from "./skills_form";

export const DashboardSkillsNew: FC = () => {
  const navigate = useNavigate();
  const [execute, { loading, data }] = useSkillBuildMutation();
  const errors = data?.skills?.build?.errors;

  return (
    <DashboardSkillsForm
      loading={loading}
      save={async (input) => {
        const result = await execute({
          variables: { input },
        });
        const skill = result.data?.skills?.build.skill;
        if (skill) navigate(`/dashboard/skills/${skill.id}`);
      }}
      errors={errors}
    />
  );
};
