import { type FC } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { faIdBadge } from "@fortawesome/pro-duotone-svg-icons/faIdBadge";
import { faTemperatureList } from "@fortawesome/pro-duotone-svg-icons/faTemperatureList";

import {
  type AgentErrors,
  type AgentFragment,
  type AgentInput,
} from "@app_schema";

import { Button } from "@styled/button";
import { Form } from "@styled/form";
import { InputField } from "@styled/input_field";
import { Notification } from "@styled/notification";
import { Page } from "@styled/page";
import { SelectField } from "@styled/select_field";
import { Sentence } from "@styled/sentence";
import { TextAreaField } from "@styled/text_area_field";

import { DashboardConnectionsField } from "./connections_field";
import { DashboardSecretsField } from "./secrets_field";
import { DashboardSkillsField } from "./skills_field";
import { DashboardVaultsField } from "./vaults_field";

const TARGETS: string[] = [
  "anthropic/claude-haiku",
  "anthropic/claude-opus",
  "anthropic/claude-sonnet",
  "google/gemini-flash",
  "google/gemini-pro",
  "mistral/codestral",
  "mistral/large",
  "mistral/small",
  "openai/gpt-3.5",
  "openai/gpt-4-turbo",
  "openai/gpt-4",
  "openai/gpt-4o-mini",
  "openai/gpt-4o",
  "openai/o1-mini",
  "openai/o1",
];

const DEFAULT_TARGET = "anthropic/claude-sonnet";

export const DashboardAgentsForm: FC<{
  agent?: AgentFragment;
  errors?: AgentErrors | null;
  loading?: boolean;
  save(_: AgentInput): void;
}> = ({ agent, loading, errors, save }) => {
  const form = useForm<AgentInput>({
    defaultValues: agent
      ? {
          name: agent.name,
          target: agent.target,
          temperature: agent.temperature,
          instructions: agent.instructions || null,
          connectionIDs: agent.connections.map((connection) => connection.id),
          secretIDs: agent.secrets.map((secret) => secret.id),
          skillIDs: agent.skills.map((skill) => skill.id),
          vaultIDs: agent.vaults.map((vault) => vault.id),
        }
      : {
          name: "",
          instructions: null,
          target: DEFAULT_TARGET,
          temperature: 0.0,
          connectionIDs: [],
          secretIDs: [],
          skillIDs: [],
          vaultIDs: [],
        },
  });

  const onSubmit = async (input: AgentInput) => {
    if (loading) return;
    save(input);
  };

  return (
    <Page>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          {errors?.base && (
            <Notification color="rose">
              <Sentence>{errors.base}</Sentence>
            </Notification>
          )}

          <InputField
            {...form.register("name", { required: "required" })}
            icon={faIdBadge}
            id="name"
            label="Name:"
            placeholder="Name"
          />

          <SelectField
            {...form.register("target", { required: "required" })}
            id="target"
            label="Target:"
          >
            <option value="" disabled={!!form.watch("target")}>
              - Target -
            </option>
            {TARGETS.map((target) => (
              <option key={target} value={target}>
                {target}
              </option>
            ))}
          </SelectField>

          <InputField
            {...form.register("temperature", {
              required: "required",
              min: 0,
              max: 1,
              valueAsNumber: true,
            })}
            id="temperature"
            icon={faTemperatureList}
            label="Temperature:"
            placeholder="Temperature"
          />

          <TextAreaField
            {...form.register("instructions")}
            id="instructions"
            label="Instructions (optional):"
            placeholder="Instructions"
          />

          <DashboardConnectionsField name="connectionIDs" />
          <DashboardSecretsField name="secretIDs" />
          <DashboardSkillsField name="skillIDs" />
          <DashboardVaultsField name="vaultIDs" />

          <Button type="submit" loading={loading}>
            Save
          </Button>
        </Form>
      </FormProvider>
    </Page>
  );
};
