import {
  DashboardSchedulesListDocument,
  type DashboardSchedulesListQuery,
  type DashboardSchedulesListQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_SCHEDULES_LIST_LOADER = async () => {
  const { data } = await client.query<
    DashboardSchedulesListQuery,
    DashboardSchedulesListQueryVariables
  >({
    query: DashboardSchedulesListDocument,
    fetchPolicy: "network-only",
  });

  return data;
};
