import { type FC, type ReactNode } from "react";

import { faCheck } from "@fortawesome/pro-duotone-svg-icons/faCheck";
import { faCopy } from "@fortawesome/pro-duotone-svg-icons/faCopy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useClipboard } from "@application/hooks/use_clipboard";

export const Clipboard: FC<{
  text: string;
  children?: ReactNode;
}> = ({ text, children }) => {
  const { copied, copy } = useClipboard(text);

  return (
    <button
      type="button"
      className="flex gap-2 rounded-md px-3 py-2 text-slate-400 hover:bg-slate-200 hover:text-slate-600"
      onClick={copy}
    >
      <FontAwesomeIcon icon={copied ? faCheck : faCopy} /> {children}
    </button>
  );
};
